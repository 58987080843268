import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { snackbarStyles } from '../../Styles/commonStyles';
import { inject, observer } from 'mobx-react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

@inject('commonStore')
@observer
class NoInternet extends Component {
    state = {
        vertical: 'top',
        horizontal: 'right',
    }

    render() {
        const { classes, message, type, open } = this.props;
        const { vertical, horizontal } = this.state;
        return (
            <div>
                <Snackbar open={open}
                    autoHideDuration={type === 'success' ? 2000 : null}
                    anchorOrigin={{ vertical, horizontal }}
                    key={`${vertical},${horizontal}`}
                    onClose={this.onClose}>
                    <Alert severity={type} className={classes[`${type}`]} onClose={this.onClose} >{message}</Alert>
                </Snackbar>
            </div>

        )
    }
}
NoInternet.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(snackbarStyles)(NoInternet);
