import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { feedbackStyles } from '../../Styles/feedbackStyles';
import { inject, observer } from 'mobx-react';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { ReactSVG } from 'react-svg'
import Images from '../../Theme/Images';
import { withRouter } from 'react-router-dom';

@inject('feedbackStore', 'commonStore')
@observer
class FeedbackDialog extends Component {

    closeDialog = () => {
        this.props.commonStore.setChatRefresh(false)
        this.props.feedbackStore.setFeedbackDialog(false);
    }

    renderImage = (type) => {
        let imageType = type
        return (
            <ReactSVG
                src={Images[`${imageType}`]} />
        )
    }

    componentDidMount() {
        const { version } = this.props.commonStore
        setTimeout(() => {
            this.props.feedbackStore.setFeedbackDialog(false);
            this.props.history.push({
                pathname: `/${version}/`,
            })
        }, 2500)
    }

    render() {
        const { classes } = this.props;
        const { openFeedbackDialog, feedbackResponse } = this.props.feedbackStore;
        return (
            <Dialog classes={{ paper: classes.cDialog }} open={openFeedbackDialog} onClose={this.closeDialog}>
                <DialogContent style={{ marginBottom: '2vh' }}>
                    {feedbackResponse && this.renderImage(feedbackResponse.type)}
                    {feedbackResponse && <Typography className={classes.feedbackInfo}>{feedbackResponse.message}</Typography>}
                </DialogContent>
            </Dialog>
        )
    }
}
FeedbackDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withRouter(withStyles(feedbackStyles)(FeedbackDialog));
