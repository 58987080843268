/*
 ChatFeedback component
 url match: /dashboard
 Base component for the home page, redirects to appropriate 
 components depending on the URL/Route
*/
import { withRouter } from 'react-router-dom';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { messageStyles } from '../../../Styles/chatStyles';
import clsx from 'clsx';
import { ReactSVG } from 'react-svg';
import Images from '../../../Theme/Images';
import Moment from 'moment'
const acceptedFiles = ['image/gif', 'image/jpeg', 'image/png', 'application/pdf', 'application/zip'];

@inject('userStore', 'commonStore', 'chatStore')
@observer
class ChatFeedback extends React.Component {
    replyToChat = (value) => {
        this.props.chatStore.sendMessage([], value)
        this.props.focusInput()
    }

    render() {
        const { classes, row } = this.props;
        return (
            <div className={clsx({ [classes.textMessage]: true })} onClick={this.openFileModal}>
                <span style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{row.webText}</span>
                <div className={classes.feedback}>
                    <ReactSVG src={Images.chatLike} className={classes.feedbackOption} onClick={(e) => this.replyToChat('Satisfied')} />
                    <ReactSVG src={Images.chatDislike} className={classes.feedbackOption} onClick={(e) => this.replyToChat('Dissatisfied')} />
                </div>
                <div className={clsx({ [classes.timeStamp]: true, [classes.agTimeStamp]: row.messageBy === 'CUSTOMER' })} >
                    <div> {Moment(row.createdAt).format('h:mm a')}</div>
                </div>

            </div>
        )
    }
}
export default withStyles(messageStyles, { withTheme: true })(withRouter(ChatFeedback));
