import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { homeStyles } from '../../Styles/homeStyles';
import { inject, observer } from 'mobx-react';
import { ReactSVG } from 'react-svg'
import Images from '../../Theme/Images';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import { withRouter } from 'react-router-dom';
//import Carousel from 'react-material-ui-carousel'
import LoginDrawer from './LoginDrawer';
const emailRegex = /^[A-Za-z0-9](\.?[A-Za-z0-9_-]){0,}@[A-Za-z0-9-]+\.([A-Za-z]{1,6}\.)?[A-Za-z]{2,6}$/;
const mobileRegex = /^[1-9][0-9]{9,}$/;
@inject('queryStore', 'commonStore', 'userStore')
@observer
class NSDLHome extends Component {
    state = {
        showLoder: true,
        openOptions: false,
        mobile: '',
        validationErrors: [],
    }

    closeOptions = () => {
        this.setState({ openOptions: false })
    }

    componentDidMount() {
        if (this.props.match.params['version'] === undefined)
            this.props.history.push(`/v4/`)
        this.props.commonStore.setChatRefresh(false)
        this.props.userStore.setToken('')
        this.props.queryStore.getFaqs()
        let that = this;
        setTimeout(that.showInitialLoader, 100);
        this.props.userStore.resetUserDetails()
        this.props.userStore.setCustomerData({})
    }

    initiateChat = () => {
        const { version } = this.props.commonStore
        this.props.userStore.resetAnonymousId()
        this.props.history.push(`/${version}/chat`)
    }


    handleInputChange = (value) => {
        const { validationErrors } = this.state
        this.setState({ mobile: value })
        delete validationErrors['mobile']
        this.setState({ validationErrors });
    }

    openSocialMedia = (url) => {
        let link = document.createElement('a');
        link.target = '_blank'
        link.href = url;
        link.click();
    }

    startChat = (type) => {
        const { version } = this.props.commonStore
        const { mobile, validationErrors } = this.state
        let valid = true
        if (emailRegex.test(mobile) || mobileRegex.test(mobile)) {
            delete validationErrors['mobile']
        }
        else {
            validationErrors['mobile'] = 'Please enter valid Email ID or 10 digit valid Mobile Number before proceeding'
            this.setState({ validationErrors });
            valid = false;
        }

        if (valid) {
            this.props.userStore.setCustomerData({ type: emailRegex.test(mobile) ? 'customerEmailId' : 'customerMobileNo', value: mobile })
            this.setState({
                validationErrors: {}
            });
        }
        else
            return
        if (type === 'chat')
            this.props.history.push({
                pathname: `/${version}/home`
            })
        else
            this.checkAckStatus()
    }

    checkAckStatus = () => {
        const { version } = this.props.commonStore
        this.props.history.push({
            pathname: `/${version}/captcha`,
            state: { type: "captcha" }
        })
    }

    render() {
        const { classes } = this.props;
        const { inProgress } = this.props.queryStore;
        const { mobile, validationErrors } = this.state
        return (
            <div className={classes.nsdlHome}>
                <div className={classes.nsdlContainer}>
                    <div className='rowFlex'>
                        <div>
                            <div className={classes.mascotContainer}>
                                <img style={{ width: '25%', marginRight: '-16px' }} alt='Mascot' src={'https://assistedclosure.tin-nsdl.com/mascot.png'} />
                                <div className={'hidden'}>Hey there!</div>
                                <div className={classes.welcomeTitle}>Hey there!</div>
                            </div>
                            <br />
                            <div className={classes.welcomeMessage}>

                                <div className='rowFlex'>
                                    <div className={classes.welcome}>

                                        <div> Welcome to Protean eGov Technologies Limited PAN Assistance, I am here to assist you with any queries related to Permanent Account Number (PAN)/Tax Deduction Account Number (TAN) and Tax Information Network (TIN).</div>

                                    </div>

                                </div>
                            </div></div>

                    </div>
                    <br />
                    <TextField autoComplete='off' value={mobile} type='text' onChange={(e) => this.handleInputChange(e.target.value)} className={classes.feedbackText} id="standard-basic" required={true} label="Please enter valid Email ID or Mobile Number to continue." fullWidth />

                    {validationErrors && validationErrors.mobile ?
                        <div className={classes.validationError}>
                            <ReactSVG src={Images.error} />
                            <div className={classes.errorStyle}>{validationErrors.mobile}</div>
                        </div> : null
                    }

                    <div className='rowFlex flexCentered'>
                        <br />
                        <ReactSVG className={classes.socialMedia} src={Images.fb} onClick={() => this.openSocialMedia('https://www.facebook.com/NSDLeGovernance')} />
                        <ReactSVG className={classes.socialMedia} src={Images.twitter} onClick={() => this.openSocialMedia('https://twitter.com/NSDLeGovernance')} />
                        <ReactSVG className={classes.socialMedia} src={Images.instagram} onClick={() => this.openSocialMedia('https://www.instagram.com/nsdlegovernance/')} />
                    </div>
                </div>
                <div>
                    <Button variant="contained" className={classes.homeButton} type="submit" onClick={() => this.startChat('status')} fullWidth  >
                        Click here to know your PAN/TAN status
                    </Button>
                    <Button variant="contained" className={classes.homeButton} type="submit" onClick={() => this.startChat('chat')} fullWidth  >
                        Chat
                    </Button>
                </div>
            </div>
        )
    }
}
NSDLHome.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter((withStyles(homeStyles)(NSDLHome)))
