import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { homeStyles } from '../../Styles/homeStyles';
import { inject, observer } from 'mobx-react';
import { ReactSVG } from 'react-svg'
import Images from '../../Theme/Images';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import SearchQuery from './SearchQuery';

import { withRouter } from 'react-router-dom';
//import Carousel from 'react-material-ui-carousel'
import LoginDrawer from './LoginDrawer';

@inject('queryStore', 'commonStore', 'userStore')
@observer
class Home extends Component {
    state = {
        showLoder: true,
        openOptions: false
    }

    closeOptions = () => {
        this.setState({ openOptions: false })
    }

    componentDidMount() {
        const { version } = this.props.commonStore
        if (this.props.userStore.customerData && this.props.userStore.customerData.value) {
            this.props.commonStore.setChatRefresh(false)
            this.props.userStore.setToken('')
            this.props.queryStore.getFaqs()
            let that = this;
            setTimeout(that.showInitialLoader, 100);
            this.props.userStore.resetUserDetails()
        }
        else
            this.props.history.push(`/${version}/`)

    }

    showInitialLoader = () => {
        this.setState({ showLoder: false })
    }


    askQuery = (item) => {
        const { version } = this.props.commonStore
        if (item.type === 'INTENT_ACK_STATUS') {
            this.props.history.push(`/${version}/captcha`)
            this.props.history.push({
                pathname: `/${version}/captcha`,
                state: { type: "captcha" }
            })
        }
        else {
            this.props.history.push({
                pathname: `/${version}/chat`,
                state: {
                    queryText: item.text,
                    category: item.category,
                    slug: item.slug
                }
            })
        }

        /*let temp = [{ intent: item.type, filter: "HEALTH_POLICY" }]
        if (this.props.userStore.authenticated) {
            this.props.queryStore.askQuery(temp, null)
                .then(() => {
                    if (!this.props.queryStore.inProgress) {
                        if (this.props.queryStore.policyList && this.props.queryStore.policyList.length) {
                            this.props.history.push({
                                pathname: '/policies',
                                state: { input: [{ intent: item.type, filter: "HEALTH_POLICY" }] }
                            })
                        }
                        else if (this.props.queryStore.redirectToChat) {
                            this.props.history.push('/chat')
                        }
                        else {
                            this.props.commonStore.setHeaderText('Pending Queries')
                            this.props.history.push({
                                pathname: '/queries/pending',
                                state: { type: "pending" }
                            })
                        }
                    })
            }
            else
                this.props.history.push({
                    pathname: '/login',
                    state: { type: 'pending', createQuery: temp }
                })
        }
        else
            this.props.history.push({
                pathname: '/login',
                state: { type: 'pending' , createQuery: temp}
            })*/

    }

    renderLoader = () => {
        const { classes } = this.props;
        return (
            <div className={classes.outerLoader}>
                <CircularProgress size={50} thickness={2} className={classes.progress} />
            </div>
        )
    }

    renderFAQList = () => {
        const { classes } = this.props;
        const { faqs } = this.props.queryStore;
        return (
            faqs.map((item, index) => (
                item.icon === 'card_icon' ? null :
                    <ListItem classes={{ divider: 'lineDivider' }} button key={item.text} divider={true} onClick={(event) => this.askQuery(item)}>
                        {/*<ListItemIcon style={{ minWidth: '2vw' }}>
                        <ReactSVG
                            beforeInjection={svg => {
                                svg.classList.add(classes.icons)
                            }}
                            src={Images[`${item.icon}`]} />
                    </ListItemIcon>*/}
                        <div className={classes.listItems}>
                            <div className={classes.categoryContainer}>
                                {/*item.category ? <div className={classes.categoryText}>{item.category}</div> : null*/}
                                <div className={`${classes.questionText} mediumText`}>{item.text && item.text.length > 96 ? (item.text.substring(0, 70) + '...') : item.text} </div>
                            </div>
                            <ListItemSecondaryAction>
                                <ReactSVG src={Images.forwardIcon} style={{ cursor: 'pointer' }} />
                            </ListItemSecondaryAction>
                        </div>
                    </ListItem>
            ))
        )
    }

    openPreferences = () => {
        const { version } = this.props.commonStore
        this.props.history.push({
            pathname: `/${version}/preferences`
        })
    }


    initiateChat = () => {
        const { version } = this.props.commonStore
        this.props.userStore.resetAnonymousId()
        this.props.history.push(`/${version}/chat`)
    }

    render() {
        const { classes } = this.props;
        const { inProgress, faqs, pendingList } = this.props.queryStore;
        const { openOptions } = this.state;
        const { authenticated } = this.props.userStore;
        const { showSearchOnly } = this.props.commonStore
        if (inProgress)
            this.renderLoader()
        return (
            <div className={classes.homeContainer}>
                {!showSearchOnly ? <div>

                    <div>
                        {faqs ?
                            <List>
                                {/* <ListItem classes={{ divider: classes.panTanStatus }} button divider={true} onClick={(event) => this.askQuery({ type: 'INTENT_ACK_STATUS' })}>

                                    <div className={classes.categoryContainer}>
                                        <div className={`${classes.categoryText} semiBold mediumText`} style={{ color: 'white' }}>{'Click here to know your PAN/TAN status'}</div>
                                    </div> <ListItemSecondaryAction   onClick={(event) => this.askQuery({ type: 'INTENT_ACK_STATUS' })}>
                                        <ReactSVG src={Images.whiteForwardIcon} style={{ cursor: 'pointer' }} />
                                    </ListItemSecondaryAction>

                                </ListItem> <br />*/}

                                <div className={classes.newFaqContainer}>
                                    <ListItem classes={{ divider: classes.commonQuestions }} button divider={true} >

                                        <div className={classes.categoryContainer}>
                                            <div className={`${classes.categoryText} semiBold mediumText`} style={{ color: 'white', lineHeight: '1', textAlign: 'center' }}>{'Get started by selecting a query from below options or Type your own query'}</div>
                                        </div>
                                    </ListItem>
                                    {this.renderFAQList()}
                                </div>
                            </List>
                            : null}</div>
                </div> : null}
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <div className={classes.bottomContainer}>
                        <SearchQuery></SearchQuery>
                        <LoginDrawer open={openOptions} closeLogin={this.closeOptions} />
                    </div>
                </div>
            </div>
        )
    }
}
Home.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter((withStyles(homeStyles)(Home)))
