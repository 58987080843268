import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { queryListStyles } from '../../../../Styles/queryStyles';
import { inject, observer } from 'mobx-react';
import { ReactSVG } from 'react-svg'
import Images from '../../../../Theme/Images';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ProcessedForm from './ProcessedForm';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import { Divider } from '@material-ui/core';

@inject('queryStore')
@observer
class UploadDocuments extends Component {
    constructor(props) {
        super(props);
        this.frontImgInput = React.createRef()
        this.backImgInput = React.createRef()
        this.state = {
            files: {
                frontImg: {},
                backImg: {}
            },
            showfrontImg: false,
            showbackImg: false,
            frontImgSrc: '',
            backImgSrc: ''
        };
    }

    openFileModal = (type) => {
        if (type === 'front')
            this.frontImgInput.current.click();
        else
            this.backImgInput.current.click();

    }

    onChangeFile = (event, type) => {
        event.stopPropagation();
        event.preventDefault();
        let file = event.target.files[0];
        if (file === undefined)
            return;

        const files = Object.assign({}, this.state.files, {
            [type]: file
        });
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = function (e, srcType) {
            this.setState({
                [type + 'Src']: [reader.result][0]
            })
        }.bind(this);
        this.setState({ files, ['show' + type]: true });
    }

    clearImage = (event, type) => {
        if (type === undefined)
            this.setState({
                frontImg: {},
                backImg: {},
                frontImgSrc: '',
                backImgSrc: '',
                showfrontImg: false,
                showbackImg: false
            })
        else
            this.setState({
                [type]: {},
                ['show' + type]: false,
                [type + 'src']: ''
            })
    }

    upload = () => {
        const { item } = this.props;
        this.props.queryStore.uploadDocuments(item.tempId, 2, this.state.files)
    }

    render() {
        const { classes, item } = this.props;
        const { uploadProgress, processedData, currentQuery } = this.props.queryStore;
        const { showfrontImg, showbackImg, frontImgSrc, backImgSrc } = this.state;
        let showBackImageUpload = false;
        if (item.allStepsData.step_2.payload.find(x => x.key === 'imageBack'))
            showBackImageUpload = true;
        return (
            <div style={{ width: '100%' }}>
            <Divider/>
                {
                    processedData !== undefined && item.tempId == currentQuery ?
                        <div>
                            <ProcessedForm clearData={this.clearImage}></ProcessedForm>
                            {processedData.error === undefined ? this.props.renderSatisfactionButtons() : null}
                        </div> :
                        <div>
                            <Typography className={classes.uploadText}>{item.allStepsData && item.allStepsData.step_2 && item.allStepsData.step_2.textToShow}</Typography>
                            <div className={classes.imageContainer}>
                                {
                                    !showfrontImg ?
                                        <div className={classes.img}  onClick={(event) => { this.openFileModal('front') }}>
                                            <ReactSVG
                                                beforeInjection={svg => {
                                                    svg.classList.add(classes.imgSvg)
                                                }}
                                                src={Images.camera} />
                                            <div>Front Image</div>
                                            <input ref={this.frontImgInput} accept="image/*" type="file" style={{ display: "none" }} onChange={(event) => this.onChangeFile(event, 'frontImg')} />

                                        </div>
                                        :
                                        <div className={classes.img} style={{ display: 'flex', position: 'relative',justifyContent: 'flex-end', width:  '40%' ,  backgroundImage: `url(${frontImgSrc})` }}>
                                            <ReactSVG src={Images.close} className={classes.cancel} onClick={(event) => this.clearImage(event, 'frontImg')} />

                                        </div>
                                }
                                {
                                    showBackImageUpload ? (!showbackImg ?
                                        <div className={classes.img} onClick={(event) => { this.openFileModal('back') }}>
                                            <ReactSVG
                                                beforeInjection={svg => {
                                                    svg.classList.add(classes.imgSvg)
                                                }}
                                                src={Images.camera} />
                                            <input ref={this.backImgInput} accept="image/*" type="file" style={{ display: "none" }} onChange={(event) => this.onChangeFile(event, 'backImg')} />
                                            Back Image
                            </div> :
                                        <div className={classes.img} style={{ display: 'flex', justifyContent: 'flex-end', position: 'relative', backgroundImage: `url(${backImgSrc})` }}>
                                            <ReactSVG src={Images.close} className={classes.cancel} onClick={(event) => this.clearImage(event, 'backImg')} />
                                        </div>) : null
                                }
                            </div>
                            <div className={classes.footerContainer} style={{ marginTop: '3vh' }}>
                                <Button className={classes.footerButton} type="submit" onClick={(event) => this.clearImage(event)} >
                                    Cancel
                    </Button>
                                {uploadProgress && item.tempId === currentQuery ? <Button variant="contained" className={classes.footerButton} >
                                    <CircularProgress size={30} thickness={5} className={classes.loading} />
                                </Button> :
                                    <Button disabled={!showfrontImg && !showbackImg} className={classes.footerButton} type="submit" onClick={(event) => this.upload()}>
                                        Upload
                    </Button>}
                            </div>
                        </div>


                }
            </div>
        )
    }
}
UploadDocuments.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withRouter(withStyles(queryListStyles)(UploadDocuments));
