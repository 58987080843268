import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { queryListStyles } from '../../Styles/queryStyles';
import { inject, observer } from 'mobx-react';
import { ReactSVG } from 'react-svg'
import Images from '../../Theme/Images';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { NavLink } from 'react-router-dom';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import QueryCard from './QueryCard';
import { withRouter } from 'react-router-dom';
let page = 1;
@inject('queryStore', 'commonStore')
@observer
class QueryList extends Component {

    componentDidMount() {
        let type = "pending"
        if (this.props.location.state !== undefined)
            type = this.props.location.state.type
        page = 1
        this.props.queryStore.getQueries(type, 0);
       
    }

    loadMore = () => {
        let type = "pending"
        if (this.props.location.state !== undefined)
            type = this.props.location.state.type
        this.props.queryStore.getQueries(type, page++)
    }

    handleScroll = (event) => {
        const { totalQuery, queryList } = this.props.queryStore;
        var obj = document.getElementById('myScrollingQueryList');
        if (obj.scrollTop >= (obj.scrollHeight - obj.offsetHeight)) {
            if (totalQuery > queryList.length)
                this.loadMore()
        }
    }

    renderQueries = () => {
        const { classes } = this.props;
        const { queryList } = this.props.queryStore;
        return (
            <List className={classes.listPadding}>
                {
                    queryList.map((item, index) => (
                        <div key={index} style={{paddingTop: '0vh'}}>
                            {/*<div className={classes.tempIdText}>Temp ID: {item.tempId}</div>*/}
                            <QueryCard type={this.props.location.state !== undefined ? this.props.location.state.type : 'pending'}
                                item={item}
                                index={index}>
                            </QueryCard>
                        </div>
                    ))
                }
            </List>
        )
    }

    render() {
        const { classes } = this.props;
        const { inProgress, queryList } = this.props.queryStore;
        if (inProgress && page === 1)
            return (
                <div className={classes.outerLoader}>
                    <CircularProgress size={50} thickness={2} className={classes.progress} />
                </div>
            )
        return (
            <React.Fragment>
                <div className={classes.queryList} id="myScrollingQueryList" onScroll={this.handleScroll}>
                    {
                        queryList && queryList.length ? this.renderQueries() : <div className={classes.noQueries}>
                           No entries found !
                        </div>
                    }
                    {
                        inProgress ?
                            <div className={classes.outerLoader}>
                                <CircularProgress size={70} thickness={2} className={classes.progress} />
                            </div>
                            : null
                    }

                </div>

            </React.Fragment>
        )
    }
}
QueryList.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withRouter(withStyles(queryListStyles)(QueryList));
