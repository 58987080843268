
const drawerWidth = '19vw';

export const commonStyles = theme => ({
  cancelButton: {
    fontSize: '13px',
    color: theme.palette.warmGray,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: `${theme.palette.cancelGray} !important`,
    }
  },
  fileWrap: {
    marginBottom: '1vh'
  },
  attPreviewContainer: {
    display: 'flex',
    margin: '0vh 20px',
    justifyContent: 'center',
    marginBottom: '1vh',
    flexWrap: 'wrap',
    maxWidth: '77vw'
  },
  attPreviewDialog: {
    backgroundColor: theme.palette.white,
    padding: '3vh 3vw !important',
    minWidth: '77vw'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  button: {
    backgroundColor: theme.palette.orange,
    color: theme.palette.white,
    textTransform: 'unset',
    marginLeft: '1vw',
    marginRight: '1vw',
    fontSize: '13px',
    fontWeight: 'bold',
    borderRadius: '10px'
  },
  previewContainer: {
    overflowX: 'scroll',
    display: 'flex',
    margin: '0vh 20px'
  },
  closeIcon: {
    position: 'absolute',
    top: '0',
    right: '0',
    cursor: 'pointer'
  },
  imgContainer: {
    width: '75px',
    height: '75px',
    backgroundColor: theme.palette.paleGrayThree,
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: '4px',
    position: 'relative',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  img: {
    backgroundColor: theme.palette.tempBlue,
    width: '75px',
    color: theme.palette.white,
    display: 'flex',
    maxHeight: '75px',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    cursor: 'pointer',
    borderRadius: '8px',
  },
  fileContainer: {
    height: '75px',
    backgroundColor: theme.palette.paleGrayThree,
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: '4px',
    position: 'relative',
    width: '75px',
    border: 'solid 1px lightGray'
  },
  svg: {
    height: '100px',
    cursor: 'pointer',
    width: '100%'
  },
  forward: {
    position: 'absolute',
    right: '10px',
    top: '16px'
  },
  outerLoader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '400px'
  },
});

export const headerStyles = theme => ({
  back: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    fontWeight: '100',
    cursor: 'pointer',
    left: '22px',
    height: '100%',
    transform: 'rotate(180deg)',
    position: 'absolute',
    right: '10px'
  },
  endChat: {
    transform: 'rotate(180deg)',
    borderRadius: '15px',
    backgroundColor: '#9b2726',
    padding: '1vh 2vh'
  },
  logo: {
    width: '32%',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  hContainer: {
    backgroundColor: theme.palette.red, width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'fixed',
    height: '61px !important',
  },
  subHeadingContainer: {
    backgroundColor: theme.palette.red,
    padding: '0vh 2vw 1vh 5vw',
  },
  minimize: {
    color: theme.palette.white,
    margin: '2.5vh 7vw 2vh 4vw',
    cursor: 'pointer',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 'bold',
    position: 'absolute',
    top: '0',
    right: '0'
  },
  feedbackHeader: {
    fontWeight: 'normal',
    textAlign: 'center',
    lineHeight: '1.7'
  },
  closeButton: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    cursor: 'pointer'
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  headerText: {
    color: theme.palette.white,
    fontSize: '14px',
    fontWeight: 'normal',
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '4px 0vw 0vh 0vw'
  },
  headerContainer: {
    color: theme.palette.white,
    fontSize: '14px',
    fontWeight: '500',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  header: {
    margin: '0vh 1vw',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column'

  },

  profile: {
    cursor: 'pointer'
  },
  normalHeader: {
  //  backgroundColor: theme.palette.red,
    // marginLeft: '10px',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    height: '61px',
    margin: '0vh 22px'
  },
  profileHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '0px',
    paddingBottom: '4vh',
    backgroundColor: theme.palette.red,
  },
  subHeader: {
    color: theme.palette.white,
    fontSize: '12px',
    width: '100%'
  },
  backIcon: {
    left: '10px',
    cursor: 'pointer',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '6px',
    // paddingTop:'3px',
    marginLeft: '-2px',
    borderRadius: '6px',
    position: 'fixed',
    '&:hover': {
      backgroundColor: theme.palette.darkRed
    }
  },
  preferenceHeader: {
    backgroundColor: theme.palette.white,
    color: theme.palette.black
  },
  outerLoader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '400px'
  },
})

export const snackbarStyles = theme => ({
  success: {
    backgroundColor: theme.palette.turquoise
  },
  info: {
    borderRadius: '8PX',
    border: `solid 1px ${theme.palette.red}`,
    color: theme.palette.red,
    backgroundColor: theme.palette.white
  },
  error: {
    backgroundColor: 'red'
  },

})

