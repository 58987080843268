
export const loginStyles = theme => ({

    loginContainer: {
        overflow: 'hidden',
        height: '102vh',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: '10% 0',
    },
    button: {
        backgroundColor: theme.palette.orange,
        color: theme.palette.white,
        textTransform: 'unset',
        height: '48px',
        borderRadius: '10px',
        marginTop: '40px',
        fontSize: '13px'


    },
    formStyle: {
        width: '500px',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    error:{
        display: 'flex',
        marginTop: '1vh',
        justifyContent: 'center'
    },
    textFieldInput: {
        padding: '14px 20px',
        fontSize: '12px',
        height: '50px',
        boxSizing: 'border-box'
    },
    formControl: {
        width: '100%'
    },
    errorStyle: {
        color: theme.palette.errorRed,
        fontSize: '12px',
        margin: '0vh 5px',
        marginBottom: '5px',
    },
    loading: {
        color: theme.palette.white,
    },
    signup: {
        color: theme.palette.black,
        textTransform: 'unset',
    },
    forgot: {
        color: theme.palette.black,
        textTransform: 'unset',
    },
    tokenError: {
        color: theme.palette.errorRed,
        marginBottom: '5px'
    },
    bottomAction: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '500px',
        marginTop: '10px',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    action: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '500px'
    },
    buttonFlex: {
        display: 'flex',
        justifyContent: 'space-around'
    },
    outerLoader: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '400px'
    },
    progress: {
        color: theme.palette.red,
    },
    loginText: {
        fontSize: '13px'
    },
    editContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
        fontSize: '13px',
        margin: '1vh',
        textDecoration: 'underline',
    },
    disabled: {
        pointerEvents: 'none',
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '13px',
        margin: '1vh',
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'not-allowed !important'
        }
    }

})
