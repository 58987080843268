

export const homeStyles = theme => ({
  welcomeTitle:{
    color: theme.palette.white,
    fontWeight: '500',
    fontSize: '13px',
    color: theme.palette.white,
    height: 'fit-content',
    padding: '1vh',
    backgroundColor: '#b33837',
    position: 'absolute',
    top: '35%',
    left: '58%',
    borderRadius: '10px',
    "&&:after": {
      position:'absolute',
      content: ' ',
      width: '0',
      height: '0',
      left: '-20px',
      right: 'auto',
      top: '0',
      bottom: 'auto',
      border: '22px solid',
      borderColor: 'blue transparent transparent transparent'
    }
   
},
mascotContainer:{
  display: 'flex',
  justifyContent: 'center',
  position: 'relative'
},
welcomeMessage:{
    color: '#818181',
    lineHeight: '1.4'
   
},
socialMedia:{
    width: '10%',
    marginRight: '1vh',
    cursor: 'pointer',
    marginTop: '6vh'
},
welcome:{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'justify',
    fontSize: '13px',
    fontWeight: '500',
    color: '#5a5a5a',
    marginTop: '4vh'
},
  captchaImg: {
    //borderBottom: 'solid 1px lightGray',
    padding: '1vh 1vw',
    borderRadius: '8px',
    backgroundColor: theme.palette.white,
  },
  captcha: {
    width: '175px',
    display: 'flex',
    justifyContent: 'space-around',
    border: 'solid 1px lightGray',
    borderRadius: '8px',
    backgroundColor: theme.palette.white,
    margin: '0 auto',
    marginBottom: '2vh',
    padding: '0.4vh'

  },
  refreshIcon: {
    cursor: 'pointer',
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: `${theme.palette.charcoalGray} !important`
  },
  errorStyle: {
    color: theme.palette.errorRed,
    fontSize: '12px',
    margin: '0vh 5px',
    marginBottom: '5px',
    fontWeight: '500'
  },
  ackError: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '2px'
  },
  error: {
    display: 'flex',
    marginTop: '2vh',
    justifyContent: 'center'
  },
  radioSelected: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center'
  },
  radioOption: {
    width: '15px',
    height: '15px',
    opacity: '0.3',
    borderRadius: '5px',
    border: 'solid 1px black',
    backgroundColor: theme.palette.white,
    marginRight: '1vw',
    cursor: 'pointer'
  },
  radioContainer: {
    display: 'flex',
    marginRight: '5vw'
  },
  selectedRadio: {
    width: '7px',
    height: '7px',
    borderRadius: '2px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.orange,
    opacity: '1'

  },
  selectedRadioOption: {
    opacity: '1',
    borderColor: theme.palette.orange
  },
  radioLabel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'left'
  },
  radio: {
    fontSize: '13px',
    margin: '1vh 1vw',
    display: 'flex'
  },
  captchaContainer: {
    width: '100%',
    margin: '2vh 0vw'
  },
  formControl: {
    width: '100%'
  },
  categoryContainer: {
    width: '100%',
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: 'normal'
  },
  panTanStatus: {
    width: '100%',
    height: '50px',
    fontSize: '12px',
    color: '#939292',
    fontWeight: '500',
    lineHeight: 'normal',
    backgroundImage: 'linear-gradient(to top, #ea8b49, #ddb54f)',
    borderRadius: '8px',
    boxShadow: '0px 3px 7px 0 rgba(0, 0, 0, 0.1)'
  },
  newFaqContainer: {
    margin: '1vh 0vw',
    boxShadow: '0px 3px 7px 0 rgba(0, 0, 0, 0.1)'
  },
  categoryText: {
    fontWeight: 'normal',
    lineHeight: '1.8',
    color: '#271c1c'
  },

  questionText: {
    fontWeight: 'normal',

    color: '#271c1c'

  },
  loading: {
    color: theme.palette.white
  },
  updateInput: {
    margin: '0vh 1vw',
    fontSize: '13px',
    color: theme.palette.steelGray,
    height: '100%',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  icons: {
    marginRight: '3vw'
  },
  rightChevron: {
    color: theme.palette.red
  },
  noBorder: {
    border: 'none'
  },
  selectedListItem: {
    paddingLeft: '0px',
    paddingRight: '0px'
  },
  queryCancel: {
    color: theme.palette.steelGray,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'

  },
  lineDivider: {
    padding: '0px 19px !important',
    height: '50px !important',
    borderBottom: 'solid 1px rgba(0,0,0,0.1)',
    backgroundColor: theme.palette.white,
  },
  commonQuestions: {
    borderTopLeftRadius: '10px !important',
    borderTopRightRadius: '10px !important',
    padding: '0px 19px !important',
    // border: 'none !important',
    height: '50px !important',
    borderBottom: 'solid 1px rgba(0,0,0,0.1)',
    backgroundColor: '#b33837',
    color: theme.palette.white,
    '&:hover': {
      backgroundColor: `#b33837`,
    }
  },

  firstItem: {
    borderRadius: '10px !important',
    padding: '0px 19px !important',
    border: 'none !important',
    height: '50px !important'
  },
  menuContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  highlighted: {
    color: theme.palette.red,
    fontWeight: 'bold'
  },
  bottomContainer: {
    width: 'calc(100% - 10px)',
    display: 'flex',
    borderRadius: '10px',
    border: 'solid 1px rgba(0, 0, 0, 0.05)',
    backgroundColor: theme.palette.white,
    boxShadow: '0 0 19px 0 rgba(0, 0, 0, 0.07)',
    margin: '0vh 5px 0vh 5px',
    cursor: 'text'
  },
  menuIcon: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    color: theme.palette.red,
    backgroundColor: theme.palette.white,
    padding: '0vh 1vh',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    height: '6vh'
  },
  outerLoader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '400px'
  },
  progress: {
    color: theme.palette.red,
  },
  bulletIcon: {
    color: theme.palette.red,
    fontSize: 'medium',
  },
  homeContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: `calc(100% - 1vh)`,
    backgroundColor: theme.palette.background,
    borderTopLeftRadius: '18px',
    borderTopRightRadius: '18px',
    padding: '1vh 14px 0vh 14px',
    width: 'calc(100% - 28px)',
    overflowY: 'scroll'

  },
  nsdlHome:{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: `calc(100% - 1vh)`,
    backgroundColor: theme.palette.background,
    borderTopLeftRadius: '18px',
    borderTopRightRadius: '18px',
    padding: '1vh 0 0vh 0',
    width: 'calc(100%)',
    overflowY: 'hidden',
    fontSize: '14px',
    overflowX: 'hidden'
  },
  nsdlContainer:{
    width: 'calc(100% - 60px)',
    overflowY: 'scroll',
    overflowX: 'hidden',
    padding: '0 30px'
  },
  queryContainer: {
    padding: '15px 15px 15px 0vw',
    marginRight: '15px',
    minHeight: '2vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.white,
    width: '100%'
  },
  ipContainer: {
    marginLeft: '0px',
    borderColor: theme.palette.silver,
    borderRadius: '10px',
    borderWidth: '1px',
    borderStyle: 'solid',
    marginRight: '0px',
    flexGrow: '1',

  },
  textFieldInput: {
    fontSize: '13px',
    borderRadius: '10px',
    backgroundColor: theme.palette.white
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '2vh'
  },

  cancelButton: {
    backgroundColor: theme.palette.white,
    color: theme.palette.orange,
    border: `solid 1px ${theme.palette.orange}`,
    textTransform: 'unset',
    borderRadius: '10px',
    marginLeft: '1vw',
    marginRight: '1vw',
    fontSize: '13px',
    fontWeight: '500',
    width: '63px',
    '&:hover': {
      backgroundColor: theme.palette.white,
    },
    display: 'flex',
    justifyContent:'center',
    flexDirection: 'column',
    textAlign: 'center',
    borderRadius: '19px',
    cursor: 'pointer',
    zIndex: '10',
    position: 'relative'
  },
  button: {
    backgroundColor: theme.palette.orange,
    color: theme.palette.white,
    textTransform: 'unset',
    borderRadius: '10px',
    marginLeft: '1vw',
    marginRight: '1vw',
    fontSize: '13px',
    fontWeight: '500',
    width: '68px',
    '&:hover': {
      backgroundColor: theme.palette.orange,
    },
    borderRadius: '19px'
  },
  policyButton: {
    backgroundColor: theme.palette.red,
    color: theme.palette.white,
    textTransform: 'unset',
    borderRadius: '8px',
    marginLeft: '1vw',
    marginRight: '1vw',
    fontSize: '13px',
    fontWeight: '500',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.orange,
    }
  },
  homeButton: {
    backgroundColor: theme.palette.red,
    color: theme.palette.white,
    textTransform: 'unset',
    borderRadius: '8px',
    fontSize: '13px',
    fontWeight: '500',
    width: 'calc(100% - 60px)',
    margin: '0 30px 15px 30px',
    '&:hover': {
      backgroundColor: theme.palette.orange,
    }
  },
  validationError:{
    display: 'flex',
    width: '100%',
    margin:'1vh 0vw'
},
errorStyle: {
    color: theme.palette.errorRed,
    fontSize: '11px',
    margin: '0vh 5px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%'
},
  doYouMeanText: {
    fontSize: '13px',
    color: theme.palette.warmGray,
    cursor: 'pointer',
    margin: '3vh 0vw'
  },
  listItems: {
    marginLeft: '1vw',
    width: '80%'
  },
  inputRoot: {
    width: '200%'
  },
  name: {
    fontSize: '12px',
    fontWeight: 'bold',
    cursor: 'pointer'
  },
  email: {
    color: theme.palette.warmGray,
    cursor: 'pointer'
  },
  drawerPaper: {
    height: '80%',
    padding: '3vw 30px',
    borderTopRightRadius: '20px',
    borderTopLeftRadius: '20px',
    backgroundColor: theme.palette.white
  },
  loginDrawer: {
    height: '88%',
    padding: '3vw',
    borderTopRightRadius: '20px',
    borderTopLeftRadius: '20px',
    backgroundColor: theme.palette.white
  },
  selectedQuery: {
    margin: '0vh 1vw',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '13px',
    width: '100%',
    cursor: 'pointer'
  },
  subHeader: {
    color: theme.palette.warmGray,
    fontSize: '12px',
    padding: '2vh 4vw 1vh 4vw'
  },
  policyContainer: {
    height: 'calc(100%  - 30px)',
    fontSize: '14px',
    backgroundColor: theme.palette.background,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: '20px 30px 0px 30px',
    lineHeight: '2.14'
  },
  listContainer: {
    backgroundColor: theme.palette.white,
    borderRadius: '10px',
    marginTop: '3VH'
  },

  policyListContainer: {
    borderRadius: '10px',
    paddingBottom: '0vh',
    paddingTop: '0vh',
    marginTop: '2vh'
  },
});

export const loginStyles = theme => ({
  listItem: {
    '&:hover': {
      backgroundColor: `${theme.palette.paleGray} !important`
    }
  },
  loginDrawer: {
    height: '88%',
    padding: '3vw',
    borderTopRightRadius: '20px',
    borderTopLeftRadius: '20px',
    backgroundColor: theme.palette.white
  },
  container: {
    padding: '3vh 2vw',
    height: '100%'
  },
  heading: {
    fontSize: '14px',
    fontWeight: '500',
    marginBottom: '2vh',
    marginTop: '3vh',
    marginLeft: '16px'
  },
  forward: {
    position: 'absolute',
    right: '10px',
    top: '16px',
    color: theme.palette.red
  },
  name: {
    fontSize: '12px',
    margin: '0vh 2vw',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    fontWeight: '500'

  },
  desc: {
    fontSize: '10px',
    color: theme.palette.warmGray,
    margin: '0vh 2vw',
  }

})