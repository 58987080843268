import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import FeedbackDetails from './FeedbackDetails';
import { withRouter } from 'react-router-dom';
import { feedbackStyles } from '../../Styles/feedbackStyles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FeedbackDialog from './FeedbackDialog';
import { ReactSVG } from 'react-svg';
import Images from '../../Theme/Images';
import ChatFeedbackDialog from '../Queries/Chat/ChatFeedbackDialog';
import PoweredBy from '../../Common/PoweredBy';
import FeedbackRating from './FeedbackRating';
import RadioGroup from '@material-ui/core/RadioGroup';
import clsx from 'clsx';

const rating_labels = ["Needs improvement", "Average", "Good", "Very Good", "Excellent"];
const emailRegex = /^[A-Za-z0-9](\.?[A-Za-z0-9_-]){0,}@[A-Za-z0-9-]+\.([A-Za-z]{1,6}\.)?[A-Za-z]{2,6}$/;
const mobileRegex = /^[1-9][0-9]{9,}$/;

@inject('feedbackStore', 'queryStore', 'commonStore', 'chatStore', 'userStore')
@observer
class FeedbackChat extends Component {

    state = {
        rating: 0,
        ratingText: '',
        errorMessage: '',
        feedbackText: '',
        email: '',
        validationErrors: [],
        hover: -1,
        feedbackType: 'skip',
        mobile: '',
        consent: ''
    }

    componentDidMount() {
        const { version } = this.props.commonStore
        this.props.commonStore.setChatRefresh(false)
        this.props.commonStore.setGoBackTo('ackStatus')
        if (!this.props.queryStore.showFeedback)
            this.props.history.push({
                pathname: `/${version}/home`,
            })
    }

    handleConsentChange = (value) => {
        this.setState({
            consent: value
        }, () => {
            this.sendFeedback()
        })
    }

    setRating = (value) => {
        const { validationErrors } = this.state
        if (value > 1) {
            const { validationErrors } = this.state;
            delete validationErrors['feedbackText']
            this.setState({ validationErrors });
        }
        delete validationErrors['rating']
        this.setState({
            rating: value,
            ratingText: rating_labels[value - 1],
            feedbackType: value == 0 ? 'skip' : (value > 2 ? 'satisfied' : 'dissatisfied'),
        })


    }

    setHover = (event, value) => {
        this.setState({
            hover: value,
        })
    }


    renderConsentForm = () => {
        const { consent } = this.state
        const { classes } = this.props;
        const { consentProgress } = this.props.chatStore
        return (
            <div className={classes.consentContainer}>
                <div className={classes.consentHeading}>Consent Statement</div>
                <br />
                <div >Please provide your consent to use the detail shared to update you with the latest NEWS letters, updates on services offered as well as new  features and services launch by Protean.<br /><br />By selecting the option “<b>Yes</b>” you agree to receive promotional email or SMS from Protean eGov Technologies Limited.</div>

                <RadioGroup aria-label="Input Type"
                    name="inputType"
                    value={consent} >
                    <div className={classes.fradio} key={consent}>
                        <div className={classes.fradioContainer}>
                            {consent === 'YES' ?
                                <div className="colCenter" >
                                    <div className={clsx({
                                        [classes.fradioOption]: true,
                                        [classes.fselectedRadioOption]: true,
                                    })} onClick={(e) => this.handleConsentChange('YES')} >
                                        <div className={classes.fradioSelected} >
                                            <div className={classes.fselectedRadio}></div>
                                        </div>
                                    </div>

                                </div>
                                : <div className="colCenter"> <div onClick={(e) => this.handleConsentChange('YES')} className={classes.fradioOption}></div></div>}
                            <div className={classes.fradioLabel}>Yes</div>
                        </div>
                        <div className={classes.fradioContainer}>
                            {consent === 'NO' ?
                                <div className="colCenter" >
                                    <div className={clsx({
                                        [classes.fradioOption]: true,
                                        [classes.fselectedRadioOption]: true,
                                    })} onClick={(e) => this.handleConsentChange('NO')} >
                                        <div className={classes.fradioSelected} >
                                            <div className={classes.fselectedRadio}></div>
                                        </div>
                                    </div>

                                </div>
                                : <div className="colCenter"> <div onClick={(e) => this.handleConsentChange('NO')} className={classes.fradioOption}></div></div>}
                            <div className={classes.fradioLabel}>No</div>
                        </div>
                    </div>
                </RadioGroup>


            </div>

        )
    }

    sendFeedback = (tempId, type) => {
        const { version } = this.props.commonStore
        const { rating, feedbackText, email, note, validationErrors, feedbackType, mobile } = this.state;
        let valid = true
        /* if (rating <= 0) {
             validationErrors['rating'] = 'Please select a suitable rating before proceeding'
             this.setState({ validationErrors });
             valid = false;
         }
         else
             delete validationErrors['rating']*/

        /* if (!feedbackText.trim().length && feedbackType === 'dissatisfied') {
             validationErrors['feedbackText'] = 'Please provide your reason before you submit'
             this.setState({ validationErrors });
             valid = false;
         }
         else
             delete validationErrors['feedbackText']
       if (emailRegex.test(mobile) || mobileRegex.test(mobile)) {
             delete validationErrors['mobile']
         }
         else {
             validationErrors['mobile'] = 'Please enter valid Email ID or 10 digit valid Mobile Number before proceeding'
             this.setState({ validationErrors });
             valid = false;
         }
 */
        if (valid) {
            this.setState({
                validationErrors: {}
            });
        }
        else {
            this.setState({
                consent: 'ff'
            }, () => {
            })
            return
        }


        this.setState({
            errorMessage: ''
        })
        if (this.props.location.appState && this.props.location.appState.from === 'ackStatus') {
            this.props.chatStore.setConsent(this.state.consent)
            this.props.feedbackStore.sendFeedback(rating === 0 ? 'skip' : feedbackType, tempId, feedbackText === 'Other' ? note : feedbackText, rating, email)
                .then(() => {
                    setTimeout(() => {
                        this.props.chatStore.setFeedbackDialog(false);
                        this.props.userStore.setCustomerData({})
                        this.props.history.push({
                            pathname: `/${version}/`,
                        })
                    }, 2500)
                })
        }
        else {
            this.props.chatStore.setConsent(this.state.consent)
            this.props.chatStore.endChat(feedbackType, feedbackText, rating, emailRegex.test(mobile) ? mobile : '', mobile)
                .then(() => {
                    setTimeout(() => {
                        this.props.chatStore.setFeedbackDialog(false);
                        this.props.userStore.setCustomerData({})
                        this.props.history.push({
                            pathname: `/${version}/`,
                        })
                    }, 2500)
                })
        }

    }

    handleInputChange = (value) => {
        const { validationErrors } = this.state
        this.setState({ mobile: value })
        delete validationErrors['mobile']
        this.setState({ validationErrors });
    }

    render() {
        const { classes } = this.props;
        const { ticketDetails } = this.props.queryStore
        const { inProgress } = this.props.feedbackStore
        const { rating, ratingText, errorMessage, feedbackText, email, note, validationErrors, hover, feedbackType, mobile } = this.state
        const { chatData, endProgress } = this.props.chatStore;
        return (
            <div className={classes.feedbackContainer}>

                <div className={classes.feedbackInnerContainer}>
                    {this.props.location.appState && this.props.location.appState.from && this.props.location.appState.from === 'ackStatus' ? <FeedbackDetails /> : null}
                    <div >
                        <div className={classes.feedbackInfo}>
                            <FeedbackRating handleRatingChange={this.setRating} />
                            {validationErrors && validationErrors.rating ?
                                <div className={classes.validationError}>
                                    <ReactSVG src={Images.error} />
                                    <div className={classes.errorStyle}>{validationErrors.rating}</div>
                                </div> : null
                            }
                            <div style={{ padding: '2vw 0vw', paddingTop: '0vh' }}>
                                {/*this.props.location.appState && this.props.location.appState.type === 'dissatisfied' ? null : <TextField
                                    value={feedbackText}
                                    required={this.props.location.appState && this.props.location.appState.type === 'dissatisfied' ? true : false}
                                    onChange={(e) => this.setState({ feedbackText: e.target.value })} className={classes.feedbackText} id="standard-basic" label="Write a note" fullWidth />*/}
                                {feedbackType === 'dissatisfied' ?
                                    <div className={classes.radioContainer}>
                                        {/* <div className={classes.dOptionsText}>Please select an appropriate feedback option:</div>*/}
                                        {/*
                                            dissatisfiedOptions.map((row, index) => (
                                                <div key={'dis' + index} className={classes.feedbackOption}>
                                                    {feedbackText === row ?
                                                        <div className="colCenter" >
                                                            <div className={clsx({
                                                                [classes.radioOption]: true,
                                                                [classes.selectedRadioOption]: true,
                                                            })} onClick={(e) => this.setState({ feedbackText: row })} >
                                                                <div className={classes.radioSelected} >
                                                                    <div className={classes.selectedRadio}></div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        : <div className="colCenter"> <div onClick={(e) => this.setState({ feedbackText: row })} className={classes.radioOption}></div></div>}&nbsp;
                                                    <div onClick={(e) => this.setState({ feedbackText: row })} className={classes.radioLabel}>{row}</div>
                                                </div>
                                            ))
                                      */  }

                                    </div>
                                    : null

                                }
                                {feedbackType === 'dissatisfied' ?
                                    <TextField autoComplete='off' style={{ margin: '0vh 0vw 1vh 0vw' }} value={feedbackText} onChange={(e) => this.setState({ feedbackText: e.target.value })} className={classes.feedbackText} id="standard-basic" label="Provide feedback here" fullWidth /> : null
                                }
                                {validationErrors && validationErrors.feedbackText ?
                                    <div className={classes.validationError}>
                                        <ReactSVG src={Images.error} />
                                        <div className={classes.errorStyle}>{validationErrors.feedbackText}</div>
                                    </div> : null
                                }

                                {this.renderConsentForm()}

                                {errorMessage && errorMessage.length ?
                                    <div className={classes.error}>
                                        <ReactSVG src={Images.error} />
                                        <div className={classes.errorStyle}>{errorMessage}</div>
                                    </div> : null
                                }

                            </div>
                        </div>
                    </div>


                </div>
                <div className={classes.feedbackSubmitContainer}>
                    {/*
                        endProgress ?
                            <Button variant="contained" className={classes.button} fullWidth style={{ width: '100%' }}>
                                <CircularProgress size={30} thickness={5} className={classes.loading} color="white" />
                            </Button> :
                            <Button variant="contained" className={classes.button} type="submit" onClick={(event) => this.sendFeedback()} fullWidth style={{ width: '100%' }} >
                                Submit Response
                            </Button>
                  */  }
                    <PoweredBy />
                </div>
                {this.props.chatStore.cFeedbackDialog ? <ChatFeedbackDialog feedback={feedbackType} /> : null}
            </div>
        )
    }
}



FeedbackChat.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withRouter(withStyles(feedbackStyles)(FeedbackChat));
