import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { createMuiTheme } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';
import { configure } from 'mobx';
import { Provider } from 'mobx-react';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

//Mobx stores
import userStore from './mobx-stores/userStore';
import commonStore from './mobx-stores/commonStore';
import queryStore from './mobx-stores/queryStore';
import feedbackStore from './mobx-stores/feedbackStore';
import chatStore from './mobx-stores/chatStore';
import websocketStore from './mobx-stores/websocketStore';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#b33837'
    },
    background: '#f6f7fb',
    cloudyBlue: '#bfc5d2',
    silver: '#c2c6cd',
    paleGray2: '#f8f8fd',
    red: '#b33837',
    errorRed:'#de173e',
    orange: '#e3622b',
    coolGray: '#89969f',
    coolGray2: '#9097a2',
    black: '#000000',
    steelGray: '#747f87',
    charcoalGray: '#3e464e',
    slateGray: '#585c64',
    dimGray: '#f1f2f5',
    greyBrown: '#3b3b3b',
    paleGray: '#f1f3f5',
    white: '#ffffff',
    warmGray: '#888888',
    battleShipGray: '#dfdee0',
    blue:'#17a0f2',
    darkRed: '#a80000',
    cancelGray: '#e0e0e0',
    darkGreen: '#698e22',
    snowGray: '#f6f7fa',
    lightGray: '#a3a3a3',
    green: '#34c759',
    

  },
  typography: {
    button: {
      textTransform: 'none'
    },
    useNextVariants: true,
    fontFamily: 'Roboto',
  },
  overrides: {
    MuiInputLabel:{
      filled:{
        fontSize: '13px'
      },
      
      formControl:{
        fontSize: '13px'
      }
    },
    MuiFilledInput: { // Name of the component ⚛️ / style sheet
      root: { // Name of the rule
        "&$focused": { // increase the specificity for the pseudo class
          backgroundColor: "#fffff",
          border: 'solid 2px #e3622b',
          borderRadius: '10px !important'
        },
        "&$hobver": { // increase the specificity for the pseudo class
          backgroundColor: "#fffff",
        },
        borderRadius: '10px !important',
        backgroundColor: "#fffff",
      },
      underline: {
        "&&&:before": {
          borderBottom: "none",
          borderRadius: '10px !important'
        },
        "&&:after": {
          borderBottom: "none",
          borderRadius: '10px !important'
        }
      }
    },


  }
});

const stores = {
  userStore,
  commonStore,
  queryStore,
  feedbackStore,
  chatStore,
  websocketStore
};

configure({ enforceActions: 'observed' })

ReactDOM.render((
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <MuiThemeProvider theme={theme}>
      <Provider {...stores}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </MuiThemeProvider>
  </MuiPickersUtilsProvider>
), document.getElementById('root'));

serviceWorker.unregister();
