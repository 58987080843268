/*
 Attachment component
 url match: /dashboard
 Base component for the home page, redirects to appropriate 
 components depending on the URL/Route
*/
import { withRouter } from 'react-router-dom';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Loader from 'react-loader-spinner'
import { messageStyles } from '../../../Styles/chatStyles';
import Moment from 'moment';
import clsx from 'clsx';
import '../../../Styles/react-chat-styles.css';
import { Tooltip } from '@material-ui/core';
import Images from '../../../Theme/Images';
import { ReactSVG } from 'react-svg';
import styled, { keyframes } from 'styled-components';
import { fadeInLeft } from 'react-animations';

const bounceInAnim = keyframes`${fadeInLeft}`;

const SystemMessage = styled.div`
  animation: 1s ${bounceInAnim};
`;

@inject('chatStore')
@observer
class Attachment extends React.Component {

    downloadFile = (fileUri, fileName) => {
        let link = document.createElement('a');
        link.target = '_blank'
        link.href = fileUri;
        link.click();
    }

    renderAttachment = () => {
        const { classes, theme, item, index, row } = this.props;
        return (
            <div >
                {/*
                        item.myUrl !== undefined ?
                            <div className={classes.attLoading}>
                                <Loader height={30} width={30} type={"Oval"} color={theme.palette.red} className={classes.loading} />
                            </div>
                            : null
                    */}
                    {
                        item.type === 'IMAGE' || item.type.includes('image') ?
                            <div className={classes.imgContainer} onClick={(e) => this.downloadFile(item.url, item.fileName)}>
                                <img className={clsx({[classes.imgDisplay]: true, [classes.imgDisplayRight]: row.messageBy === 'CUSTOMER'})} src={item.url}></img>
                                <div className={clsx({ [classes.timeStamp]: true, [classes.agTimeStamp]: row.messageBy === 'CUSTOMER', [classes.attTimeStamp]: true })} >
                                            {row.messageBy === 'CUSTOMER' ? <React.Fragment> <ReactSVG src={Images.doubleTick} />&nbsp;&nbsp;</React.Fragment> : null}<div> {Moment(row.createdAt).format('h:mm a')}</div>
                                        </div>
                            </div>
                            :
                            <div className={classes.fileContainer} onClick={(e) => this.downloadFile(item.url, item.fileName)}>
                                <div className="rowFlex">
                                    <ReactSVG style={{paddingLeft: '2vw'}} src={item.type === 'ALL' ? Images.zip :  Images.pdfDoc} />
                                    <div className={classes.fileName}>
                                        <span className={classes.name}> {item.originalFileName}</span>
                                       
                                    </div>
                                </div>
                                <div className={clsx({ [classes.timeStamp]: true, [classes.agTimeStamp]: row.messageBy === 'CUSTOMER', [classes.attTimeStamp]: true })} >
                                            {row.messageBy === 'CUSTOMER' ? <React.Fragment> <ReactSVG src={Images.doubleTick} />&nbsp;&nbsp;</React.Fragment> : null}<div> {Moment(row.createdAt).format('h:mm a')}</div>
                                        </div>
                               
                            </div>


                }
                {/*
                        row.sending !== undefined && row.sending ?
                            <div className={clsx({ [classes.timeStamp]: true, [classes.agTimeStamp]: row.messageBy === 'CUSTOMER' , [classes.darkFont]: row.messageBy === 'CUSTOMER'})} >
                                <ReactSVG src={Images.chatSending} />&nbsp;&nbsp;<div>Sending</div>
                            </div>
                            :
                            <div className={clsx({ [classes.timeStamp]: true, [classes.agTimeStamp]: row.messageBy === 'CUSTOMER', [classes.darkFont]: row.messageBy === 'CUSTOMER' })} >
                                <ReactSVG src={Images.doubleTick} />&nbsp;&nbsp;<div> {format(row.createdAt)}</div>
                            </div>
                    */  }
            </div>
        )
    }

    render() {

        const { classes, theme, item, index, row } = this.props;
        return (
            row.messageBy !== 'CUSTOMER' ?
                <SystemMessage key={'item' + index} className={row.messageBy === 'CUSTOMER' ? classes.right : classes.left}>
                    {this.renderAttachment()}
                </SystemMessage>
                :
                <div key={'item' + index} className={row.messageBy === 'CUSTOMER' ? classes.right : classes.left}>
                    {this.renderAttachment()}
                </div>

        )

    }
}
export default withStyles(messageStyles, { withTheme: true })(withRouter(Attachment));
