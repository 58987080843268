/*
 SelectOptions component
 url match: /dashboard
 Base component for the home page, redirects to appropriate 
 components depending on the URL/Route
*/
import { withRouter } from 'react-router-dom';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { messageStyles } from '../../../Styles/chatStyles';
import clsx from 'clsx';
import { ReactSVG } from 'react-svg';
import Images from '../../../Theme/Images';
@inject('chatStore')
@observer
class SelectOptions extends React.Component {

    selectOption = (selected) => {
        const { index } = this.props;
        this.props.chatStore.sendMessage([], selected)
        this.props.focusInput()
        this.props.chatStore.openMessageDrawer(false, index)
        /* .then(() => {
             this.props.resetPage()
             if (!this.props.chatStore.replyProgress) {
                 this.scrollToBottom();
             }
         })*/
    }

    render() {
        const { classes, row } = this.props;

        return (
            <div className={classes.selectContainer}>
                <div className={classes.selectQuestion}>{row.webText}</div>
                <div className={classes.selectAnswerContainer}>
                    {
                        row.selectOptions.map((row, optionIndex) => (
                            <div className={classes.selectOption} onClick={(e) => this.selectOption(optionIndex + 1)} key={'options' + optionIndex}>
                                <div>{row}</div>
                                <ReactSVG src={Images.selectForwardIcon} />
                            </div>
                        ))
                    }
                </div>
            </div>
        )

    }
}
export default withStyles(messageStyles, { withTheme: true })(withRouter(SelectOptions));
