import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import { PropTypes } from 'mobx-react';
import { ReactSVG } from 'react-svg';
import Images from '../../Theme/Images';
import clsx from 'clsx'
const labels = {
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 3,
    5: 3,
};
const ratingText = {
    1: 'Very Bad',
    2: 'Bad',
    3: 'Good',
    4: 'Very Good',
    5: 'Excellent',
};

const ratings = [
    'Needs improvement', 'Good', 'Excellent'
]

const useStyles = makeStyles({

    starEmpty: {
        color: '#e3622b'
    },
    starFocus: {
        color: '#e3622b'
    },
    'starHover-1': {
        color: '#de173e'
    },
    'starHover-2': {
        color: '#de173e'
    },
    'starHover-3': {
        color: '#ffb400'
    },
    'starHover-4': {
        color: '#ffb400'
    },
    'starHover-5': {
        color: '#ffb400'
    },
    feedbackText: {
        fontSize: '13px',
        textAlign: 'center',
        // margin: '18px 0vw 10px 0vw'
    },
    ratingImage: {
        width: '35vw'
    },
    feedbackImage: {
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        width: '100%'
    },
    ratingContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        margin: '2vh 0vw 10px 0vw '
    },
    ratingButton: {
        backgroundColor: 'white',
        color: '#353535',
        borderRadius: '22px',
        fontSize: '12px',
        padding: '4px 12px',
        cursor: 'pointer',
        marginTop: '155px',
        border: 'solid 1px #e3622b',
        color: '#e3622b',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#e3622b',
            color: 'white'
        }
    },
    selectedRating: {
        backgroundColor: '#e3622b',
        color: 'white'
    }

});

export default function FeedbackRating(props) {
    const [value, setValue] = React.useState(0);
    const [hover, setHover] = React.useState(-1);
    const classes = useStyles();

    const setRating = (value) => {
        setValue(value);
        props.handleRatingChange(value)
    }

    return (
        <div className={classes.root} style={{ position: 'relative' }}>
            <div className={classes.feedbackImage} > {<div>{<ReactSVG className={classes.ratingImage} src={Images[`${labels[hover !== -1 ? hover : value]}`]} />}</div>}</div>
            <div className={classes.ratingContainer}>

                {<Rating style={{ margin: '30vw 0vw 10px 0vw ' }}
                    name="hover-feedback"
                    value={value}
                    precision={1}
                    onChange={(event, newValue) => {
                        setValue(newValue === null ? 0 : newValue);
                        props.handleRatingChange(newValue === null ? 0 : newValue)
                    }}
                    onChangeActive={(event, newHover) => {
                        setHover(newHover);
                        if (props.from === 'ack')
                            props.resetRatingError()
                    }}
                    classes={{
                        root: classes.star,
                        iconHover: classes[`starHover-${Math.ceil(hover)}`],
                        iconFilled: classes[`starHover-${Math.ceil(value)}`],
                        iconFocus: classes.starFocus,
                        iconEmpty: classes.starEmpty
                    }}
                    max={5}
                    emptyIcon={<StarBorderRoundedIcon fontSize="inherit" />}
                    icon={<StarRoundedIcon fontSize="inherit" />}
                />}
                <div className={classes.feedbackText} style={{ position: 'absolute', width: '100%', bottom: '-15%' }}> {<Box ml={2}>{ratingText[hover !== -1 ? hover : value]}</Box>}</div>
            </div>
        </div>
    );
}
