/**
 * Images should be stored in the `App/Images` directory and referenced using variables defined here.
 */

export default {
  logo: require('../Assets/svgs/logo.svg'),
  me: require('../Assets/svgs/me.svg'),
  contact_card: require('../Assets/svgs/name.svg'),
  home: require('../Assets/svgs/home.svg'),
  email: require('../Assets/svgs/email.svg'),
  mailbox: require('../Assets/svgs/post.svg'),
  vehicle: require('../Assets/svgs/vehicle.svg'),
  processed: require('../Assets/svgs/ticket-processed.svg'),
  // processed: require('../Assets/svgs/vehicle.svg'),
  // needDoc: require('../Assets/svgs/vehicle.svg'),
  //download: require('../Assets/svgs/vehicle.svg'),
  //pending: require('../Assets/svgs/vehicle.svg'),

  needDoc: require('../Assets/svgs/need-doc-2.svg'),
  download: require('../Assets/svgs/download-2.svg'),
  satisfied: require('../Assets/svgs/like.svg'),
  dissatisfied: require('../Assets/svgs/unhappy.svg'),
  camera: require('../Assets/svgs/camera.svg'),
  account: require('../Assets/svgs/user-account-box.svg'),
  reply: require('../Assets/svgs/reply.svg'),
  note: require('../Assets/svgs/note.svg'),
  send: require('../Assets/svgs/send.svg'),
  close: require('../Assets/svgs/close.svg'),
  closeHeader: require('../Assets/svgs/close-header.svg'),
  attachment: require('../Assets/svgs/attachment.svg'),
  error: require('../Assets/svgs/error.svg'),
  ticket: require('../Assets/svgs/ticket.svg'),
  time: require('../Assets/svgs/time.svg'),
  date: require('../Assets/svgs/date.svg'),
  customer: require('../Assets/svgs/customer.svg'),
  agent: require('../Assets/svgs/agent.svg'),
  bot: require('../Assets/svgs/bot.svg'),
  pending: require('../Assets/svgs/pending.svg'),
  snooze: require('../Assets/svgs/snooze.svg'),
  sendQuery: require('../Assets/svgs/sendQuery.svg'),
  miniAttachment: require('../Assets/svgs/miniAttachment.svg'),
  like: require('../Assets/svgs/satisfied.svg'),
  dislike: require('../Assets/svgs/dissatisfied.svg'),
  pdfDoc: require('../Assets/svgs/pdfDoc.svg'),
  zip: require('../Assets/svgs/zip.svg'),
  openTickets: require('../Assets/svgs/openTickets.svg'),
  closedTickets: require('../Assets/svgs/closedTickets.svg'),
  chats: require('../Assets/svgs/chats.svg'),
  chatIcon: require('../Assets/svgs/chatIcon.svg'),
  newProducts: require('../Assets/svgs/newProducts.svg'),
  logout: require('../Assets/svgs/logout.svg'),
  notification: require('../Assets/svgs/notification.svg'),
  loginLogo: require('../Assets/svgs/loginLogo.svg'),
  chatSending: require('../Assets/svgs/chatSending.svg'),
  doubleTick: require('../Assets/svgs/correct.svg'),
  chatBg: require('../Assets/Images/chatBg.png'),
  thankyou: require('../Assets/Images/thankyou.png'),
  back: require('../Assets/svgs/back.svg'),
  login: require('../Assets/svgs/login.svg'),
  chatLike: require('../Assets/svgs/chatLike.svg'),
  card_icon: require('../Assets/svgs/pan.svg'),
  faq_icon: require('../Assets/svgs/faq.svg'),
  chatDislike: require('../Assets/svgs/chatDislike.svg'),
  forwardIcon: require('../Assets/svgs/forwardIcon.svg'),
  whiteForwardIcon: require('../Assets/svgs/whiteForwardIcon.svg'),
  sat: require('../Assets/svgs/sat.svg'),
  dissat: require('../Assets/svgs/dissat.svg'),
  dissatOrange: require('../Assets/svgs/dissatOrange.svg'),
  satisfied: require('../Assets/svgs/feedbackS.svg'),
  dissatisfied: require('../Assets/svgs/feedbackD.svg'),
  poweredBy: require('../Assets/svgs/poweredByLogo.svg'),
  sugClose: require('../Assets/svgs/sugClose.svg'),
  nsdlLogo: require('../Assets/Images/NSDL-LOGO.png'),
  banner: require('../Assets/Images/banner.png'),
  instagram: require('../Assets/svgs/INSTA.svg'),
  fb: require('../Assets/svgs/FB.svg'),
  twitter: require('../Assets/svgs/TW.svg'),
  0: require('../Assets/svgs/Rating0.svg'),
  1: require('../Assets/svgs/Rating1.svg'),
  2: require('../Assets/svgs/Rating1.svg'),
  3: require('../Assets/svgs/Rating3.svg'),
  4: require('../Assets/svgs/Rating3.svg'),
  5: require('../Assets/svgs/Rating3.svg'),
}