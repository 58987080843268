import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { queryListStyles } from '../../Styles/queryStyles';
import { inject, observer } from 'mobx-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import Images from '../../Theme/Images';
import { ReactSVG } from 'react-svg';
import ClaimStatus from './QueryCardHelpers/ClaimStatus';
import { ExpansionPanelActions } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import UploadDocuments from './QueryCardHelpers/Upload/UploadDocuments';
import AlternateQuery from './QueryCardHelpers/AlternateQuery';
import Moment from 'moment';
import TicketChat from './QueryCardHelpers/TicketChat';
@inject('queryStore', 'commonStore')
@observer
class QueryCard extends Component {
    state = {
        showAlternateQueries: false
    }

    componentDidMount() {
        this.props.queryStore.reupload()
    }

    onClickDownload = (e) => {
        e.stopPropagation()
        e.preventDefault()
        const { item } = this.props;
        const link = document.createElement('a');
        link.href = item.allStepsData.step_2.apiURL.replace('http:', 'https:');
        document.body.appendChild(link);
        link.click();

    }

    renderQueryStatus = (type, tempId, currentQuery) => {
        const { classes, item } = this.props;
        if (type === 'download')
            return (
                <ReactSVG
                    beforeInjection={svg => {
                        svg.classList.add(classes.icons)
                    }}
                    src={Images[`${type}`]} onClick={(event) => this.onClickDownload(event)} />
            )
        return (
            <ReactSVG
                beforeInjection={svg => {
                    svg.classList.add(classes.icons)
                }}
                src={Images[`${type}`]} />
        )
    }

    renderQuerySummary = () => {
        const { classes, item, type } = this.props
        return (
            <div>
                <div className={classes.intentHeading}>{item.allStepsData.textToShow}</div>
                <div className={classes.policyNoText}>Policy Number: {item.stepsData.step_1.stepData_policyId}</div>
                <div className={classes.dateContainer}>
                    <span className={classes.detail}><ReactSVG className={classes.detailIcon} src={Images.ticket} />&nbsp;{item.tempId}</span>
                    <span className={classes.time}><ReactSVG className={classes.detailIcon} src={Images.time} />{Moment(item.createdAt).format('DD/MM/YYYY, h:mm a')}</span>
                </div>
                <div className={classes.detailContainer}>
                    {
                        (!item.allStepsData.noInputStep ?
                            (item.allStepsData.step_2.inputType == 'DISPLAY_DOWNLOAD_BUTTON' || item.allStepsData.step_2.inputType == 'FORM' ?
                                this.renderQueryStatus('download', item.tempId, item.allStepsData.constant) :
                                (item.allStepsData.step_2.inputType == 'DISPLAY_TEXT' || item.permanentId ? null
                                    : this.props.commonStore.activeHeader === 'Completed Queries' ? null :
                                        this.renderQueryStatus('needDoc', item.tempId, item.allStepsData.constant)
                                )
                            ) :
                            (item.allStepsData.noInputStep && item.allStepsData.step_1.clickableBoxWithData ?
                                (item.allStepsData.step_1.clickableBoxWithData.arrayToShow.filter((obj) => { return obj.value == 'PROCESSED' }).length > 0 ?
                                    this.renderQueryStatus('processed', item.tempId, item.allStepsData.constant)
                                    : this.renderQueryStatus('pending', item.tempId, item.allStepsData.constant)
                                )
                                : null
                            )
                        )
                    }
                    {type === 'pending' ? <div className={classes.notYourQuery} ><span className={classes.vertCenter} onClick={(e) => this.notYourQuery(item)}>Not your query?</span></div> : null}
                </div>
            </div>
        )
    }

    renderClaimStatus = () => {
        const { item } = this.props;
        return (
            <React.Fragment>
                <ClaimStatus item={item}></ClaimStatus>
                {this.renderSatisfactionButtons()}
            </React.Fragment>
        )
    }

    renderQueryDetails = () => {
        const { item, classes } = this.props;
        if (true) {
            return (
                (!item.allStepsData.noInputStep ?
                    (item.allStepsData.step_2.inputType == 'DISPLAY_DOWNLOAD_BUTTON' ?
                        null :
                        (item.allStepsData.step_2 && item.allStepsData.step_2.inputType == 'DISPLAY_TEXT') ? <div className={classes.uploadText}>{item.allStepsData.step_2.textToShow}</div> :
                            !item.dissatisfied && !item.customerInputDataExists ? <UploadDocuments renderSatisfactionButtons={this.renderSatisfactionButtons} item={item}></UploadDocuments> : this.renderSatisfactionButtons()) :
                    (item.allStepsData.noInputStep ?
                        this.renderClaimStatus() : null))
            );
        } else {
            return (
                (item.allStepsData.step_2 && item.allStepsData.expandByDefault && item.allStepsData.step_2.inputType == 'DISPLAY_TEXT' ?
                    <div className={classes.uploadText}>{item.allStepsData.step_2.textToShow}</div> : null)
            );
        }
    }

    onClickSatisfactionButton = (type) => {
        const { version } = this.props.commonStore
        this.props.queryStore.setShowFeedback(true);
        this.props.history.push({
            pathname: `/${version}/feedback`,
            appState: { item: this.props.item, type: type }
        })
    }

    renderSatisfactionButtons = () => {
        const { classes, type, item } = this.props;
        return (
            type == 'pending' ?
                <TicketChat item={item} />
                : null
        )
    }

    renderFooter = () => {
        const { item } = this.props;
        return (
            <div style={{ width: '100%' }}>
                {
                    (!item.allStepsData.noInputStep ?
                        (item.allStepsData.step_2 && (item.allStepsData.step_2.inputType == 'DISPLAY_DOWNLOAD_BUTTON' || item.allStepsData.step_2.inputType == 'DISPLAY_TEXT') ?
                            this.renderSatisfactionButtons() :
                            null) :
                        (item.allStepsData.step_2 && item.allStepsData.noInputStep && item.allStepsData.step_2.inputType != 'FORM' ?
                            this.renderSatisfactionButtons() :
                            (
                                (this.props.intent != 'completed' ?
                                    <div></div> : null
                                )
                            )
                        )
                    )
                }
            </div>
        )

    }

    notYourQuery = (item) => {
        const { version } = this.props.commonStore
        this.props.commonStore.setGoBackTo('pendingQueries')
        this.props.history.push(`/${version}/queries/pending/${item.tempId}`)
    }

    expandQuery = (index, value, type) => {
        const { item } = this.props;
        if (type === 'pending')
            this.props.queryStore.expandQuery(index, value)
        if (value)
            this.props.queryStore.getQueryLog(item.tempId)

    }

    render() {
        const { classes, item, index, type } = this.props;
        const { inProgress } = this.props.queryStore;
        const { header } = this.props.queryStore;
        const { showAlternateQueries } = this.state;
        return (
            <div className={classes.cardContainer}>
                <ExpansionPanel expanded={item.expanded} onChange={(e, v) => this.expandQuery(index, v, type)}>
                    <ExpansionPanelSummary
                        expandIcon={type === 'pending' ? <ExpandMoreIcon /> : null}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        classes={{
                            content: classes.queryHeading,
                            expandIcon: classes.expandIcon,
                            expanded: classes.expanded
                        }}
                        className={classes.expansionPanel}
                    >
                        {this.renderQuerySummary()}

                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                        classes={{
                            root: classes.actionsRoot
                        }}
                    >
                        <div className="fullWidth">
                            {!showAlternateQueries ? this.renderQueryDetails() : <AlternateQuery />}
                        </div>
                    </ExpansionPanelDetails>
                    <ExpansionPanelActions
                        classes={{
                            root: classes.actionsRoot
                        }}
                        disableSpacing={true}>
                        <div className="fullWidth">{this.renderFooter()}</div>
                    </ExpansionPanelActions>
                </ExpansionPanel></div>
        )
    }
}
QueryCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter((withStyles(queryListStyles)(QueryCard)));
