/*
 ChatAttachmentPreview component
 url match: /dashboard
 Base component for the home page, redirects to appropriate 
 components depending on the URL/Route
*/
import { withRouter } from 'react-router-dom';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { ReactSVG } from 'react-svg';
import Images from '../../../Theme/Images';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { commonStyles } from '../../../Styles/commonStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

@inject('commonStore', 'chatStore')
@observer
class ChatAttachmentPreview extends React.Component {

    onClickUpload = () => {
        this.props.upload()
        this.focusInput()
    }

    onCancel = () => {
        this.props.cancelUpload()
        this.focusInput()
    }

    focusInput = () => {
        let elem = document.getElementById("textInput")
        if (elem) {
            elem.focus()
        }
    }


    render() {
        const { classes, files } = this.props;
        const { openAttachmentPreview } = this.props.chatStore;
        return (
            <Dialog classes={{ paper: classes.dialog }} open={openAttachmentPreview} >
                <DialogContent className={classes.attPreviewDialog}>
                    <div className={classes.attPreviewContainer}>
                        {files.map((row, index) => (
                            <div key={'chat-att-' + index} className={classes.fileWrap}>
                                {row && acceptedImageTypes.includes(row['type']) ?
                                <div className={classes.imgContainer} style={{ backgroundImage: `url(${row.myUrl})` }}>
                                    <ReactSVG className={classes.closeIcon} src={Images.close} onClick={(e) => this.props.clearFiles(index)} />
                                </div> :
                                <Tooltip title={row.name}>
                                    <div className={classes.fileContainer}>
                                        <ReactSVG className={classes.closeIcon} src={Images.close} onClick={(e) => this.props.clearFiles(index)} />
                                        <ReactSVG style={{ textAlign: 'center' }} src={row['type'] === 'application/zip' ? Images.zip : Images.pdfDoc} />
                                    </div>
                                </Tooltip>}
                            </div>
                        ))
                        }
                    </div>
                    <div className={classes.buttonContainer}>
                        <Button variant="contained" className={classes.cancelButton} type="submit" onClick={this.onCancel}>
                            Cancel
                        </Button>
                        <Button variant="contained" className={classes.button} type="submit" onClick={this.onClickUpload}>
                            Upload
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

        )
    }
}
export default withStyles(commonStyles, { withTheme: true })(withRouter(ChatAttachmentPreview));
