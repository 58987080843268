/*
 Message component
 url match: /dashboard
 Base component for the home page, redirects to appropriate 
 components depending on the URL/Route
*/
import { withRouter } from 'react-router-dom';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { messageStyles } from '../../../Styles/chatStyles';
import clsx from 'clsx';
import { ReactSVG } from 'react-svg';
import Images from '../../../Theme/Images';
import FileUpload from './FileUpload';
import ChatFeedback from './ChatFeedback';
import BooleanInput from './BooleanInput';
import SelectOptions from './SelectOptions';
import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';
import Loader from 'react-loader-spinner'
import dompurify from 'dompurify'
import Moment from 'moment'
const bounceInAnim = keyframes`${fadeIn}`;

const SystemMessage = styled.div`
  animation: 1s ${bounceInAnim};
`;

const CustomerMessage = styled.div`
  animation: 1s ${bounceInAnim};
`;
@inject('chatStore')
@observer
class Message extends React.Component {

    renderBoolean = (row) => {
        const { classes, index } = this.props;
        return (
            <BooleanInput row={row} index={index} focusInput={this.focusInput} />
        )
    }

    renderFeedback = (row) => {
        const { classes, index } = this.props;
        return (
            <ChatFeedback row={row} index={index} focusInput={this.focusInput} />
        )
    }

    renderFileUpload = (row) => {
        const { classes, index } = this.props;
        return (
            <FileUpload row={row} index={index} focusInput={this.focusInput} />
        )
    }

    renderSelectOptions = (row) => {
        const { classes, index } = this.props;
        return (
            <SelectOptions row={row} index={index} focusInput={this.focusInput} />

        )
    }

    focusInput = () => {
        let elem = document.getElementById("textInput")
        if (elem) {
            elem.focus()
        }
    }

    renderIcon = (from, index) => {
        const { classes } = this.props;
        const { chatData } = this.props.chatStore;
        if (index + 1 < chatData.messages.length) {
            if (chatData.messages[index + 1].messageBy === from)
                return (<ReactSVG className={classes.hidden} src={Images[`${from.toLowerCase()}`]} />)
            else
                return (<ReactSVG src={Images[`${from.toLowerCase()}`]} />)
        }
        else
            return (<ReactSVG src={Images[`${from.toLowerCase()}`]} />)
    }

    openSocialMedia = (url) => {
        let link = document.createElement('a');
        link.target = '_blank'
        link.href = url;
        link.click();
    }

    renderWelcomeMessage = (row) => {
        const { classes } = this.props;
        return (
            <div className={classes.textMessage} >
                <div>
                    <div className={classes.welcomeTitle}>Hey there!</div>
                    <br />
                    <div className={classes.welcomeMessage}>

                        <div className='rowFlex'>
                            <div className={classes.welcome}>
                                Welcome to Protean eGov Technologies Limited PAN Assistance,
                                <div>I am here to assist you with any queries related to Permanent Account Number (PAN)/Tax Deduction Account Number (TAN) and Tax Information Network (TIN).</div>
                                <br /><br />
                                <br />
                                <div className='rowFlex'>
                                    <ReactSVG className={classes.socialMedia} src={Images.fb} onClick={() => this.openSocialMedia('https://www.facebook.com/NSDLeGovernance')} />
                                    <ReactSVG className={classes.socialMedia} src={Images.twitter} onClick={() => this.openSocialMedia('https://twitter.com/NSDLeGovernance')} />
                                    <ReactSVG className={classes.socialMedia} src={Images.instagram} onClick={() => this.openSocialMedia('https://www.instagram.com/nsdlegovernance/')} />

                                </div>
                            </div>
                            <img style={{ width: '33%', marginRight: '-16px' }} alt='Mascot' src={'https://assistedclosure.tin-nsdl.com/mascot.png'} />
                        </div>
                    </div>
                </div>
                <div className={clsx({ [classes.timeStamp]: true, [classes.agTimeStamp]: row.messageBy === 'CUSTOMER' })} >
                    {row.messageBy === 'CUSTOMER' ? <React.Fragment> <ReactSVG src={Images.doubleTick} />&nbsp;&nbsp;</React.Fragment> : null}<div> {Moment(row.createdAt).format('h:mm a')}</div>
                </div>
            </div>
        )
    }

    getLinkedText = (matches, text) => {
        const { row } = this.props
        let temp = text
        var matchSet = new Set(matches);
        var matchArray = Array.from(matchSet);

        row.messageBy === 'SYSTEM' && matchArray && matchArray.length && matchArray.map((text) => {
            // if (row.messageText.includes('Who will provide AO details') || row.messageText == 'Thank you for writing to us.\nFor any further clarification, you may visit our website http://www.tin-nsdl.com or write us at TINInfo@nsdl.co.in')
            if (!row.showClickHere)
                temp = temp.replaceAll(text, `<a id='acClickHereLink' className='anchorLink' target='_blank' href=${text}>${text}</a>`)
            else
                temp = temp.replaceAll(text, `<a id='acClickHereLink' className='anchorLink' target='_blank'  href=${text}>Click here</a>`)
        })
        return temp
    }

    getMessageText = (text) => {
        const sanitizer = dompurify.sanitize;
        var expression = /(https?:\/\/(?:www1?\.|(?!www1?))[^\s\.]+\.[^\s]{2,})/gi;
        var matches = text.match(expression);
        return <span dangerouslySetInnerHTML={{ __html: sanitizer(this.getLinkedText(matches, text), { ADD_ATTR: ['target'] }) }}></span>
    }

    renderMessage = () => {
        const { classes, row, index } = this.props
        return (

            row.messageType === 'SELECT_OPTIONS' ?
                this.renderSelectOptions(row) :
                row.messageType === 'FILE_UPLOAD' ? this.renderFileUpload(row) :
                    row.messageType === 'FEEDBACK' ? this.renderFeedback(row) :
                        row.messageType === 'BOOLEAN' ? this.renderBoolean(row) :
                            (row.messageText === 'Welcome to NSDL e-Gov PAN assistance, How may I help you?' && row.messageBy === 'SYSTEM') ? this.renderWelcomeMessage(row) :
                                <div className={clsx({ [classes.textMessage]: true, [classes.agent]: row.messageBy === 'CUSTOMER' })} >
                                    <span style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{row.messageBy === 'CUSTOMER' ? row.messageText : this.getMessageText(row.messageText)}
                                    </span>

                                    {
                                        row.sending !== undefined && row.sending ?
                                            <div className={clsx({ [classes.timeStamp]: true, [classes.agTimeStamp]: row.messageBy === 'CUSTOMER' })} >
                                                <ReactSVG src={Images.chatSending} />&nbsp;&nbsp;<div>Sending</div>
                                            </div>
                                            :
                                            <div className={clsx({ [classes.timeStamp]: true, [classes.agTimeStamp]: row.messageBy === 'CUSTOMER' })} >
                                                {row.messageBy === 'CUSTOMER' ? <React.Fragment> <ReactSVG src={Images.doubleTick} />&nbsp;&nbsp;</React.Fragment> : null}<div> {Moment(row.createdAt).format('h:mm a')}</div>
                                            </div>
                                    }
                                </div>

        )
    }

    render() {
        const { classes, row, index, theme } = this.props;
        const { chatData, typing } = this.props.chatStore
        return (
            row.messageBy !== 'CUSTOMER' ?
                <SystemMessage className={row.messageBy !== 'CUSTOMER' ? classes.left : classes.right} >
                    {/*row.messageBy !== 'CUSTOMER' ? this.renderIcon(row.messageBy, index) : null*/}
                    {this.renderMessage()}
                    {/*row.messageBy === 'CUSTOMER' ? this.renderIcon(row.messageBy, index) : null*/}
                </SystemMessage>
                :
                <div className={row.messageBy !== 'CUSTOMER' ? classes.left : classes.right} >
                    {/*row.messageBy !== 'CUSTOMER' ? this.renderIcon(row.messageBy, index) : null*/}
                    {this.renderMessage()}
                    {/*row.messageBy === 'CUSTOMER' ? this.renderIcon(row.messageBy, index) : null*/}
                </div>
        )

    }
}
export default withStyles(messageStyles, { withTheme: true })(withRouter(Message));
