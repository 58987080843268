import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { queryListStyles } from '../../../Styles/queryStyles';
import { inject, observer } from 'mobx-react';

@inject('queryStore')
@observer
class ClaimStatus extends Component {

    renderContent = () => {
        const { classes, item } = this.props;
        if (item.allStepsData.step_1 && item.allStepsData.step_1.clickableBoxWithData && item.allStepsData.step_1.clickableBoxWithData.arrayToShow) {
            return (
                <div style={{paddingLeft: '3vw', paddingBottom: '1vh'}}>
                    {item.allStepsData.step_1.clickableBoxWithData.arrayToShow.map((item, index) => {
                        if (item.hiddenLabel) {
                            return (
                                <div key={index} className={classes.claimText}>{item.hiddenLabel} : {item.value}</div>
                            )
                        }
                    })
                    }
                </div>
            );
        }
    }


    render() {
        return (
            <div>{this.renderContent()}</div>
        )
    }
}
ClaimStatus.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(queryListStyles)(ClaimStatus);
