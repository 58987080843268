/*
 CurrentChat component
 url match: /dashboard
 Base component for the home page, redirects to appropriate 
 components depending on the URL/Route
*/
import { withRouter } from 'react-router-dom';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Loader from 'react-loader-spinner'
import { messageStyles } from '../../../Styles/chatStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import clsx from 'clsx';
import MessageList from './MessageList';

let page = 1;
@inject('chatStore', 'userStore', 'commonStore')
@observer
class CurrentChat extends React.Component {

    componentDidMount() {
        this.props.commonStore.setChatRefresh(true)
        var elem = document.getElementById('myscrollingChat');
        if (elem != null)
            elem.scrollTop = elem.scrollHeight - elem.offsetHeight;
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={clsx({ ["colFlex"]: true, [classes.fullHeight]: true })} >
                <div className={clsx({ [classes.detailsContainer]: true, [classes.fullHeight]: true })}>
                    <Card className={clsx({
                        [classes.ticketDetail]: true,
                        [classes.fullHeight]: true
                    })}>
                        <CardContent
                            className={clsx({
                                [classes.cardContent]: true,
                            })}>
                            <MessageList />
                        </CardContent>
                    </Card>
                </div>
            </div>
        )

    }
}
export default withStyles(messageStyles, { withTheme: true })(withRouter(CurrentChat));
