import { observable, action } from 'mobx';
//import ReconnectingWebSocket from 'reconnecting-websocket';
import io from 'socket.io-client';
import constants from '../constants';
import commonStore from './commonStore';
import chatStore from './chatStore';
import userStore from './userStore';
import queryStore from './queryStore';
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

let WS_ROOT = constants.WS_ROOT;

// if (window.location.href.indexOf('10.78.0.27') > -1 || window.location.href.indexOf('localhost:8999') > -1){
//     //access via local ip
//     WS_ROOT = constants.WS_ROOT;
// }
//Process as follows:
/*
* 1) Connect to Socket
* 2) Authorize with token
* 3) On response of initiateChat API, store the session id in store
* 4) When customer types the msg -> customer typing event //TODO LATER
* 5) Customer stops typing msg -> trigger event //TODO later
* 6) Customer sends msg -> hit customer chatMsgFromClient event with data & session id
* */


export class WebsocketStore {
    constructor() {
        this.socketHandle = null;
        this.maxReconnects = 10;
        this.pingInterval = 7000;
        this.pongInterval = 5000;
        this.pongReceived = false;
    }

    @observable connected = false;
    @observable disconnected = false;
    @observable reconnecting = false;
    @observable reconnectFailed = false;
    @observable reconnected = false;


    startPingPong = () => {
        this.clearPingPong();
        this.pingLoopHandle = window.setInterval(() => {
            if (this.connected) {
                this.pongReceived = false;
                this.socketHandle.emit("ping");
                window.setTimeout(() => {
                    if (!this.pongReceived) {
                       //Pong not received within 5 seconds. WS disconnected
                        //this.socketHandle.close();
                    }
                }, this.pongInterval);
            }
        }, this.pingInterval)
    }

    clearPingPong = () => {
        if (this.pingLoopHandle) {
            window.clearInterval(this.pingLoopHandle);
        }
    }

    @action register() {
        if (this.socketHandle)
            this.registerWithToken(userStore.token);
        else
            this.connect()
    }

    @action connect(token) {
        this.reconnectCount = 0;
        this.firstConnect = false;
        if (this.socketHandle) {
            return;
        }
        const socketUrl = `${WS_ROOT}`;
        //trying to connect to server

        this.socketHandle = io(socketUrl, {
            reconnection: true,
            transports: ['websocket'],
            reconnectionDelay: 1000,
            reconnectionAttempts: 10
        });
        this.socketHandle.on('connect', action(() => {
            //Connection Completed>>>
            if (userStore.token && userStore.token.length) {
                this.registerWithToken();
            }
            this.connected = true;
           //"Websocket connected
            if (commonStore.wsFailed.value !== '')
                commonStore.showOverlay({ value: 'Connected', type: 'success' });
            setTimeout(() => {
                commonStore.showOverlay({ value: '', type: '' })
            }, 1000);
            if (commonStore.noNet) {
                commonStore.resetNoInternet();
            }
            this.startPingPong();
            //if(this.firstConnect) {
            //Websocket reconnected
            this.reconnecting = false;
            this.wsReconnected = true;
            this.reconnectCount = 0;
            // commonStore.hideOverlay();
            //}
            this.firstConnect = true;
        }));

        this.socketHandle.on('disconnect', action(() => {
            //Websocket disconnected
            this.connected = false;
            this.disconnected = true;
            if (this.reconnectCount < this.maxReconnects) {
                this.reconnectCount++;
                //Websocket reconnecting
                this.reconnecting = true;
                commonStore.showOverlay({ value: 'Reconnecting...', type: 'error' });
            } else {
                //Websocket reconnecting failed with tries = this.reconnectCount
                this.reconnecting = false;
                this.reconnectFailed = true;
                commonStore.showOverlay({ value: 'Connection failed', type: 'error' });
            }
        }));
        this.socketHandle.on('incomingChatMsgForClient', action((message) => {
            //message received on socket
            // if(message.data === "pong") {
            //     this.pongReceived = true;
            //     return;
            // }
            const data = JSON.parse(message.data);
           //incoming message 
            chatStore.getAllMessages(0)
        }));
        this.socketHandle.on('refreshChat', action((message) => {
            //RefreshChat Event Received
            if (commonStore.allowChatRefresh)
                chatStore.refreshChat(true)
        }));
        this.socketHandle.on('ticketMsgFromAgent', action((message) => {
           //TicketMsgFromAgent Event Received
            queryStore.getQueryLog(message.tempId)
            commonStore.handleOpenSnackBar('Agent has commented on your ticket with ID' + message.tempId, 'info')
        }));
        this.socketHandle.on('typingEvent', action((message) => {
            //typing event received
            chatStore.agentTyping(true);
        }));
        this.socketHandle.addEventListener('messageFromServer', action((message) => {
           //message received on socket


            if (message.data === "pong") {
                this.pongReceived = true;
                return;
            }
            else if (message.sessionId) {
                chatStore.getAllMessages(message.sessionId, 0)
            }
            // if(data.type) {
            //     switch(data.type) {
            //         case NotificationTypes.ConversationLocked:
            //         case NotificationTypes.ConversationUnlocked:
            //         case NotificationTypes.ConversationUpdated:
            //             mailboxStore.updateConversationLive(data.conversation);
            //             break;
            //         case NotificationTypes.ConversationAdded:
            //             mailboxStore.addConversation(data.conversation);
            //             break;
            //         default:
            //             break;
            //     }
            // }
        }));
        this.socketHandle.addEventListener('error', (error) => {
            //WS Error ---
            if (navigator.onLine === false) {
                commonStore.setNoInternet();
            } else {
                commonStore.resetNoInternet();
            }
        });
    }

    registerWithToken() {
        if (this.socketHandle && userStore.token && userStore.token.length) {
            this.socketHandle.emit('clientAuth', { token: userStore.token, sessionId: chatStore.chatData.sessionId }, (data) => {
                 //callback data returned from server auth token callback
            });
        } else {
            const error = new Error("Web socket not connected");
        }
    }

    registerAnonymous() {
        //registering anonymous
        //registering anonymous sessionId
        //you might already have anonymouse id in localstorage
        if (this.socketHandle && userStore.anonymousId && userStore.anonymousId.length) {
            this.socketHandle.emit('clientAuth', { anonymousId: userStore.anonymousId, sessionId: chatStore.chatData.sessionId }, (data) => {
                //store anonymousid now in localstorage
                if (data === 'INVALID_ANONYMOUS_ID') {
                    userStore.resetAnonymousId()
                    this.registerAnonymous()
                    chatStore.initiateChat()
                } else {
                    //all ok
                   //existing anonymousid registered with socket
                }

            });
        } else {
            this.socketHandle.emit('clientAuth', { anonymousUserAccessRequested: true, sessionId: chatStore.chatData.sessionId }, (data) => {
                //store anonymousid now in localstorage
                //callback data returned from server
                if (data.anonymousId) {
                    window.local_Storage.setItem('anonymousId', data.anonymousId);
                } else {
                    //Server Error in registerAnonymous
                }
            });
        }
    }

    sendMessage(message) {
        if (this.socketHandle) {
            this.socketHandle.emit('chatMsgFromClient', message, (data) => {
                //callback data returned from server sendMsgcallback
            });

        } else {
            const error = new Error("Web socket not connected");
            throw error;
        }
    }
}
export default new WebsocketStore();
