import Images from "../Theme/Images"

export const sugStyles = theme => ({
    sugListContainer: {
        width: 'calc(100% - 60px)',
        margin: '0vh 30px',
        justifyContent: 'center',
        backgroundColor: theme.palette.white,
        maxHeight: '60vh',
        position: 'absolute',
        bottom: '47px',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        boxShadow: '0 0 19px 0 rgba(0, 0, 0, 0.17)',
        border: 'solid 1px rgba(0, 0, 0, 0.05)',
        padding: '2vh 0px 1vh 0px',
        //  minHeight: '30vh',
        color: '#939393',
        fontSize: '13px',
        overflowY: 'scroll'
    },

    closeIcon: {
        cursor: 'pointer',
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end'
    }
})

export const chatStyles = theme => ({
    customer: {
        display: 'flex',
        justifyContent: 'flex-start'
    },
    agent: {
        display: 'flex',

        justifyContent: 'flex-end',
    },
    statsContainer: {
        marginTop: '2vh',
    },

    loaderDiv: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    card: {
        width: '18vw',
        height: '11vh',
        marginRight: '1vw',
        cursor: 'pointer',
    },
    satisfied: {
        width: '100% !important',
    },
    open: {
        backgroundColor: theme.palette.blue
    },
    satisfied: {
        backgroundColor: theme.palette.turquoise
    },
    dissatisfied: {
        backgroundColor: theme.palette.red
    },
    statusIcon: {
        marginLeft: '2vw'
    },
    ticketsContainer: {
        paddingTop: '1vw',
        display: 'flex',

    },
    button: {
        backgroundColor: theme.palette.orange,
        color: theme.palette.white,
        width: '7vw',
        borderRadius: '10px',
        marginRight: '1vw'
    },
    detailAction: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    ticket: {
        width: '56vw',
        height: '12vh',
        marginBottom: '1vh',
        marginRight: '1vw',
        cursor: 'pointer',
        backgroundColor: theme.palette.white
        // background: 'linear-gradient(135deg, #f7ce3e 0%, #f7ce3e 20%, #ffffff 51%, #ffffff 100%)'
    },

    comment: {
        backgroundColor: theme.palette.lightBlue,
        fontSize: '13px',
        color: theme.palette.dark,
        marginTop: '1vh',
        padding: '10px',
        borderRadius: '25px'
    },
    time: {
        fontSize: '10px',
        color: theme.palette.primaryGray,
        padding: '0px 10px',
        display: 'flex',
        justifyContent: 'flex-end'
    },

    doYouMean: {
        backgroundColor: theme.palette.lightBlue,
        fontSize: '13px',
        fontWeight: 'bold',
        color: theme.palette.dark,
        marginTop: '1vh',
        padding: '10px',
        borderRadius: '25px'
    },
    ticketDetail: {
        width: '100%',
        height: '83vh',
        marginBottom: '1vh',
        marginRight: '1vw',
        cursor: 'pointer',
        backgroundSize: 'cover'
    },
    shrinkTicket: {
        width: '18vw',
        height: '12vh',
        marginBottom: '1vh',
        cursor: 'pointer',
        backgroundColor: theme.palette.white
        // background: 'linear-gradient(135deg, #f7ce3e 0%, #f7ce3e 20%, #ffffff 51%, #ffffff 100%)'
    },
    activeTicket: {
        backgroundColor: theme.palette.paleGray,
        color: theme.palette.blue
    },
    ticketHeading: {
        fontSize: '15px',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'space-between'


    },
    detsContainer: {
        width: '100%',
        height: '34vh',
    },
    customer: {
        fontSize: '13px',
        color: '#424357',
        display: 'flex',
    },
    details: {
        fontSize: '13px',
        color: '#424357',
        display: 'flex',
        width: '10vw',
    },
    category: {
        fontSize: '13px',
        color: '#424357',
        fontWeight: 'bold'

    },
    descContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    ticketCircle: {
        background: theme.palette.mustard,
        color: theme.palette.white,
        padding: '10px 25px',
        fontSize: '35px',
        marginRight: '1vw'
    },
    ticketIcons: {
        marginRight: '1vw'
    },
    ticDesc: {
        fontSize: '13px',
        color: theme.palette.dark,
        marginTop: '1vh',
        backgroundColor: '#f0f0f0',
        padding: '10px',
        borderRadius: '25px'
    },
    test: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    }
    ,
    radioContainer: {
        flexDirection: 'row',
        color: theme.palette.dark,
    },
    radio: {
        color: theme.palette.dark,
        fontSize: '13px'
    },
    activeRadio: {
        color: theme.palette.blue,
        fontSize: '20px'
    },
    messageContainer: {
        display: 'flex',
        padding: '1vh',
        flexDirection: 'column',
        border: `solid 1px lightGray`,
        borderRadius: '8px',

    },
    message: {
        display: 'flex',

    },
    messageInput: {
        fontSize: '13px',
        padding: '5px !important',
        border: 'none',
        marginTop: '-1vh',
        backgroundColor: theme.palette.white,
        width: '31vw'
    },
    messageAction: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    textField: {
        padding: '14px',
        backgroundColor: theme.palette.white,
        width: '100%',
        fontSize: '13px',
        color: theme.palette.black,
        borderRadius: '8px'
    },
    cssFocused: {
        color: theme.palette.black
    },
    cssUnderline: {
        '&:before': {
            borderBottom: `0px solid ${theme.palette.white} !important`,
        },
        '&:after': {
            borderBottom: `0px solid ${theme.palette.white}`,
        }
    },
    expand: {
        position: 'absolute',
        top: '19%',
        left: '95%'
    },
    formControl: {
        border: `solid 1px ${theme.palette.primaryGray}`,
        width: '100%',
        marginBottom: '1vh',
        borderRadius: '8px'
    },
    dialog: {
        width: '45vw',
        height: '58vh'
    },
    icon: {
        cursor: 'pointer',
        marginRight: '1vw'
    },
    cardContent: {
        display: 'flex',
        height: '58vh'
    },
    templateCard: {
        marginBottom: '1vh',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.lightGray
        }
    },
    templatesDialog: {
        width: '60vw',

    },
    chatBot: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',

    },
    searchPref: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        color: theme.palette.blue,
        fontWeight: 'bold',
        paddingRight: '1vw',
        cursor: 'pointer'
    }
})

export const listStyles = theme => ({
    noEntries: {
        fontSize: '13px',
        margin: '2vh 1vw',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.steelGray
    },
    outerLoader: {
        height: '100%',
        justifyContent: 'center',
        flexDirection: 'column',
        display: 'flex'
    },
    progress: {
        alignSelf: 'center'
    },
    chatList: {
        margin: '2vw',
        paddingBottom: '0vh',
        height: `calc(100% - 61px)`,
        overflow: 'auto',
    },
    listRoot: {
        paddingTop: '0px !important'
    },
    cardContent: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '13px',
        fontWeight: 'bold',
        cursor: 'pointer'
    },
    chatCardContent: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '13px',
        fontWeight: '500',
        cursor: 'pointer',
        padding: '2vh 1vw !important'
    },
    time: {
        fontSize: '12px',
        display: 'flex',
        color: theme.palette.warmGray,
        margin: '1vh 0vw'
    },
    icons: {
        marginRight: '5px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    chatIcons: {
        filter: 'drop-shadow(0px 0px 1px rgba(191, 197, 210, 0.9))',
        margin: '2px 2px'
    },

})



export const messageStyles = theme => ({
    feedback: {
        display: 'flex',
        justifyContent: 'flex-start',
        backgroundColor: theme.palette.white,
        cursor: 'pointer',
    },
    welcomeTitle:{
        color: '#8e1709',
        fontWeight: 'bold',
       
    },
    welcomeMessage:{
        color: '#818181'
    },
    socialMedia:{
        width: '10%',
        marginRight: '1vh'
    },
    welcome:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        textAlign: 'justify'
    },
    attTimeStamp: {
        /*  position: 'absolute',
          bottom: '10px',
          left: '3vw',*/
        marginLeft: '4vw'
    },
    feedbackOption: {
        // width: '50%',
        padding: '1vh 1vw 1vh 0vw',
        marginRight: '22px',
        marginTop: '2px',
        color: theme.palette.black,
        textAlign: 'center',
        fontWeight: 'bold',
        '&:hover': {
            // backgroundColor: theme.palette.paleGray2,
        }
    },
    booleanOption: {
        color: theme.palette.white,
        borderRadius: '15px',
        margin: '1vh 2vw 0vh 0vw',
        padding: '1vh 2vw',
        minWidth: '5vw',
        textAlign: 'center',
        backgroundColor: theme.palette.orange
    },
    negBooleanOption: {
        color: theme.palette.orange,
        border: `solid 1px ${theme.palette.orange}`,
        backgroundColor: theme.palette.white
    },
    selectButton: {
        backgroundColor: `${theme.palette.red} !important`,
        color: theme.palette.white,
        cursor: 'pointer',
        fontWeight: 'bold',
        padding: '2vh 2vw !important',
    },
    feedbackQuestion: {
        backgroundColor: `${theme.palette.white} !important`,
        color: theme.palette.black,
        fontWeight: 'normal',
        padding: '1vh 2vw',
        textAlign: 'center',
    },
    feedbackContainer: {
        backgroundColor: `${theme.palette.white} !important`,
        padding: '0vh !important'
    },
    drawerPaper: {
        padding: '3vw',
        borderTopRightRadius: '20px',
        borderTopLeftRadius: '20px',
        backgroundColor: theme.palette.white
    },
    selectContainer: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '13px',
        boxShadow: '0 0 17px 0 rgba(0, 0, 0, 0.06)',
        margin: '3px 20px 5px 20px',
        borderRadius: '20px',
    },
    selectQuestion: {
        color: theme.palette.white,
        backgroundColor: theme.palette.red,
        padding: '2vh 2vw',
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '20px',
        textAlign: 'center',
    },
    selectAnswerContainer: {
        borderBottomLeftRadius: '20px',
        borderBottomRightRadius: '20px',
        padding: '1vh 2vw',
        backgroundColor: theme.palette.white
    },
    selectOption: {
        cursor: 'pointer',
        backgroundColor: theme.palette.white,
        color: theme.palette.black,
        padding: '1vh 2vw',
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: '8px',
        '&:hover': {
            backgroundColor: theme.palette.paleGray2,

        },
        margin: '1vh 0vw'
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
    },
    attLoading: {
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginRight: '4px',
        position: 'relative',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        border: 'solid 1px gainsboro',
        boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, .2)',
        marginBottom: '1vh',
        width: '30vh',
        height: '15vh'
    },
    fileName: {
        fontSize: '13px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingLeft: '1vw',
        borderRadius: '20px',
        borderTopLeftRadius: '0px'
    },
    name: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    border: {
        border: 'solid 1px gainsboro',
        boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, .2)',
    },
    fileContainer: {
        height: '12vh',
        boxShadow: '0 0 17px 0 rgba(0, 0, 0, 0.06)',
        margin: '3px 12px 8px 12px',
        backgroundColor: theme.palette.white,
        borderRadius: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        width: '30vh',
        borderTopLeftRadius: '0px'
    },
    fileContainerRight: {
        borderRadius: '20px',
        borderTopRightRadius: '0px'
    },
    messages: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    fullHeight: {
        height: '100%'
    },
    attachmentText: {
        color: theme.palette.midBlue,
        textDecoration: 'none',
        margin: '0vh 4px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    center: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%'
    },
    intent: {
        padding: '10px',
        backgroundColor: theme.palette.red,
        borderRadius: '12px',
        fontSize: '13px',
        padding: '4px 1vw',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        color: theme.palette.white

    },


    headingContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },


    typing: {
        display: 'flex',
        justifyContent: 'center',
        margin: '1vh 5px 1vh 20px'
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%'
    },

    detailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
        backgroundColor: theme.palette.snowGray

    },

    timeStamp: {
        color: theme.palette.primaryGray,
        fontSize: '10px',
        display: 'flex',
        justifyContent: 'flex-start',
        fontWeight: 'normal',
        marginTop: '2.5vh',
        marginRight: '-1vw',
        width: '100%'
    },
    agTimeStamp: {
        color: theme.palette.white,
        display: 'flex',
        justifyContent: 'flex-end',
    },
    cardContent: {
        //  padding: '16px',
        paddingBottom: '0px !important',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '0px'
    },
    ticketDetail: {
        cursor: 'pointer',
        borderRadius: '0px',
        boxShadow: 'none',
        overflowX: 'hidden',
        marginTop: '10px',
        backgroundSize: 'cover'
    },
    chatContent: {
        overflow: 'hidden',
        overflowY: 'scroll',
        // height: '84vh',
        width: '100%',
        scrollBehavior: 'smooth'
    },
    minimisedDiv: {
        height: '69vh'
    },
    left: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
    },
    right: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        marginRight: '1vw'

    },
    textMessage: {
        maxWidth: '80%',
        fontSize: '13px',
        minWidth: '19vw',
        boxShadow: '0 0 17px 0 rgba(0, 0, 0, 0.06)',
        margin: '3px 5px 8px 12px',
        borderRadius: '20px',
        padding: '2vh 4vw 1.5vh 4vw',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.white,
        borderTopLeftRadius: '0px'
    },
    agent: {
        color: theme.palette.white,
        backgroundColor: theme.palette.orange,
        borderRadius: '20px',
        borderTopRightRadius: '0px',
        marginRight: '12px'

    },
    hidden: {
        visibility: 'hidden'
    },
    test: {
        width: '30px'
    },
    imgContainer: {
        maxWidth: '50vh',
      //  maxHeight: '30vh',
        borderRadius: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        boxShadow: '0 0 17px 0 rgba(0, 0, 0, 0.06)',
        margin: '3px 12px 8px 12px',
        backgroundColor: theme.palette.white,
        borderTopLeftRadius: '0px',
        paddingBottom: '3vh'
    },
    imgContainerRight: {
        borderRadius: '20px',
        borderTopRightRadius: '0px',

    },
    imgDisplay: {
        maxWidth: '50vh',
        maxHeight: '30vh',
        borderRadius: '8px',
        borderRadius: '20px',
        borderTopLeftRadius: '0px',
    },
    imgDisplayRight: {
        borderRadius: '20px',
        borderTopRightRadius: '0px'
    },
    img: {
        backgroundColor: theme.palette.paleGray2,
        width: '75px',
        color: theme.palette.white,
        display: 'flex',
        maxHeight: '75px',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        cursor: 'pointer',
        borderRadius: '8px',
    },
    darkFont: {
        color: theme.palette.black
    }



})



export const replyStyles = theme => ({
    replyInput: {
        padding: '3vw',
        display: 'flex',
        borderRadius: '10px',
        boxShadow: '0 0 19px 0 rgba(0, 0, 0, 0.07)',
        margin: '0vh 30px',
        backgroundColor: theme.palette.white
    },
    sugReplyInput: {
        borderTopRightRadius: '0px',
        borderTopLeftRadius: '0px',
        borderTop: `solid 1px ${theme.palette.orange}`
    },
    chatClosed: {
        backgroundColor: theme.palette.battleShipGray,
        fontSize: '13px',
        padding: '1vh',
        textAlign: 'center'
    },
    replyCard: {
        cursor: 'pointer',
        backgroundColor: '#f6f7fb',
        width: `100%`,
        //   margin:'0vh 0px 3vh 0px',
        borderRadius: '10px',
        position: 'relative'

    },
    startButton: {
        backgroundColor: theme.palette.red,
        color: theme.palette.white,
        textTransform: 'unset',
        height: '48px',
        marginTop: '20px',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: theme.palette.red,
        }

    },
    textFieldInput: {
        padding: '14px 20px',
        fontSize: '13px',
        boxSizing: 'border-box'
    },
    cardContent: {
        padding: '6px 16px !important',
    },
    root: {
        borderRadius: '0px',
        border: 'none',
        backgroundColor: theme.palette.white,
        '&:hover': {
            backgroundColor: theme.palette.white,
        }

    },
    multiline: {
        padding: '0px',
        paddingRight: '8px'
    },
    actionButton: {
        fontSize: '13px',
        fontWeight: 'bold',
        cursor: 'pointer',
        display: 'flex',
        marginRight: '1vw',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: '10px'
    },

    attachments: {
        fontSize: '11px',
        cursor: 'pointer',
        display: 'flex',
        marginRight: '1vw',
        color: theme.palette.primaryGray,
        border: `solid 1px ${theme.palette.paleGray}`,
        borderRadius: '8px',
        padding: '5px',


    },
    attachmentCont: {
        display: 'flex',
        borderTop: 'none',
        backgroundColor: theme.palette.white,
        padding: '3px'
    },
    vertCenter: {
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '4px',
        fontWeight: '200',
    },

    file: {
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '4px',
        fontWeight: '200',
        width: '10vw',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        borderRadius: '20px',
        borderTopLeftRadius: '0px'
    },
    fileNameRight: {
        borderRadius: '20px',
        borderTopRightRadius: '0px'
    },
    actionItems: {
        display: 'flex',
        justifyContent: 'flex-end',
        border: `solid 1px silver`,
        borderTop: 'none',
        backgroundColor: theme.palette.white,
        border: 'none'
    },
    responses: {
        fontSize: '12px',
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        width: '626px',
        backgroundColor: theme.palette.white,
        cursor: 'pointer',
        '&:hover': {
            transform: 'scale(1.08)',
            transition: 'transform .5s ease',
            transformOrigin: 'left bottom',
        }
    },
    resHeading: {
        color: theme.palette.primaryGray
    },
    textField: {
        width: '87%',
        paddingTop: 0, marginTop: 0, marginBottom: 0,
        position: 'absolute',
        top: '0',
        left: '0'
    },
    hiddenTextField: {
        width: '87%',
        paddingTop: 0, marginTop: 0, marginBottom: 0,
        position: 'absolute',
        top: '0',
        left: '0'
    }
})

