/*
 TicketReply component
 url match: /dashboard
 Base component for the home page, redirects to appropriate 
 components depending on the URL/Route
*/
import { withRouter } from 'react-router-dom';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { Switch, Route } from 'react-router-dom';
import Loader from 'react-loader-spinner'
import { replyStyles } from '../../../Styles/queryStyles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { ReactSVG } from 'react-svg';
import Images from '../../../Theme/Images';
import TextField from '@material-ui/core/TextField';
import AttachmentPreview from '../../../Common/AttachmentPreview';
const acceptedFiles = ['image/gif', 'image/jpeg', 'image/png', 'application/pdf'];

@inject('userStore', 'commonStore', 'queryStore')
@observer
class TicketReply extends React.Component {
    state = {
        showTextField: true,
        comment: '',
        files: [],
    }

    constructor() {
        super()
        this.inputOpenFileRef = React.createRef()
    }

    selectResponse = (value) => {
        this.setState({
            comment: value.suggestionText
        })
    }

    openFileModal = () => {
        const { type, showPassword, password } = this.state;

        this.inputOpenFileRef.current.click();
    }

    clearFiles = (index) => {
        let temp = this.state.files.slice();
        temp.splice(index, 1);

        this.setState({ files: temp })
    }

    onChangeFile = (event, type) => {
        event.stopPropagation();
        event.preventDefault();
        var files = Array.from(event.target.files);
        if (!files.length)
            return
        else {
            let temp = []
            Array.from(files).forEach(x => {
                if (!acceptedFiles.includes(x.type.toLowerCase())) {
                    this.props.commonStore.handleOpenSnackBar('Only image and PDF files are allowed!', 'info')
                    return
                }
                x.myUrl = URL.createObjectURL(x)
                temp.push(x)
            })
            let newFilesArray = this.state.files.slice();
            newFilesArray = newFilesArray.concat(temp)
            if (newFilesArray.length > 10)
                this.props.commonStore.handleOpenSnackBar('Only 10 or less attachments are allowed per reply!', 'info')
            else {
                this.setState({ files: newFilesArray })
            }
        }


    }

    respondToQuery = () => {
        const { item } = this.props;
        const { comment, files, mail } = this.state;
        if ((files.length || comment.length) && !this.props.queryStore.replyProgress) {
            this.props.queryStore.respondToTicket(files, comment.length ? comment : undefined, item.tempId)
                .then(() => {
                    if (!this.props.queryStore.replyProgress) {
                        this.setState({ comment: '', files: [] })
                        this.scrollToBottom()
                    }

                })
            this.setState({ files: [] })
        }

        else if (!this.props.queryStore.replyProgress) {
            this.props.commonStore.handleOpenSnackBar("Please add a comment/attach file before proceeding to send", 'info')
        }


    }

    onClickSatisfactionButton = (type) => {
        this.props.queryStore.setShowFeedback(true);
        this.props.history.push({
            pathname: '/feedback',
            appState: { item: this.props.item, type: type }
        })
    }


    onPressEnter = (e) => {
        if (e.which === 13) {
            this.respondToQuery()
        }
    }


    scrollToBottom = () => {
        var elem = document.getElementById('myscrollingChat');
        if (elem != null)
            elem.scrollTop = elem.scrollHeight;
    }

    renderImg = (row) => {
        const { classes, theme } = this.props;
        return (
            <div className={classes.attachments} ><img src={row.fileSrc} ></img></div>
        )
    }

    render() {
        const { classes, theme } = this.props;
        const { showTextField, comment, files } = this.state;
        return (
            !showTextField ?
                <Card className={classes.replyCard}>
                    <CardContent classes={{ root: classes.cardContent }}>
                        <div className="rowFlex">
                            <div onClick={(e) => this.setState({ showTextField: true })} className={classes.actionButton} ><ReactSVG src={Images.reply} /><span className={classes.vertCenter}>Reply</span></div>
                            <div onClick={(e) => this.setState({ showTextField: true })} className={classes.actionButton} ><ReactSVG src={Images.note} /><span className={classes.vertCenter}>Add note</span></div>
                        </div>
                    </CardContent>
                </Card>
                :
                <div style={{ marginBottom: '3vh' }}>
                    {
                        files.length ?
                            <AttachmentPreview files={files} clearFiles={this.clearFiles} />
                            : null
                    }
                    <div className={classes.replyContainer}>
                        <TextField style={{ paddingTop: 0, marginTop: 0, marginBottom: 0, width: '87%' }} id="response"
                            placeholder='Add a comment...'
                            onChange={(e) => this.setState({ comment: e.target.value })}
                            value={comment}
                            onKeyDown={(e) => this.onPressEnter(e)}
                            margin="normal"
                            variant="filled"
                            fullWidth
                            InputProps={{
                                disableUnderline: true,
                                classes: { input: classes.input, root: classes.root, multiline: classes.multiline },

                            }}
                        />


                        <div className={classes.ticketActions}>
                            <div onClick={(event) => this.onClickSatisfactionButton('satisfied')} className={classes.actionButton} ><ReactSVG src={Images.like} /><span className={classes.vertCenter}></span></div>
                            <div onClick={(event) => this.onClickSatisfactionButton('dissatisfied')} className={classes.actionButton} ><ReactSVG src={Images.dislike} /><span className={classes.vertCenter}></span></div>
                            <div onClick={(e) => this.openFileModal(e)} className={classes.actionButton} ><ReactSVG src={Images.attachment} /><span className={classes.vertCenter}></span></div>
                            {
                                this.props.queryStore.replyProgress ?
                                    <div className={classes.actionButton} >
                                        <Loader height={15} width={15} color={this.props.theme.palette.red} type="Oval" className={classes.loading} />
                                    </div>
                                    :
                                    <div onClick={(e) => this.respondToQuery()} className={classes.actionButton} ><ReactSVG src={Images.sendQuery} /><span className={classes.vertCenter}></span></div>
                            }
                            <input
                                type="file"
                                multiple
                                style={{ display: "none" }}
                                accept="image/x-png,image/gif,image/jpeg,.pdf,.doc"
                                ref={this.inputOpenFileRef}
                                onChange={(e) => this.onChangeFile(e, false)}
                            />
                            <div className="rowFlex">

                            </div>
                        </div>
                    </div>

                </div>
        )
    }
}
export default withStyles(replyStyles, { withTheme: true })(withRouter(TicketReply));
