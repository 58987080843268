import { observable, action } from 'mobx';
import agent from '../agent';
import commonStore from './commonStore';
import websocketStore from './websocketStore';
import chatStore from './chatStore'
import queryStore from './queryStore';

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const mobileRegex = new RegExp(/^[5-9]\d{9}$/);
export class UserStore {
    @observable inProgress = false;
    @observable loginFailed = false;
    @observable statusText;
    @observable errors;
    @observable token;
    @observable anonymousId;
    @observable user = {};
    @observable mobileNo = null;
    @observable registerFailed = false;
    @observable logoutSuccess = false;
    @observable logoutProgress = false;
    @observable emailVerified = false;
    @observable validationErrors = {};
    @observable authenticated = false;
    @observable userData = {};
    @observable statusCount = {};
    @observable customerData = {}
    constructor() {
        let userLocal = window.local_Storage.getItem('user');
        let anonymousId = window.local_Storage.getItem('anonymousId');
        let anonymousToken = window.local_Storage.getItem('anonymousToken');
        if (anonymousId) {
            this.anonymousId = anonymousId
        }
        if (anonymousToken) {
            this.token = anonymousToken
        }
        if (userLocal) {
            userLocal = JSON.parse(userLocal);
            this.authenticated = userLocal.authenticated;
            this.mobileNo = userLocal.mobileNo;
            this.userData = userLocal.userData;
            this.token = userLocal.token;
            if (anonymousToken) {
                this.token = anonymousToken
            }
        }
    }

    @action setCustomerData = (value) => {
        this.customerData = value
    }

    @action setAnonymousId(id) {
        this.anonymousId = id
    }

    @action setToken(token) {
        this.authenticated = true
        this.token = token
    }

    @action resetAnonymousId() {
        this.anonymousId = null;
        chatStore.resetSessionId()
        window.local_Storage.removeItem('anonymousId');
        // window.local_Storage.removeItem('anonymousToken');
        window.local_Storage.removeItem('sessionId');
    }

    @action setEmail(email) {
        this.loginDetails.email = email;
    }

    @action setPassword(password) {
        this.loginDetails.password = password;
    }

    @action reset() {
        this.loginDetails.email = '';
        this.loginDetails.password = '';
    }

    @action setProgress(value) {
        this.inProgress = value
    }

    @action getOtp(mobileNo) {
        this.inProgress = true;
        this.errors = undefined;
        this.loginFailed = false;
        this.resetUserDetails()
        return agent.Auth.getOtp(mobileNo)
            .then(action((response) => {
                if (response.statusCode !== 200)
                    this.loginFailed = true;
                this.statusText = response.message
            }))
            .catch(action((err) => {
                this.loginFailed = true;
            }))
            .finally(action(() => {
                this.inProgress = false;
            }))
    }

    @action getStatusCount() {
        websocketStore.connect()
        this.inProgress = true;
        return agent.Auth.getStatusCount()
            .then(action((response) => {
                if (response.statusCode == '200') {
                    this.statusCount = response.data;
                }
                this.statusText = response.message
            }))
            .catch(action((err) => {

            }))
            .finally(action(() => {
                this.inProgress = false;
            }))
    }

    @action resetStatusText() {
        this.statusText = ''
        this.loginFailed = false
        this.validationErrors = {}
    }

    @action verifyOTP(mobileNo, otp) {
        this.inProgress = true;
        this.errors = undefined;
        this.loginFailed = false;
        this.statusText = ''
        this.loginFailed = false
        return agent.Auth.verifyOTP(mobileNo, otp)
            .then(action((response) => {
                if (response.statusCode !== 200) {
                    this.statusText = response.message
                    this.loginFailed = true
                }

                else {
                    this.authenticated = true;
                    this.mobileNo = mobileNo;
                    this.userData = response.userData.userDetails;
                    this.token = response.userData.accessToken;
                    window.local_Storage.setItem('user', JSON.stringify({
                        authenticated: this.authenticated,
                        mobileNo: this.mobileNo,
                        userData: this.userData,
                        token: this.token
                    }));
                    websocketStore.register();
                }
                this.statusText = response.message
            }))
            .catch(action((err) => {
                this.loginFailed = true;
            }))
            .finally(action(() => {
                this.inProgress = false;
            }))
    }

    @action resetUserDetails() {
        window.local_Storage.removeItem("user");
        window.local_Storage.removeItem("sessionId");
        window.local_Storage.removeItem("anonymousId");
        this.userData = {};
        this.authenticated = false;
        this.mobileNo = null;
        chatStore.resetSessionId();
    }

    @action logout() {
        this.resetUserDetails()
        queryStore.pendingList = []
    }

    @action validateMobile(mobileNo) {
        if (!mobileNo || !mobileRegex.test(mobileNo))
            this.validationErrors['mobileNo'] = "Please enter a valid mobile number";
        else
            delete this.validationErrors['mobileNo'];
    }

    @action validateString(value, type, min, max) {
        let valid = true;
        if (!value || /\s/g.test(value))
            valid = false;
        else if (min && max) {
            if (value.length < min || value.length > max)
                valid = false;
        }
        if (!valid)
            this.validationErrors[type] = "Please enter a valid " + type;
        else
            delete this.validationErrors[type]
    }

    @action goToLogin() {
        this.token = undefined;
        window.local_Storage.removeItem("user");
        window.location.reload();
    }

}

export default new UserStore();
