import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { feedbackStyles } from '../../../Styles/feedbackStyles';
import { inject, observer } from 'mobx-react';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { ReactSVG } from 'react-svg'
import Images from '../../../Theme/Images';
import { withRouter } from 'react-router-dom';
import RadioGroup from '@material-ui/core/RadioGroup';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

@inject('chatStore', 'commonStore')
@observer
class ChatFeedbackDialog extends Component {
    state = {
        showConsent: true,
        consent: true
    }

    consentForPromotions = () => {
        const { version } = this.props.commonStore
        this.props.chatStore.consentForPromotions(this.state.consent)
            .then(() => {
                //  if (!this.props.chatStore.consentProgress) {
                //      this.setState({ showConsent: false })
                setTimeout(() => {
                    this.props.chatStore.setFeedbackDialog(false);
                    this.props.history.push({
                        pathname: `/${version}/home`,
                    })
                }, 2500)
                //  }

            })
    }

    renderConsentForm = () => {
        const { consent } = this.state
        const { classes } = this.props;
        const { consentProgress } = this.props.chatStore
        return (
            <div>
                <div>Stay updated with the latest NEWS, services offered as well as new features and services launched by Protean. Please provide your consent to receive promotional message from Protean eGov Technologies Limited.</div>

                <RadioGroup aria-label="Input Type"
                    name="inputType"
                    value={consent} >
                    <div className={classes.fradio}>
                        <div className={classes.fradioContainer}>
                            {consent ?
                                <div className="colCenter" >
                                    <div className={clsx({
                                        [classes.fradioOption]: true,
                                        [classes.fselectedRadioOption]: true,
                                    })} onClick={(e) => this.handleTypeChange(true)} >
                                        <div className={classes.fradioSelected} >
                                            <div className={classes.fselectedRadio}></div>
                                        </div>
                                    </div>

                                </div>
                                : <div className="colCenter"> <div onClick={(e) => this.handleTypeChange(true)} className={classes.fradioOption}></div></div>}
                            <div className={classes.fradioLabel}>Yes</div>
                        </div>
                        <div className={classes.fradioContainer}>
                            {!consent ?
                                <div className="colCenter" >
                                    <div className={clsx({
                                        [classes.fradioOption]: true,
                                        [classes.fselectedRadioOption]: true,
                                    })} onClick={(e) => this.handleTypeChange(false)} >
                                        <div className={classes.fradioSelected} >
                                            <div className={classes.fselectedRadio}></div>
                                        </div>
                                    </div>

                                </div>
                                : <div className="colCenter"> <div onClick={(e) => this.handleTypeChange(false)} className={classes.fradioOption}></div></div>}
                            <div className={classes.fradioLabel}>No</div>
                        </div>
                    </div>
                </RadioGroup>

                {
                    consentProgress ?
                        <Button fullWidth variant="contained" className={classes.policyButton} >
                            <CircularProgress size={22} thickness={5} className={classes.loading} />
                        </Button> :
                        <Button fullWidth onClick={this.consentForPromotions} variant="contained" className={classes.policyButton} type="submit" style={{ width: '100%' }} >
                            Confirm
                        </Button>
                }
            </div>

        )
    }

    closeDialog = () => {
        this.props.commonStore.setChatRefresh(false)
        this.props.chatStore.setFeedbackDialog(false);
    }

    renderImage = (type) => {
        let imageType = type
        return (
            <img style={{ maxWidth: '100%' }}
                src={Images.thankyou} />
        )
    }

    handleTypeChange = (value) => {
        this.setState({ consent: value })
    }

    render() {
        const { classes, feedback } = this.props;
        const { cFeedbackDialog } = this.props.chatStore;
        const { showConsent, consent } = this.state
        return (
            <Dialog classes={{ paper: classes.cDialog }} open={true} >
                <DialogContent style={{ marginBottom: '2vh' }}>
                    <React.Fragment>
                        {this.renderImage(feedback)}
                        {/*<Typography className={classes.feedbackInfo} >Thank you for your  <br /> feedback!</Typography>*/}
                    </React.Fragment>
                </DialogContent>
            </Dialog>

        )
    }
}
ChatFeedbackDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withRouter(withStyles(feedbackStyles)(ChatFeedbackDialog));
