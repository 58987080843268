import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { feedbackStyles } from '../Styles/feedbackStyles';
import { inject, observer } from 'mobx-react';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withRouter } from 'react-router-dom';

@inject('commonStore', 'commonStore')
@observer
class ConnectivityDialog extends Component {

    render() {
        const { classes } = this.props;
        const { wsFailed } = this.props.commonStore;
        return (
            <Dialog classes={{ paper: classes.cDialog }} open={wsFailed.value != ''} >
                <DialogContent style={{ marginBottom: '2vh' }}>
                    {<Typography className={classes.feedbackInfo} style={{ fontWeight: 'normal' }}>{wsFailed.value}</Typography>}
                </DialogContent>
            </Dialog>
        )
    }
}
ConnectivityDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withRouter(withStyles(feedbackStyles)(ConnectivityDialog));
