/*
 FileUpload component
 url match: /dashboard
 Base component for the home page, redirects to appropriate 
 components depending on the URL/Route
*/
import { withRouter } from 'react-router-dom';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Loader from 'react-loader-spinner'
import { messageStyles } from '../../../Styles/chatStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { ReactSVG } from 'react-svg';
import Images from '../../../Theme/Images';
import TextField from '@material-ui/core/TextField';
import ChatAttachmentPreview from './ChatAttachmentPreview';
const acceptedFiles = ['image/gif', 'image/jpeg', 'image/png', 'application/pdf', 'application/zip'];

@inject('userStore', 'commonStore', 'chatStore')
@observer
class FileUpload extends React.Component {
    state = {
        files: []
    }

    constructor() {
        super()
        this.fileUploadRef = React.createRef()
    }


    componentDidMount() {
        const { index } = this.props;
        document.getElementById("myBtn" + index).addEventListener("click", this.openFileModal);
    }

    componentWillUnmount() {
        const { index } = this.props;
        document.getElementById("myBtn" + index).removeEventListener("click", this.openFileModal);
    }

    openFileModal = (e) => {
        e.stopPropagation()
        this.fileUploadRef.current.click();
    }

    clearFiles = (index) => {
        let temp = this.state.files.slice();
        temp.splice(index, 1);
        this.setState({ files: temp })
        if (temp.length < 1)
            this.cancelUpload()
    }

    cancelUpload = () => {
        this.props.chatStore.setAttachmentPreview(false)
        this.setState({ files: [] })
        this.props.focusInput()
    }

    onChangeFile = (event, type) => {
        event.stopPropagation();
        event.preventDefault();
        var files = event.target.files;
        if (!files.length)
            return
        else {
            let temp = []
            Array.from(files).forEach(x => {
                if (!acceptedFiles.includes(x.type.toLowerCase())) {
                    this.props.commonStore.handleOpenSnackBar('Only image and PDF files are allowed!', 'info')
                    return
                }
                x.myUrl = URL.createObjectURL(x)
                temp.push(x)
            })
            let newFilesArray = this.state.files.slice();
            newFilesArray = newFilesArray.concat(temp)
            if (newFilesArray.length > 10)
                this.props.commonStore.handleOpenSnackBar('Only 10 or less attachments are allowed per reply!', 'info')
            else {
                this.setState({ files: newFilesArray })
            }
            this.props.chatStore.setAttachmentPreview(true)
        }
        event.target.value = ''
    }
    componentWillUnmount() {
        this.fileUploadRef = null
    }

    scrollToBottom = () => {
        var elem = document.getElementById('myscrollingChat');
        if (elem != null)
            elem.scrollTop = elem.scrollHeight - elem.offsetHeight;
    }

    replyToChat = () => {
        const { files } = this.state;
        const { replyProgress } = this.props.chatStore;
        if (files.length && !replyProgress) {
            this.props.chatStore.sendMessage(files, undefined)
                .then(() => {
                    this.props.chatStore.setAttachmentPreview(false)
                    if (!this.props.chatStore.replyProgress) {
                        this.props.focusInput()
                        this.setState({ files: [] });
                        //  this.scrollToBottom();
                    }

                })
            this.setState({ files: [] })
        }

    }


    render() {
        const { classes, row, index } = this.props;
        const { files } = this.state
        return (
            <div className={clsx({ [classes.textMessage]: true, [classes.selectButton]: true })} id={"myBtn" + index}>
                {row.webText}
                <input
                    type="file"
                    multiple
                    style={{ display: "none" }}
                    ref={this.fileUploadRef}
                    accept="image/x-png,image/gif,image/jpeg,.pdf,.doc,.zip,.rar,.7zip"
                    onChange={(e) => this.onChangeFile(e, false)}
                />
                {files.length ? <ChatAttachmentPreview files={files} clearFiles={this.clearFiles} upload={this.replyToChat} cancelUpload={this.cancelUpload} /> : null}

            </div>
        )
    }
}
export default withStyles(messageStyles, { withTheme: true })(withRouter(FileUpload));
