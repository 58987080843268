import { observable, action } from 'mobx';
import agent from '../agent';
import chatStore from './chatStore';
import userStore from './userStore';
import websocketStore from './websocketStore';

export class QueryStore {
    @observable inProgress = false;
    @observable statusText;
    @observable errors;
    @observable faqs;
    @observable queryCount = {
        pendingQueryCount: 0,
        completedQueryCount: 0
    }
    @observable autoSuggestion;
    @observable processedData;
    @observable uploadProgress = false
    @observable currentQuery;
    @observable showFeedback = false;
    @observable showAckStatus = false;
    @observable totalQuery = 0;
    @observable queryList = [];
    @observable policyList = [];
    @observable pendingList = [];
    @observable redirectToChat = false;
    @observable queryLog = {};
    @observable logProgress = false;
    @observable initiateChat = true;
    @observable ticketDetails = {}
    @observable captchaImg = "";
    @observable uniqueId = "";
    @observable agentTransferAllowed = false;

    @action resetStatusText() {
        this.statusText = ''
        this.errors = false
    }

    @action getFaqs(tempId) {
        websocketStore.connect()
        this.statusText = '';
        this.errors = undefined;
        this.inProgress = true;
        this.pendingChat = {}
        return agent.Home.getFaqs(tempId)
            .then(action((response) => {
                if (response.statusCode == 200) {
                    this.faqs = response.widgetsArray;
                    this.queryCount.pendingQueryCount = response.pendingQueryCount;
                    this.queryCount.completedQueryCount = response.completedQueryCount;
                    this.pendingList = response.pendingQueryList;
                    this.pendingChat = response.pendingChat
                }
                else {
                    this.statusText = response.message;
                }
            }))
            .catch(action((err) => {
                this.errors = err.response && err.response.body && err.response.body.errors;
                this.statusText = "Error fetching frequently asked questions"
            }))
            .finally(action(() => {
                this.inProgress = false;
            }))
    }

    @action getAutoSuggestText(text) {
        this.statusText = '';
        this.errors = undefined;
        return agent.Home.getAutoSuggestText(text)
            .then(action((response) => {
                if (response.statusCode == 200) {
                    this.autoSuggestion = response.dataToSend
                    this.initiateChat = true
                }
                else {
                    this.statusText = response.message;
                }
            }))
            .catch(action((err) => {
                this.errors = err.response && err.response.body && err.response.body.errors;
            }))
            .finally(action(() => {
            }))
    }

    @action checkAckStatus(ackNo, type, captcha) {
        this.statusText = '';
        this.inProgress = true
        this.errors = false;
        return agent.Query.checkAckStatus(ackNo, type, captcha, this.uniqueId, userStore.customerData)
            .then(action((response) => {
                if (response.statusCode == 200) {
                    this.ticketDetails = response.data
                    userStore.setToken(response.data.accessToken)
                    window.local_Storage.setItem('user', JSON.stringify({
                        authenticated: true,
                        token: response.data.accessToken
                    }));
                    this.agentTransferAllowed = response.data.agentTransferAllowed
                }
                else {
                    this.statusText = response.message
                    this.errors = true
                }
            }))
            .catch(action((err) => {
                this.errors = err.response && err.response.body && err.response.body.errors;
            }))
            .finally(action(() => {
                this.inProgress = false
            }))
    }

    @action getCaptcha = () => {
        //this.statusText = '';
        // this.errors = false;
        return agent.Query.getCaptcha()
            .then(action((response) => {
                if (response) {
                    this.captchaImg = response.imgSrc
                    this.uniqueId = response.uniqueId
                }
            }))
            .catch(action((err) => {
                this.errors = err.response && err.response.body && err.response.body.errors;
            }))
            .finally(action(() => {
            }))
    }


    @action askQuery(intentList, searchText, policyNo) {
        this.statusText = '';
        this.errors = undefined;
        this.inProgress = true;
        this.policyList = [];
        this.redirectToChat = false;
        return agent.Query.askQueries(intentList, searchText, policyNo)
            .then(action((response) => {
                if (response.statusCode === 200) {
                    if (response.policyList && response.policyList.length) {
                        this.policyList = response.policyList;
                    }
                    else if (response.data.chatData) {
                        this.redirectToChat = true
                        chatStore.setupChat(response.data)
                        if (response.accessToken) {
                            userStore.setToken(response.accessToken)
                            window.local_Storage.setItem('anonymousToken', response.accessToken);
                        }
                        if (!userStore.authenticated) {
                            window.local_Storage.setItem('anonymousId', response.data.chatData.uniqueClientId);
                            userStore.setAnonymousId(response.data.chatData.uniqueClientId)
                        }
                    }
                    else {
                        this.queryList = response.queryList;
                        this.queryList = this.queryList.map((obj, index) => ({
                            ...obj,
                            expanded: false,
                        }))
                    }

                }
                else
                    this.statusText = response.message;
            }))
            .catch(action((err) => {
                this.errors = err.response && err.response.body && err.response.body.errors;
            }))
            .finally(action(() => {
                this.inProgress = false;
            }))
    }

    @action updateQuery(intentList, searchText, tempId, policyNo) {
        this.statusText = '';
        this.errors = undefined;
        this.inProgress = true;
        return agent.Query.updateQuery(intentList, searchText, tempId, policyNo)
            .then(action((response) => {
                if (response.statusCode === 200) {
                    if (response.policyList && response.policyList.length) {
                        this.policyList = response.policyList;
                    }
                    else if (response.data.chatData) {
                        this.redirectToChat = true
                        chatStore.setupChat(response.data)
                    }
                    else {
                        this.queryList = response.queryList;
                        this.queryList = this.queryList.map((obj, index) => ({
                            ...obj,
                            expanded: false,
                        }))
                    }
                }
                else
                    this.statusText = response.message;
            }))
            .catch(action((err) => {
                this.errors = err.response && err.response.body && err.response.body.errors;
            }))
            .finally(action(() => {
                this.inProgress = false;
            }))
    }

    @action expandQuery(index, value) {
        this.queryList.forEach((item, i) => {
            if (i === index)
                this.queryList[i].expanded = value
            else
                this.queryList[i].expanded = false
        })
    }

    @action getQueries(type, pageNo) {
        websocketStore.connect()
        this.statusText = '';
        this.errors = undefined;
        this.inProgress = true;
        return agent.Query.getQueries(type, pageNo)
            .then(action((response) => {
                if (response.statusCode === 200) {
                    if (pageNo == 0)
                        this.queryList = response.list;
                    else
                        this.queryList.push(...response.list);
                    this.queryList = this.queryList.map((obj, index) => ({
                        ...obj,
                        expanded: false,
                    }))
                    this.totalQuery = response.totalQuery;
                }
                else
                    this.statusText = response.message;
            }))
            .catch(action((err) => {
                this.errors = err.response && err.response.body && err.response.body.errors;
            }))
            .finally(action(() => {
                this.inProgress = false;
            }))
    }

    @action getQueryLog(tempId) {
        websocketStore.connect()
        this.statusText = '';
        this.errors = undefined;
        this.logProgress = true
        return agent.Query.getQueryLog(tempId)
            .then(action((response) => {
                if (response.statusCode === 200) {
                    this.queryLog = response.data;
                }
            }))
            .catch(action((err) => {
            }))
            .finally(action(() => {
                this.logProgress = false
            }))
    }

    @action resetQueryLog() {
        this.queryLog = {};
    }

    @action respondToTicket(files, message, tempId) {
        this.replyProgress = true;
        return agent.Query.respondToTicket(files, message, tempId)
            .then(action((response) => {
                if (response.statusCode === 200) {
                    this.queryLog = response.data;
                    var elem = document.getElementById('myTicketData');
                    elem.scrollTop = elem.scrollHeight;
                }
            }))
            .catch(action((err) => {
            }))
            .finally(action(() => {
                this.replyProgress = false;
            }))
    }


    @action uploadDocuments(tempId, stepNumber, files) {
        this.statusText = '';
        this.errors = undefined;
        this.uploadProgress = true;
        this.currentQuery = tempId
        return agent.Query.uploadDocuments(tempId, stepNumber, files.frontImg, files.backImg)
            .then(action((response) => {
                this.processedData = response.processedData;
            }))
            .catch(action((err) => {
                this.errors = err.response && err.response.body && err.response.body.errors;
            }))
            .finally(action(() => {
                this.uploadProgress = false;
            }))
    }

    @action reupload() {
        this.processedData = undefined
    }

    @action setShowFeedback(value) {
        this.showFeedback = value;
    }

    @action setShowAckStatus(value) {
        this.showAckStatus = value;
    }

}

export default new QueryStore();

