import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { queryListStyles } from '../../../Styles/queryStyles';
import { inject, observer } from 'mobx-react';
import clsx from 'clsx'
import { ReactSVG } from 'react-svg'
import Images from '../../../Theme/Images';
import Moment from 'moment';
import TicketReply from './TicketReply';
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from '@material-ui/core/Drawer';
@inject('queryStore', 'commonStore')
@observer
class TicketChat extends Component {

    state = {
        openChatModal: false
    }

    renderContent = () => {
        const { classes, item } = this.props;
        const { logProgress } = this.props.queryStore;
        return (
            <div className={classes.chatContainer} id="myTicketData">
                {this.renderEvents()}
                {logProgress ?
                    <div className={classes.outerLoader} style={{ minHeight: '0px' }}>
                        <CircularProgress size={50} thickness={2} className={classes.progress} />
                    </div>
                    :
                    <TicketReply item={item} type={this.props.type} />}

            </div>
        )
    }

    closeChatModal = () => {
        this.setState({ openChatModal: false })
    }

    componentDidMount() {
        var elem = document.getElementById('myTicketData');
        if (elem != null)
            elem.scrollTop = elem.scrollHeight;
        let that = this;
        setTimeout(function () { that.setState({ showLoader: false }) }, 1000);
    }

    getPrevImage = () => {
        const { attachments } = this.state;
        let currIndex = attachments.findIndex(x => x === this.state.currentImage);
        if (currIndex - 1 > -1)
            this.setState({ currentImage: attachments[currIndex - 1] })
    }

    getNextImage = () => {
        const { attachments } = this.state;
        let currIndex = attachments.findIndex(x => x === this.state.currentImage);
        if (currIndex + 1 < attachments.length)
            this.setState({ currentImage: attachments[currIndex + 1] })
    }

    componentWillUnmount() {
        clearTimeout()
    }


    modifyText = (text) => {
        return this.props.commonStore.modifyText(text);
    }

    renderText = (item) => {
        const { classes, theme } = this.props;
        return (
            <div className={clsx({ [classes.text]: true, [classes.custText]: item.dataFrom === 'customer' })}>
                {item.value}
            </div>
        )
    }

    renderSystemMessage = (item, label) => {
        const { classes, theme } = this.props;
        return (
            <div className={clsx({ [classes.text]: true })}>
                <div style={{ display: 'flex' }}><span >{label} &nbsp;</span> {this.modifyText(item.value)}</div>
            </div>
        )
    }

    openImage = (url, item) => {
        this.setState({ currentImage: url, openImageDialog: true, attachments: item.attachments })
    }

    closeImage = () => {
        this.setState({ openImageDialog: false })
    }

    downloadFile = (fileUri, fileName) => {
        let link = document.createElement('a');
        link.target = '_blank'
        link.href = fileUri;
        link.click();
    }

    renderAttachment = (item) => {
        const { classes, theme } = this.props;
        return (
            <React.Fragment>
                <div >
                    <div className={clsx({ [classes.attachments]: true })}>
                        {
                            item.attachments.map((row, index) => (
                                row.type === 'IMAGE' ?
                                <div className={classes.imgContainer} onClick={(e) => this.downloadFile(row.url, row.fileName)}> 
                                <img className={classes.imgDisplay} src={row.url}></img>
                                  </div>
                                :
                                <div className={classes.fileContainer} onClick={(e) => this.downloadFile(row.url, row.fileName)}>
                                    <div className="rowFlex">
                                        <ReactSVG src={Images.pdfDoc} />
                                        <div className={classes.fileName}>
                                            <span className={classes.name}> {row.originalFileName}</span>
                                        </div>
                                    </div>
    
                                </div>  
                            ))
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }


    renderEvents = () => {
        const { classes, theme } = this.props;
        const { queryLog, replyProgress } = this.props.queryStore;
        const { openChatModal } = this.state;
        return (
            <div className={classes.logData} style={{maxHeight: '68vh', overflowY: 'scroll'}} >
                {queryLog.dataArray && !queryLog.dataArray.length  ? null :(openChatModal ? null :  <div className={classes.loadMore} onClick={(e) => this.setState({ openChatModal: true })}>Load more messages</div>)}
                {
                    queryLog.dataArray && queryLog.dataArray.slice(openChatModal ? '' : queryLog.dataArray.length - 2).map((row, index) => (
                        <div key={index} className="colFlex" style={{ width: '100%' }}>
                            <div className={row.dataFrom === 'customer' ? classes.customer : classes.agent}>
                                {row.dataType === 'text' ? this.renderText(row) : null}
                                {row.dataType === 'attachment' ? this.renderAttachment(row) : null}
                            </div>
                            <div className={row.dataFrom === 'customer' ? classes.customerTime : classes.agentTime}>{Moment(row.createdAt).format('DD/MM/YYYY, h:mm a')}</div>
                        </div>
                    ))
                }
            </div>
        )
    }


    render() {
        const { openChatModal } = this.state;
        const { classes } = this.props;
        return (
            <React.Fragment>
                <div>{this.renderContent()}</div>
                <Drawer classes={{
                    paper: classes.drawerPaper,
                }} anchor="bottom" open={openChatModal} onClose={(e) => this.closeChatModal()}>
                    <div style={{height: '100%'}}>{this.renderContent()}</div>
                </Drawer>
            </React.Fragment>


        )
    }
}
TicketChat.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(queryListStyles)(TicketChat);
