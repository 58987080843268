export const queryListStyles = theme => ({
    cFooterButton: {
        width: '49%',
        backgroundColor: theme.palette.orange,
        borderRadius: '19px',
        '&:hover': {
            backgroundColor: theme.palette.orange,
        }
    },
    skipButton: {
        color: 'darkGray',
        fontSize: '14px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '2vh',
        marginTop: '2vh',
        color: 'white',
        width: '30%',
        padding: '4px 0vw',
        borderRadius: '19px',
        backgroundColor: theme.palette.red,
        '&:hover': {
            backgroundColor: theme.palette.red,
        }
    },
    cSatisfiedButton: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        color: theme.palette.white,
        fontSize: '13px',
        fontWeight: '500',
        backgroundColor: theme.palette.orange
    },
    cDFooterButton: {
        backgroundColor: 'transparent',
        border: `solid 1px ${theme.palette.orange}`,
        '&:hover': {
            backgroundColor: 'transparent',
        }

    },
    dissatisfiedButton: {
        color: theme.palette.orange,
        backgroundColor: 'transparent',
    },
    descText: {
        margin: '3vh 0vw',
        textAlign: 'left',
        color: theme.palette.warmGray,
        fontSize: '13.5px',
        fontWeight: '500',
        whiteSpace: 'pre-wrap', wordBreak: 'break-word' 
    },
    innerCard: {
        padding: '23px 27px'
    },
    cardHeading: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    satisfiedButton: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        color: theme.palette.white,
        fontSize: '13px',
        fontWeight: '500',
        backgroundColor: theme.palette.green
    },
    feedbackIcon: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: 'inherit',
        marginRight: '1vw'
    },
    noQueries: {
        fontSize: '13px',
        margin: '4vh 1vw',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.steelGray
    },
    alternateContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '98%'
    },
    itemGutter: {
        paddingLeft: '0px !important'
    },
    processedValue: {
        backgroundColor: '#eeeeef',
        fontSize: '12px',
        padding: '1vh',
        marginBottom: '1vh',
        borderRadius: '8px',
        color: theme.palette.black
    },
    claimText: {
        fontSize: '13px',
        //  padding: '0.5vh 0vw',
        fontWeight: '500',
        lineHeight: '1.64'
    },
    listPadding: {
        paddingTop: '0vh !important'
    },
    dateContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        margin: '1vh 0vw'
    },
    fileName: {
        fontSize: '13px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    name: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    border: {
        border: 'solid 1px gainsboro',
        boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, .2)',
    },
    fileContainer: {
        height: '8vh',
        backgroundColor: theme.palette.white,
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: '1vh 0vw',
        marginRight: '4px',
        position: 'relative',
        width: '30vh',
        border: 'solid 1px gainsboro',
        boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, .2)',


    },
    att: {
        background: 'white !important'
    },
    imgDisplay: {
        maxWidth: '30vh',
        maxHeight: '30vh',
    },
    imgContainer: {
        maxWidth: '30vh',
        maxHeight: '30vh',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginRight: '4px',
        position: 'relative',
        backgroundRepeat: 'no-repeat',
        //  backgroundSize: 'cover',
        // border: 'solid 1px gainsboro',
        boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, .2)',
        marginBottom: '1vh'
    },
    latestTicket: {
        backgroundColor: 'white', height: '100px', cursor: 'pointer'
    },
    outerLoader: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    },
    textStyle: {
        fontSize: '13px'
    },

    progress: {
        color: theme.palette.red,
    },
    tempIdText: {
        fontSize: '16px',
        color: theme.palette.warmGray
    },
    cardContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: 'fit-content',
        borderRadius: '10px',
        boxShadow: '0 0 17px 0 rgba(0, 0, 0, 0.1)',
        backgroundColor: theme.palette.white
    },
    queryCard: {
        padding: '0vh 4vw',
        marginTop: '2vh',
        marginBottom: '1vh',
    },
    latestQueryCard: {
        padding: '0vh 4vw',
        marginTop: '1vh',
        marginBottom: '1vh',
        cursor: 'pointer'
    },
    queryHeading: {
        display: 'flex',
        flexDirection: 'column',
        height: 'fit-content',
        padding: '1vh 0vw',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.white
    },
    policyNoText: {
        fontSize: '13px',
        color: theme.palette.warmGray,
        fontWeight: '500'
      //  lineHeight: '26.5px',
    },
    icons: {
    },
    footerContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '9vh'
    },
    footerButton: {
        width: '49%',
        backgroundColor: theme.palette.green,
        borderRadius: '10px',
        '&:hover': {
            backgroundColor: theme.palette.green,
        }
    },
    imageContainer: {
        display: 'flex',
        width: '87%',
        height: '14vh',
        cursor: 'pointer',
        padding: '0vh 4vw'
    },
    img: {
        width: '40%',
        background: theme.palette.battleShipGray,
        borderRadius: '13px',
        height: '14vh',
        textAlign: 'center',
        marginRight: '1vw',
        fontSize: '9px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    imgSvg: {
        margin: '0 auto',
        display: 'block'
    },
    preview: {
        height: '12vh',
        margin: '0 auto'
    },
    cancel: {
        position: 'absolute',
        top: '0',
        right: '0',
        cursor: 'pointer'
    },
    loading: {
        color: theme.palette.white,
    },
    error: {
        color: theme.palette.red,
        fontSize: '12px'
    },
    reupload: {
        background: theme.palette.red,
        color: theme.palette.white,
        marginTop: '1vh',
        fontSize: '12px'
    },
    intentHeading: {
        fontSize: '15px',
        fontWeight: '500',
        color: theme.palette.black,
       // lineHeight: '26.5px',
    },
    uploadText: {
        fontSize: '12px',
        margin: '1.5vh 0vw',
        // padding: '0vh 4vw'
    },
    expansionPanel: {
        minHeight: '13vh !important',
        overflow: 'hidden'
    },
    notYourQuery: {
        fontSize: '12px',
        color: theme.palette.warmGray,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%'
    },
    infoText: {
        fontSize: '12px',
        color: theme.palette.steelGray
    },
    faqItems: {
        fontSize: '12px',
        marginLeft: '1vw'
    },
    bulletIcon: {
        color: theme.palette.red,
        fontSize: 'medium',
    },
    queryList: {
        padding: '20px 30px',
        height: 'calc(100%  - 40px)',
        backgroundColor: theme.palette.background
    },
    expandIcon: {
        position: 'absolute',
        top: '0%',
        left: '88%'
    },
    detail: {
        display: 'flex',
        color: theme.palette.primaryGray,
        fontSize: '12px',
        margin: '1vh',
        marginLeft: '0vw',
        marginRight: '7vw'
    },
    time: {
        display: 'flex',
        color: theme.palette.warmGray,
        fontSize: '12px',
       // margin: '1vh',
        marginLeft: '0vw',
        marginRight: '0vw'
    },
    detailIcon: {
        marginRight: '5px'
    },
    actionsRoot: {
        padding: '0px'
    },
    formContainer: {
        padding: '0vh 2vw',
        color: theme.palette.warmGray,
        fontSize: '12px',
        marginLeft: '1vw'
    },
    textFieldInput: {
        fontSize: '12px',
        padding: '1vh'
    },
    errorContainer: {
        padding: '1vh 6vw',
    },
    detailContainer: {
        width: '100%',
        margin: '3vh 0vw 4vh 0vw',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'space-between'
    },
    vertCenter: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'

    },
    expanded: {
        marginBottom: '0px !important'
    },
    latestQuery: {
        backgroundColor: theme.palette.white,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'fit-content',
        padding: '1vh 0vw',
        justifyContent: 'space-between',
    },
    chatContainer: {
        padding: '2vw',
        justifyContent: 'space-between',
        flexDirection: 'column',
        display: 'flex',
        height: '100%'
    },
    custText: {
        backgroundColor: `${theme.palette.dimGray} !important`,
        color: `${theme.palette.black} !important`,
        maxWidth: '50%'
    },
    text: {
        fontSize: '12px',
        backgroundColor: theme.palette.red,
        padding: '1vw',
        borderRadius: '8px',
        color: theme.palette.white,
        maxWidth: '50%',
        whiteSpace: 'pre-line'
    },
    customer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: '2vh'
    },
    agent: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginLeft: '1vw'
    },
    customerTime: {
        fontSize: '9px',
        color: theme.palette.warmGray,
        justifyContent: 'flex-end',
        margin: '1vh 0vw 2vh 0vw',
        display: 'flex',
        marginRight: '2vh'
    },
    agentTime: {
        fontSize: '9px',
        color: theme.palette.warmGray,
        justifyContent: 'flex-start',
        display: 'flex',
        margin: '1vh 0vw 2vh 0vw',
        marginLeft: '1vw'
    },
    loadMore: {
        color: theme.palette.warmGray,
        fontSize: '10px',
        margin: '1vh',
        textAlign: 'center',
        cursor: 'pointer'
    },
    drawerPaper: {
        height: '80%',
        padding: '3vw',
        borderTopRightRadius: '20px',
        borderTopLeftRadius: '20px',
        backgroundColor: theme.palette.white,
        overflow: 'hidden'
    },
    chatDiv: {
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column'
    }

});
export const replyStyles = theme => ({
    replyContainer: {
        border: 'solid 1px lightGray',
        display: 'flex',
        borderRadius: '6px'
    },
    replyCard: {
        marginBottom: '1vh',
        marginRight: '1vw',
        cursor: 'pointer',
        backgroundColor: theme.palette.white,
    },
    textFieldInput: {
        padding: '14px 20px',
        fontSize: '13px',
        boxSizing: 'border-box'
    },
    input: {
        padding: '14px 20px',
        fontSize: '12px',
        boxSizing: 'border-box'
    },
    cardContent: {
        padding: '6px 16px !important',
    },
    root: {
        borderRadius: '6px',
        border: 'none',
        backgroundColor: theme.palette.white,
        '&:hover': {
            backgroundColor: theme.palette.white,
        }

    },
    multiline: {
        padding: '0px',
        paddingRight: '8px'
    },
    actionButton: {
        fontSize: '13px',
        fontWeight: 'bold',
        cursor: 'pointer',
        display: 'flex',
        marginRight: '1vw',
    },

    attachments: {
        fontSize: '11px',
        cursor: 'pointer',
        display: 'flex',
        marginRight: '1vw',
        color: theme.palette.primaryGray,
        border: `solid 1px ${theme.palette.paleGray}`,
        borderRadius: '8px',
        padding: '5px',


    },
    attachmentCont: {
        display: 'flex',
        borderTop: 'none',
        backgroundColor: theme.palette.white,
        padding: '3px'
    },
    vertCenter: {
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '4px',
        fontWeight: '200',
    },
    file: {
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '4px',
        fontWeight: '200',
        width: '10vw',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: theme.palette.warmGray
    },
    actionItems: {
        display: 'flex',
        justifyContent: 'flex-end',
        border: `solid 1px silver`,
        borderTop: 'none',
        backgroundColor: theme.palette.white,
        border: 'none'
    },
    ticketActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        border: `solid 1px silver`,
        borderTop: 'none',
        backgroundColor: theme.palette.white,
        border: 'none',
        marginTop: '1vh',
        borderRadius: '6px'
    },
    responses: {
        fontSize: '12px',
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        width: '626px',
        backgroundColor: theme.palette.white,
        cursor: 'pointer',
        '&:hover': {
            transform: 'scale(1.08)',
            transition: 'transform .5s ease',
            transformOrigin: 'left bottom',
        }
    },
    resHeading: {
        color: theme.palette.primaryGray
    }
})