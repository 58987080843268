import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { feedbackStyles } from '../../Styles/feedbackStyles';
import { inject, observer } from 'mobx-react';
import Typography from '@material-ui/core/Typography';
import { Divider } from 'material-ui';

@inject('queryStore')
@observer
class FeedbackDetails extends Component {

    render() {
        const { classes } = this.props;
        const { ticketDetails } = this.props.queryStore
        return (
            <div style={{ paddingTop: '1vh' }}>
                {/*<div style={{ fontSize: '12px' }} className={classes.feedbackText} >
                    On {new Date(ticketDetails.createdAt).toDateString()}, you have enquired about
                        </div>*/}
                <Typography className={classes.feedbackInfo} variant="subtitle1" >
                You have enquired about status of {ticketDetails.cardHeader.includes('PAN') ? 'PAN ' : 'TAN '} Application - {ticketDetails.ackNumber}
                </Typography>
               {/* <div className={classes.feedbackText} >
                    Ticket ID: {ticketDetails.ticketId}
                </div>*/}

            </div>
        )
    }
}
FeedbackDetails.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(feedbackStyles)(FeedbackDetails);
