/*
 Login component
 url match: / or /login
 Displays email id and password field
 with Login button
*/
import { withRouter, Link } from 'react-router-dom';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { isObjectEmpty } from '../../utils';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { loginStyles } from '../../Styles/loginStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ReactSVG } from 'react-svg'
import Images from '../../Theme/Images';
import InputAdornment from '@material-ui/core/InputAdornment';
@inject('userStore', 'commonStore', 'queryStore')
@observer
class Login extends React.Component {

    state = {
        user: {
            email: "",
            mobileNo: ""
        },
        showError: false,
        showLogin: true,
        showLoader: true
    }


    componentWillUnmount() {

    }

    componentDidMount() {
        if (this.props.userStore.authenticated)
            this.props.history.push("/home")
        this.props.userStore.resetStatusText()
        let that = this;
        setTimeout(() => {
            that.setState({
                showLoader: false
            })
        }, 1000);

    }

    handleChange = (field, value) => {
        const user = Object.assign({}, this.state.user, {
            [field]: value
        });
        this.setState({ user });
    }

    getOtp = (e) => {
        e.preventDefault();
        const { user } = this.state;
        this.props.userStore.validateMobile(user.mobileNo)
        if (isObjectEmpty(this.props.userStore.validationErrors))
            this.props.userStore.getOtp(user.mobileNo)
                .then(() => {
                    const { userStore } = this.props;
                    if (!userStore.loginFailed && !userStore.inProgress)
                        this.setState({
                            showLogin: false
                        })
                })
    }

    verifyOTP = (e) => {
        e.preventDefault();
        const { user } = this.state;
        this.props.userStore.resetStatusText()
        this.props.userStore.validateString(user.otp, "otp", 5, 5)
        if (isObjectEmpty(this.props.userStore.validationErrors))
            this.props.userStore.verifyOTP(user.mobileNo, user.otp)
                .then(() => {
                    const { userStore } = this.props;
                    if (!userStore.loginFailed && !userStore.inProgress) {
                        if (this.props.location.state === undefined)
                            this.props.history.push('/home')
                        else if (this.props.location.state && this.props.location.state.goTo === 'chats')
                            this.props.history.push('/chats')
                        else if (this.props.location.state && this.props.location.state.type === 'pending') {
                            if (this.props.location.state.createQuery) {
                                this.props.queryStore.askQuery(this.props.location.state.createQuery, null)
                                    .then(() => {
                                        if (!this.props.queryStore.inProgress) {
                                            if (this.props.queryStore.policyList && this.props.queryStore.policyList.length) {
                                                this.props.history.push({
                                                    pathname: '/policies',
                                                    state: { input: [{ intent: this.props.location.state.createQuery.intent, filter: "HEALTH_POLICY" }] }
                                                })
                                            }
                                            else if (this.props.queryStore.redirectToChat) {
                                                this.props.history.push('/chat')
                                            }
                                            else {
                                                this.props.commonStore.setHeaderText('Pending Queries')
                                                this.props.history.push({
                                                    pathname: '/queries/pending',
                                                    state: { type: "pending" }
                                                })
                                            }
                                        }
                                    })
                                this.props.commonStore.setHeaderText('Pending Queries')

                            }
                            else {
                                this.props.commonStore.setHeaderText('Pending Queries')
                                this.props.history.push({
                                    pathname: '/queries/pending',
                                    state: { type: "pending" }
                                })
                            }
                        }
                        else if (this.props.location.state && this.props.location.state.type === 'completed') {
                            this.props.commonStore.setHeaderText('Completed Queries')
                            this.props.history.push({
                                pathname: '/queries/completed',
                                state: { type: "completed" }
                            })
                        }
                        else {
                            this.props.history.push('/home')
                        }

                    }
                    else {

                    }

                })
    }

    renderLogo = () => {
        const { classes } = this.props;
        return (
            <ReactSVG beforeInjection={svg => { svg.classList.add(classes.svg) }} src={Images.loginLogo} />
        )
    }

    editMobile = () => {
        const user = Object.assign({}, this.state.user, {
            otp: ''
        });
        this.setState({ user, showLogin: true });
    }

    handleFocus = () => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }

    renderOtpVerificationScreen = () => {
        const { inProgress, loginFailed, statusText, validationErrors } = this.props.userStore;
        const { classes } = this.props;
        const { user } = this.state;
        return (
            <div>
                <p className={classes.loginText}>Enter the 5 digit code sent to {user.mobileNo}</p>
                <form noValidate autoComplete="off" className={classes.formStyle}>
                    <FormControl error aria-describedby="component-error-text" className={classes.formControl}>
                        <TextField onFocus={(e) => this.handleFocus()} required id="otp-input" label="Enter OTP" type="tel" name="otp" variant="outlined"
                            fullWidth autoFocus={true} InputProps={{ classes: { input: classes.textFieldInput } }}
                            value={user.otp} onChange={(event) => this.handleChange("otp", event.target.value)}
                        />
                        {validationErrors.otp && <FormHelperText id="otp-input">{validationErrors.otp}</FormHelperText>}
                        {loginFailed &&
                            <div className={classes.error}>
                                <ReactSVG src={Images.error} />
                                <div className={classes.errorStyle}>{statusText}</div>
                            </div>}
                    </FormControl>
                    {
                        inProgress ?
                            <Button variant="contained" className={classes.button} fullWidth>
                                <CircularProgress size={30} thickness={5} className={classes.loading} />
                            </Button> :
                            <Button variant="contained" className={classes.button} type="submit" onClick={this.verifyOTP} fullWidth>
                                Confirm
                            </Button>

                    }
                    <div className={classes.editContainer}>
                        <div className={inProgress ? classes.disabled : classes.editContainer} onClick={(e) => this.editMobile()}>
                            <span className={classes.noClick}>Edit Mobile number</span>
                        </div>
                        <div className={inProgress ? classes.disabled : classes.editContainer} onClick={(e) => this.getOtp(e)}>
                            <span className={classes.noClick}>Resend OTP</span>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    renderLoginScreen = () => {
        const { inProgress, loginFailed, statusText, validationErrors } = this.props.userStore;
        const { classes } = this.props;
        const { user } = this.state;

        return (
            <React.Fragment>
                <p className={classes.loginText}>Enter your registered mobile number</p>
                <form noValidate autoComplete="off" className={classes.formStyle}>
                    <FormControl error aria-describedby="component-error-text" className={classes.formControl}>
                       
                        <TextField onFocus={(e) => this.handleFocus()} required id="mob-input" label="Mobile Number" type="tel" name="mobile" variant="outlined"
                            fullWidth autoFocus={true} InputProps={{ classes: { input: classes.textFieldInput } }}
                            value={user.mobileNo} onChange={(event) => this.handleChange("mobileNo", event.target.value)}
                        />
                        {validationErrors.mobileNo &&
                            <div className={classes.error}>
                                <ReactSVG src={Images.error} />
                                <div className={classes.errorStyle}>{validationErrors.mobileNo}</div>
                            </div>}
                        {loginFailed &&
                            <div className={classes.error}>
                                <ReactSVG src={Images.error} />
                                <div className={classes.errorStyle}>{statusText}</div>
                            </div>}
                    </FormControl>
                    {
                        inProgress ?
                            <Button variant="contained" className={classes.button} fullWidth>
                                <CircularProgress size={30} thickness={5} className={classes.loading} />
                            </Button> :
                            <Button variant="contained" className={classes.button} type="submit" onClick={this.getOtp} fullWidth>
                                Send OTP
                        </Button>
                    }
                </form>
            </React.Fragment>
        )
    }

    render() {
        const { showLogin, showLoader } = this.state;
        const { classes } = this.props;
        if (showLoader)
            return (
                <div className={classes.outerLoader}>
                    <CircularProgress size={50} thickness={2} className={classes.progress} />
                </div>
            )
        return (
            <div className="loginContainer">
                {this.renderLogo()}
                {showLogin ? this.renderLoginScreen() : this.renderOtpVerificationScreen()}
            </div>
        );
    }
}
export default withStyles(loginStyles)(withRouter(Login));
