import React, { Component } from 'react';
import './index.css';
import { Switch, Route, withRouter } from 'react-router-dom';
import Login from './Components/Auth/Login';
import PrivateRoute from './Components/PrivateRoute';

import Page from './Page';
import Home from './Components/Home/Home';
import QueryList from './Components/Queries/QueryList';
import AlternateQuery from './Components/Queries/QueryCardHelpers/AlternateQuery';
import Feedback from './Components/Feedback/Feedback';
import Preferences from './Common/Preferences';
import NewChat from './Components/Queries/Chat/NewChat';
import PolicySelection from './Components/Home/PolicySelection';
import ChatList from './Components/Queries/Chat/ChatList';
import CurrentChat from './Components/Queries/Chat/CurrentChat';
import { inject, observer } from 'mobx-react';
import CustomSnackbar from './Common/CustomSnackbar';
import CaptchaComponent from './Components/Home/CaptchaComponent';
import StatusList from './Components/Queries/StatusList';
import NoInternet from './Components/Home/NoInternet';
import FeedbackChat from './Components/Feedback/FeedbackChat';
import NSDLHome from './Components/Home/NSDLHome';

@inject('userStore', 'commonStore')
@observer
class App extends Component {




  componentWillMount() {
    if (!this.props.userStore.token) {
      this.props.commonStore.setAppLoaded();
    }
  }

  componentDidMount() {
    if (this.props.userStore.token) {
      this.props.commonStore.setAppLoaded();
    }
  }

  render() {
    return (<div className="main-container">
      <Switch>
        <Route path="/:version/login"
          render={props => (
            <Page {...props} headerText="Login" headerType="login" component={Login} />
          )} />
        <Route path="/:version/captcha"
          render={props => (
            <Page {...props} headerText="Know your PAN/TAN Status" headerType="captcha" component={CaptchaComponent} />
          )} />
        <PrivateRoute path="/:version/queries/details/:id"
          render={props => (
            <Page {...props} component={AlternateQuery} headerType="query" exact headerText="Your queries" />
          )} />
        <PrivateRoute path="/:version/queries/pending/:id"
          render={props => (
            <Page {...props} component={AlternateQuery} headerType="query" exact headerText="Update your query" />
          )} />
        <PrivateRoute path="/:version/queries/pending"
          render={props => (
            <Page {...props} headerText="Pending Queries" component={QueryList} headerType="query" exact />
          )} />
        <Route path="/:version/ackStatus"
          render={props => (
            <Page {...props} headerText="PAN/TAN Status" component={StatusList} headerType="query" exact />
          )} />
        <PrivateRoute path="/:version/queries/completed"
          render={props => (
            <Page {...props} headerText="Completed Queries" component={QueryList} headerType="query" exact />
          )} />
        <PrivateRoute path="/:version/preferences"
          render={props => (
            <Page {...props} component={Preferences} headerText="Preferences" headerType="preferences" exact />
          )} />
        <PrivateRoute path="/:version/feedback"
          render={props => (
            <Page {...props} component={FeedbackChat} headerText="Feedback" headerType="feedback" exact from="chat" />
          )} />
        <PrivateRoute path="/:version/chatFeedback"
          render={props => (
            <Page {...props} component={FeedbackChat} headerText="Feedback" headerType="feedback" exact from="chat" />
          )} />
        <PrivateRoute path="/:version/policies"
          render={props => (
            <Page {...props} component={PolicySelection} headerText="Select your policy" headerType="policy" exact />
          )} />
        <Route path="/:version/chat"
          render={props => (
            <Page {...props} component={NewChat} headerText="PAN Assist" headerType="chat" exact />
          )} />
        <PrivateRoute path="/:version/chats/:id"
          render={props => (
            <Page {...props} component={CurrentChat} headerText="PAN Assist" headerType="chat" exact />
          )} />
        <PrivateRoute path="/:version/chats"
          render={props => (
            <Page {...props} component={ChatList} headerText="Chats" headerType="chat" exact />
          )} />

        <Route path="/:version/home"
          render={props => (
            <Page {...props} component={Home} headerType="home" exact />
          )} />
        <Route path="/:version/"
          render={props => (
            <Page {...props} component={NSDLHome} headerType="nsdlHome" exact />
          )} />
        <Route path="/"
          render={props => (
            <Page {...props} component={NSDLHome} headerType="nsdlHome" exact />
          )} />

      </Switch>
      <CustomSnackbar />
    </div>
    )
  }
}

export default withRouter(App);
