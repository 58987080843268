import { observable, action } from 'mobx';
import agent from '../agent';
import userStore from './userStore';
import React, { Component } from 'react';
import queryStore from './queryStore';
import chatStore from './chatStore';

//import commonStore from './commonStore';
//import websocketStore from './websocketStore';
//import { common } from '@material-ui/core/colors';
//import { convertColorToString } from 'material-ui/utils/colorManipulator';

export class FeedbackStore {
    @observable inProgress = false;
    @observable feedbackResponse;
    @observable errors;
    @observable statusText;
    @observable openFeedbackDialog = false;

    @action sendFeedback(type, tempId, feedbackText, stars, email) {
        this.statusText = '';
        this.errors = undefined;
        this.inProgress = true;
        return agent.Feedback.sendFeedback(type, tempId, feedbackText, stars, email)
            .then(action((response) => {
                this.feedbackResponse = { type: type === 'dissatisfied' ? 'dissatisfied' : 'satisfied', message: type !== 'dissatisfied' ? <div>Thank you for your<br /> feedback!</div> : <div>Thank you for your<br /> feedback!</div> };
                if (type === 'satisfied' || (!queryStore.agentTransferAllowed && type === 'dissatisfied'))
                    this.openFeedbackDialog = true;
                    chatStore.consentForPromotions('ackStatus')
            }))
            .catch(action((err) => {
                this.errors = err.response && err.response.body && err.response.body.errors;
            }))
            .finally(action(() => {
                this.inProgress = false
            }))
    }

    @action setFeedbackDialog(value) {
        this.openFeedbackDialog = value;
    }

}

export default new FeedbackStore();
