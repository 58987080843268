/*
 ChatCard component
 url match: /dashboard
 Base component for the home page, redirects to appropriate 
 components depending on the URL/Route
*/
import { withRouter } from 'react-router-dom';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { Switch, Route } from 'react-router-dom';
import Loader from 'react-loader-spinner'
import { listStyles } from '../../../Styles/chatStyles';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { ReactSVG } from 'react-svg';
import Images from '../../../Theme/Images';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import ReplyIcon from '@material-ui/icons/Reply';
import SendIcon from '@material-ui/icons/Send';

import NoteIcon from '@material-ui/icons/Note';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Tooltip } from '@material-ui/core';
import Moment from 'moment'
@inject('userStore', 'commonStore', 'chatStore')
@observer
class ChatCard extends React.Component {

    getChatDetails = () => {
        const { item } = this.props;
        const {version} = this.props.commonStore
        this.props.chatStore.resetChat()
        this.props.commonStore.setGoBackTo('chatList')
        this.props.history.push(`/${version}/chats/${item.sessionId}`)
    }

    render() {
        const { classes, item } = this.props;
        return (
            <Card onClick={(e) => this.getChatDetails()}>
                <CardContent classes={{ root: classes.chatCardContent }}>
                    <div className="rowFlex">
                        <ReactSVG className={classes.chatIcons} src={item.isChatTransferred ? Images.agent : Images.bot} />
                        <div>
                            <span>Session #{item.sessionId}</span>
                            <Tooltip enterDelay={500} leaveDelay={200} title={Moment(item.createdAt).format('DD/MM/YYYY, h:mm a')}><div className={classes.time}><ReactSVG className={classes.icons} src={Images.snooze} /> {Moment(item.createdAt).format('DD/MM/YYYY, h:mm a')}</div></Tooltip>
                        </div>
                    </div>
                </CardContent>
            </Card>
        )
    }
}
export default withStyles(listStyles, { withTheme: true })(withRouter(ChatCard));
