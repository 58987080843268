
const uat = {
    API_ROOT: 'https://acapiuat.tin-nsdl.com/api/',
    WS_ROOT: 'https://acapiuat.tin-nsdl.com',
    captchaKey: '6LcprN8ZAAAAAImQ2Sc3AtGR4LqIma17UbhIb-aA',
    domainArray: {
        v1: 'https://www.uat.tin-nsdl.com/',
        v2: 'https://172.19.75.207/sam',
        v3: 'Offline (Standalone Software)'
    }
}


const prod = {
    API_ROOT: 'https://assistedclosureapi.tin-nsdl.com/api/',
    WS_ROOT: 'https://assistedclosureapi.tin-nsdl.com',
    captchaKey: '6LcprN8ZAAAAAImQ2Sc3AtGR4LqIma17UbhIb-aA',
    domainArray: {
        v1: 'https://www.tin-nsdl.com/',
        v2: 'https://www.onlineservices.nsdl.com/',
        v3: 'Offline (Standalone Software)'
    }
}

export default process.env.REACT_APP_STAGE === 'uat' ? uat : prod;
