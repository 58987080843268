import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { snackbarStyles } from '../Styles/commonStyles';
import { ReactSVG } from 'react-svg';
import Images from '../Theme/Images';

class PoweredBy extends Component {

    openWebsite = () => {
        const link = document.createElement('a');
        link.href = 'http://www.amygb.ai/ ';
        link.target = '_blank'
        document.body.appendChild(link);
        link.click();
    }

    render() {
        return (
            <div className='poweredBy'><span></span>
                {/*<ReactSVG  className='websiteLink' src={Images.poweredBy}  onClick={this.openWebsite}/>*/ }
             </div>

        )
    }
}
export default withStyles(snackbarStyles)(PoweredBy);
