import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { ReactSVG } from 'react-svg'
import Images from '../../Theme/Images';
import List from '@material-ui/core/List';
import { withRouter } from 'react-router-dom';
import { homeStyles } from '../../Styles/homeStyles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import ReCAPTCHA from "react-google-recaptcha";
import constants from '../../constants';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import clsx from 'clsx'
import CachedIcon from '@material-ui/icons/Cached';
import NoInternet from './NoInternet';
const options = ['PAN', 'TAN']

@inject('queryStore', 'commonStore')
@observer
class CaptchaComponent extends Component {
    state = {
        ackNumber: '',
        validationErrors: {},
        showLoader: false,
        captchaSolved: false,
        type: 'PAN',
        captchaResponse: '',
        errorMsg: '',
        captchaCode: '',
        isDisconnected: false,
        showConnectedSnackbar: false
    }

    componentDidMount() {
        const { version } = this.props.commonStore
        this.handleConnectionChange();
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
        this.props.commonStore.setChatRefresh(false)
        this.props.queryStore.getCaptcha()
        if (this.props.location.state === undefined)
            this.props.history.push(`/${version}/home`)
    }

    componentWillUnmount() {
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
    }

    handleConnectionChange = () => {
        this.setState({ showConnectedSnackbar: false })
        const condition = navigator.onLine ? 'online' : 'offline';
        if (condition === 'online') {
            const webPing = setInterval(
                () => {
                    fetch('//google.com', {
                        mode: 'no-cors',
                    })
                        .then(() => {
                            if (this.state.isDisconnected) {
                                this.setState({ showConnectedSnackbar: true })
                                let that = this;
                                setTimeout(() => {
                                    that.setState({
                                        showConnectedSnackbar: false
                                    })
                                }, 1000);
                            }
                            this.setState({ isDisconnected: false }, () => {
                                return clearInterval(webPing)
                            });
                        }).catch(() => this.setState({ isDisconnected: true }))
                }, 5000);
            return;
        }

        return this.setState({ isDisconnected: true });
    }

    closeDisconnected = () => {
        this.setState({ isDisconnected: false })
    }
    closeConnected = () => {
        this.setState({ showConnectedSnackbar: false })
    }


    submit = (e) => {
        e.preventDefault();
        const { ackNumber, type, captchaCode } = this.state
        const { version } = this.props.commonStore
        this.props.commonStore.setChatRefresh(false)
        this.props.queryStore.checkAckStatus(ackNumber, type, captchaCode)
            .then(() => {
                if (this.props.queryStore.errors) {
                    this.setState({ captchaCode: '' })
                    this.props.queryStore.getCaptcha()


                }
                else {
                    this.props.queryStore.setShowAckStatus(true);
                    this.props.history.push({
                        pathname: `/${version}/ackStatus`,
                    })
                }

            })

    }

    onCaptchaChange = (e) => {
        this.setState({ captchaSolved: true, captchaResponse: e })
    }

    setAckNumber = (value) => {
        const { type, ackNumber } = this.state
        const re = /^[0-9\b]+$/;
        if (type === 'PAN' && value.toString().length > 15) {
            if (re.test(value.toString().substring(0, 15)))
                this.setState({ ackNumber: value.toString().substring(0, 15), errorMsg: '', ackErrorMsg: '' })
            else
                this.setState({ ackErrorMsg: 'Please enter a valid 15 digit Acknowledgement number' })
            return
        }

        else if (type === 'TAN' && value.toString().length > 14) {
            if (re.test(value.toString().substring(0, 14)))
                this.setState({ ackNumber: value.toString().substring(0, 14), errorMsg: '', ackErrorMsg: '' })
            else
                this.setState({ ackErrorMsg: 'Please enter a valid 14 digit Acknowledgement number' })
            return
        }
        if (value === '' || re.test(value)) {
            this.setState({ ackNumber: value })
        }
        if (type === 'PAN' && ackNumber.length == 15)
            this.setState({ errorMsg: '', ackErrorMsg: '' })
        else if (type === 'TAN' && ackNumber.length == 14)
            this.setState({ errorMsg: '', ackErrorMsg: '' })
    }

    handleTypeChange = (value) => {
        if (value === this.state.type)
            return
        else {
            this.setState({
                type: value,
                ackNumber: '',
                errorMsg: '',
                ackErrorMsg: ''
            })
            this.props.queryStore.resetStatusText()
        }
    }

    getDisabledValue = (ackNumber, type) => {
        const { captchaCode, isDisconnected } = this.state

        if (!ackNumber || ackNumber === '' || !captchaCode || captchaCode === '' || captchaCode.length < 4) {
            return true
        }
        if (type === 'PAN' && ackNumber.length < 15)
            return true
        if (type === 'TAN' && ackNumber.length < 14)
            return true
        return false
    }

    onAckBlur = () => {
        const { type, ackNumber } = this.state
        if (type === 'PAN' && ackNumber.length > 0 && ackNumber.length < 15)
            this.setState({ ackErrorMsg: 'Please enter a valid 15 digit Acknowledgement number' })
        else if (type === 'TAN' && ackNumber.length > 0 && ackNumber.length < 14)
            this.setState({ ackErrorMsg: 'Please enter a valid 14 digit Acknowledgement number' })
        else
            this.setState({ ackErrorMsg: '' })
    }

    setCaptcha = (value) => {
        const re = /^[0-9\b]+$/;
        if (value.toString().length > 4)
            return
        if (value === '' || re.test(value)) {
            this.setState({ captchaCode: value })
        }
    }


    render() {
        const { classes } = this.props;
        const { inProgress, errors, statusText, captchaImg } = this.props.queryStore;
        const { showLoader, ackNumber, isDisconnected, type, errorMsg, captchaCode, ackErrorMsg, showConnectedSnackbar } = this.state;
        if (showLoader)
            return (
                <div className={classes.outerLoader}>
                    <CircularProgress size={50} thickness={2} className={classes.progress} />
                </div>
            )
        return (
            <form className={classes.policyContainer} onSubmit={this.submit}>


                <div>
                    <div>Please enter your Acknowledgement Number for PAN/TAN :</div>
                    <RadioGroup aria-label="Input Type"
                        name="inputType"
                        value={type} >
                        <div className={classes.radio}>
                            <div className={classes.radioContainer}>
                                {type === 'PAN' ?
                                    <div className="colCenter" >
                                        <div className={clsx({
                                            [classes.radioOption]: true,
                                            [classes.selectedRadioOption]: true,
                                        })} onClick={(e) => this.handleTypeChange('PAN')} >
                                            <div className={classes.radioSelected} >
                                                <div className={classes.selectedRadio}></div>
                                            </div>
                                        </div>

                                    </div>
                                    : <div className="colCenter"> <div onClick={(e) => this.handleTypeChange('PAN')} className={classes.radioOption}></div></div>}
                                <div className={classes.radioLabel}>PAN</div>
                            </div>
                            <div className={classes.radioContainer}>
                                {type === 'TAN' ?
                                    <div className="colCenter" >
                                        <div className={clsx({
                                            [classes.radioOption]: true,
                                            [classes.selectedRadioOption]: true,
                                        })} onClick={(e) => this.handleTypeChange('TAN')} >
                                            <div className={classes.radioSelected} >
                                                <div className={classes.selectedRadio}></div>
                                            </div>
                                        </div>

                                    </div>
                                    : <div className="colCenter"> <div onClick={(e) => this.handleTypeChange('TAN')} className={classes.radioOption}></div></div>}
                                <div className={classes.radioLabel}>TAN</div>
                            </div>
                        </div>
                    </RadioGroup>
                    <div style={{ minHeight: '10vh' }}>
                        <List className={classes.policyListContainer}>

                            <FormControl error aria-describedby="component-error-text" className={classes.formControl}>
                                <TextField autoFocus={true} onBlur={this.onAckBlur} required id="ack-input" label={"Enter valid" + (type === 'PAN' ? " 15 digit " : " 14 digit ") + "Acknowledgement Number"} name="ackNum" variant="filled"
                                    fullWidth InputProps={{ classes: { input: classes.textFieldInput } }}
                                    autoComplete='off'
                                    value={ackNumber} onChange={(event) => this.setAckNumber(event.target.value)}
                                />
                                {ackErrorMsg ? <div className={classes.ackError}>
                                    <ReactSVG src={Images.error} />
                                    <div className={classes.errorStyle}>{ackErrorMsg}</div>
                                </div> : null}

                            </FormControl>

                        </List>
                    </div>


                    <div className={classes.captchaContainer}>
                        <div className={classes.captcha}>
                            <img src={captchaImg} className={classes.captchaImg} />
                            <div className={classes.refreshIcon}><CachedIcon onClick={this.props.queryStore.getCaptcha} /></div>
                        </div>
                        <FormControl error aria-describedby="component-error-text" style={{ width: '175px', backgroundColor: 'white', display: 'flex', margin: '0 auto', borderRadius: '10px' }}>

                            <TextField onBlur={this.onAckBlur} required id="ack-input" label="Enter Captcha" name="ackNum" variant="filled"
                                InputProps={{ classes: { input: classes.textFieldInput } }} style={{ width: '175px' }}
                                value={captchaCode} onChange={(event) => this.setCaptcha(event.target.value)}
                                autoComplete='off'
                            />
                            {errorMsg ? <div className={classes.ackError}>
                                <ReactSVG src={Images.error} />
                                <div className={classes.errorStyle}>{errorMsg}</div>
                            </div> : null}

                        </FormControl>
                    </div>

                    {errors ? <div className={classes.error}>
                        <ReactSVG src={Images.error} />
                        <div className={classes.errorStyle}>{statusText}</div>
                    </div> : null}

                </div>
                {
                    inProgress ?
                        <Button fullWidth variant="contained" className={classes.policyButton} >
                            <CircularProgress size={22} thickness={5} className={classes.loading} />
                        </Button> :
                        <Button fullWidth disabled={this.getDisabledValue(ackNumber, type)} variant="contained" className={classes.policyButton} type="submit" style={{ width: '100%' }} >
                            Submit
                        </Button>
                }

            </form>
        )
    }
}
CaptchaComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(homeStyles, { withTheme: true })(withRouter(CaptchaComponent));

