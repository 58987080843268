import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { snackbarStyles } from '../Styles/commonStyles';
import { inject, observer } from 'mobx-react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

@inject('commonStore')
@observer
class CustomSnackbar extends Component {
    state = {
        vertical: 'top',
        horizontal: 'right',
    }

    handleCloseSnackBar = () => {
        this.props.commonStore.handleCloseSnackBar();
    }



    render() {
        const { classes } = this.props;
        const { open, message, type } = this.props.commonStore.snackbarOptions;
        const { vertical, horizontal } = this.state;
        return (
            <div>
                <Snackbar open={open}
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical, horizontal }}
                    key={`${vertical},${horizontal}`}
                    onClose={this.handleCloseSnackBar}>
                    <Alert severity={type} className={classes[`${type}`]} onClose={this.handleCloseSnackBar} >{message}</Alert>
                </Snackbar>
            </div>

        )
    }
}
CustomSnackbar.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(snackbarStyles)(CustomSnackbar);
