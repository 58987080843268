/*
 BooleanInput component
 url match: /dashboard
 Base component for the home page, redirects to appropriate 
 components depending on the URL/Route
*/
import { withRouter } from 'react-router-dom';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { messageStyles } from '../../../Styles/chatStyles';
import clsx from 'clsx';
import Moment from 'moment';

@inject('commonStore', 'chatStore')
@observer
class BooleanInput extends React.Component {
    replyToChat = (value) => {
        this.props.chatStore.sendMessage([], value)
        this.props.focusInput()
    }

    render() {
        const { classes, row , theme} = this.props;
        return (
            <div className={clsx({ [classes.textMessage]: true})}>
                <span> {row.webText}</span>
                <div className={classes.feedback}>
                    {row.selectOptions && row.selectOptions.map((option, i) => (
                        <div key={'boolean' + i} className={i == 0 ? classes.booleanOption : `${classes.booleanOption} ${classes.negBooleanOption}`} onClick={(e) => this.replyToChat(option && this.props.commonStore.modifyText(option))} >
                            {option  && this.props.commonStore.modifyText(option)}
                        </div>
                    ))}
                </div>
                <div className={clsx({ [classes.timeStamp]: true, [classes.agTimeStamp]: row.messageBy === 'CUSTOMER' })} >
                    <div> {Moment(row.createdAt).format('h:mm a')}</div>
                </div>

            </div>
        )
    }
}
export default withStyles(messageStyles, { withTheme: true })(withRouter(BooleanInput));
