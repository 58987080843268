import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { queryListStyles } from '../../Styles/queryStyles';
import { inject, observer } from 'mobx-react';
import FeedbackDetails from './FeedbackDetails';
import { withRouter } from 'react-router-dom';
import Rating from '@material-ui/lab/Rating';
import { feedbackStyles } from '../../Styles/feedbackStyles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import FeedbackDialog from './FeedbackDialog';
import { ReactSVG } from 'react-svg';
import Images from '../../Theme/Images';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import clsx from 'clsx'
import ChatFeedbackDialog from '../Queries/Chat/ChatFeedbackDialog';
import PoweredBy from '../../Common/PoweredBy';
import FeedbackRating from './FeedbackRating';
import Box from '@material-ui/core/Box';
const labels = {
    1: 'Needs improvement',
    2: 'Average',
    3: 'Good',
    4: 'Very Good',
    5: 'Excellent',
};

const rating_labels = ["Needs improvement", "Average", "Good", "Very Good", "Excellent"];
const emailRegex = /^[a-z0-9](\.?[a-z0-9_-]){0,}@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{2,6}$/;
const dissatisfiedOptions = [
    'Incorrect response received',
    'Partial/Incomplete response received',
    'Other'
]
@inject('feedbackStore', 'queryStore', 'commonStore', 'chatStore')
@observer
class Feedback extends Component {

    state = {
        rating: -1,
        ratingText: '',
        errorMessage: '',
        feedbackText: '',
        email: '',
        validationErrors: [],
        hover: -1,
        feedbackType: 'satisfied',
        mobile: ''
    }

    componentDidMount() {
        const { version } = this.props.commonStore
        this.props.commonStore.setChatRefresh(false)
        this.props.commonStore.setGoBackTo('ackStatus')
        if (!this.props.queryStore.showFeedback)
            this.props.history.push({
                pathname: `/${version}/home`,
            })
    }

    setRating = (value) => {
        this.setState({
            rating: value,
            ratingText: rating_labels[value - 1],
            feedbackType: value > 2 ? 'satisfied' : 'dissatisfied'
        })
    }

    setHover = (event, value) => {
        this.setState({
            hover: value,
        })
    }

    resetRatingError = () => {
        const { validationErrors } = this.state;
        delete validationErrors['rating']
        this.setState({ validationErrors });
    }

    sendFeedback = (tempId, type) => {
        const { version } = this.props.commonStore
        const { rating, feedbackText, email, note, validationErrors, feedbackType, mobile } = this.state;
        let valid = true
        if (rating <= 0) {
            validationErrors['rating'] = 'Please provide a suitable rating before proceeding.'
            this.setState({ validationErrors });
            valid = false;
        }
        else
            delete validationErrors['rating']

        if (!feedbackText.trim().length && feedbackType === 'dissatisfied') {
            validationErrors['feedbackText'] = 'Please select an appropriate feedback option before you submit.'
            this.setState({ validationErrors });
            valid = false;
        }
        else
            delete validationErrors['feedbackText']
        if (feedbackText && feedbackText === 'Other') {
            if (!note || !note.trim().length) {
                validationErrors['feedbackText'] = 'Please provide a suitable feedback proceeding.'
                this.setState({ validationErrors });
                valid = false;
            }

        }
        if (!emailRegex.test(email)) {
            validationErrors['email'] = 'Please enter a valid Email ID before proceeding.'
            this.setState({ validationErrors });
            valid = false;
        }
        else
            delete validationErrors['email']
        if (!mobile || (mobile && mobile.length < 10)) {
            validationErrors['mobile'] = 'Please enter a valid mobile number before proceeding.'
            this.setState({ validationErrors });
            valid = false;
        }
        else
            delete validationErrors['mobile']
        if (valid) {
            this.setState({
                validationErrors: {}
            });
        }
        else
            return

        this.setState({
            errorMessage: ''
        })
        if (this.props.location.appState && this.props.location.appState.from === 'ackStatus') {
            this.props.feedbackStore.sendFeedback(feedbackType, tempId, feedbackText === 'Other' ? note : feedbackText, rating, email)
                .then(() => {
                    if (feedbackType === 'dissatisfied' && this.props.queryStore.agentTransferAllowed) {
                        this.props.history.push({
                            pathname: `/${version}/chat`,
                            appState: { from: 'ticket' }
                        })
                    }
                    else
                        this.props.queryStore.setShowFeedback(false);
                })

        }
        else {
            this.props.chatStore.endChat(feedbackType, feedbackText === 'Other' ? note : feedbackText, rating, email, mobile)
                .then(() => {

                    this.props.queryStore.setShowFeedback(false);
                })
        }

    }

    render() {
        const { classes } = this.props;
        const { ticketDetails } = this.props.queryStore
        const { inProgress } = this.props.feedbackStore
        const { rating, ratingText, errorMessage, feedbackText, email, note, validationErrors, hover, feedbackType, mobile } = this.state
        const { chatData } = this.props.chatStore
        return (
            <div className={classes.feedbackContainer}>

                <div className={classes.feedbackInnerContainer}>
                    <p className={classes.feedbackInfo}>Your ticket ID {(this.props.location.appState && this.props.location.appState.from === 'ackStatus') ? ticketDetails.ticketId : chatData && chatData.sessionId} has been closed</p>
                    {this.props.location.appState && this.props.location.appState.from && this.props.location.appState.from === 'ackStatus' ? <FeedbackDetails /> : null}
                    <div >
                        <div className={classes.feedbackInfo}>
                            <FeedbackRating handleRatingChange={this.setRating} from='ack' resetRatingError={this.resetRatingError} />
                            {validationErrors && validationErrors.rating ?
                                <div className={classes.validationError}>
                                    <ReactSVG src={Images.error} />
                                    <div className={classes.errorStyle}>{validationErrors.rating}</div>
                                </div> : null
                            }
                            <div style={{ padding: '2vw 0vw', paddingTop: '0vh' }}>
                                {/*this.props.location.appState && this.props.location.appState.type === 'dissatisfied' ? null : <TextField
                                    value={feedbackText}
                                    required={this.props.location.appState && this.props.location.appState.type === 'dissatisfied' ? true : false}
                                    onChange={(e) => this.setState({ feedbackText: e.target.value })} className={classes.feedbackText} id="standard-basic" label="Write a note" fullWidth />*/}

                                <TextField autoComplete='off' value={email} onChange={(e) => this.setState({ email: e.target.value })} className={classes.feedbackText} id="standard-basic" required={true} label="Email ID" fullWidth />
                                {validationErrors && validationErrors.email ?
                                    <div className={classes.validationError}>
                                        <ReactSVG src={Images.error} />
                                        <div className={classes.errorStyle}>{validationErrors.email}</div>
                                    </div> : null
                                }
                                <TextField autoComplete='off' value={mobile} type='number' onChange={(e) => this.setState({ mobile: e.target.value })} className={classes.feedbackText} id="standard-basic" required={true} label="Mobile No." fullWidth />

                                {validationErrors && validationErrors.mobile ?
                                    <div className={classes.validationError}>
                                        <ReactSVG src={Images.error} />
                                        <div className={classes.errorStyle}>{validationErrors.mobile}</div>
                                    </div> : null
                                }
                                {feedbackType === 'dissatisfied' ?
                                    <div className={classes.radioContainer}>
                                        <div className={classes.dOptionsText}>Please select an appropriate feedback option:</div>
                                        {
                                            dissatisfiedOptions.map((row, index) => (
                                                <div key={'dis' + index} className={classes.feedbackOption}>
                                                    {feedbackText === row ?
                                                        <div className="colCenter" >
                                                            <div className={clsx({
                                                                [classes.radioOption]: true,
                                                                [classes.selectedRadioOption]: true,
                                                            })} onClick={(e) => this.setState({ feedbackText: row })} >
                                                                <div className={classes.radioSelected} >
                                                                    <div className={classes.selectedRadio}></div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        : <div className="colCenter"> <div onClick={(e) => this.setState({ feedbackText: row })} className={classes.radioOption}></div></div>}&nbsp;
                                                    <div onClick={(e) => this.setState({ feedbackText: row })} className={classes.radioLabel}>{row}</div>
                                                </div>
                                            ))
                                        }

                                    </div>
                                    : null

                                }
                                {feedbackType === 'dissatisfied' && feedbackText === 'Other' ?
                                    <TextField autoComplete='off' style={{ margin: '0vh 0vw 1vh 0vw' }} value={note} onChange={(e) => this.setState({ note: e.target.value })} className={classes.feedbackText} id="standard-basic" required={true} label="Provide feedback below" fullWidth /> : null
                                }
                                {validationErrors && validationErrors.feedbackText ?
                                    <div className={classes.validationError}>
                                        <ReactSVG src={Images.error} />
                                        <div className={classes.errorStyle}>{validationErrors.feedbackText}</div>
                                    </div> : null
                                }


                                {errorMessage && errorMessage.length ?
                                    <div className={classes.error}>
                                        <ReactSVG src={Images.error} />
                                        <div className={classes.errorStyle}>{errorMessage}</div>
                                    </div> : null
                                }

                            </div>
                        </div>
                    </div>


                </div>
                <div className={classes.feedbackSubmitContainer}>
                    {
                        inProgress ?
                            <Button variant="contained" className={classes.button} fullWidth style={{ width: '100%' }}>
                                <CircularProgress size={30} thickness={5} className={classes.loading} color="white" />
                            </Button> :
                            <Button variant="contained" className={classes.button} type="submit" onClick={(event) => this.sendFeedback()} fullWidth style={{ width: '100%' }} >
                                Submit Response
                            </Button>
                    }
                    <PoweredBy />
                </div>
                {this.props.feedbackStore.openFeedbackDialog ? <FeedbackDialog /> : null}
                {this.props.chatStore.cFeedbackDialog ? <ChatFeedbackDialog feedback={feedbackType} /> : null}
            </div>
        )
    }
}



Feedback.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withRouter(withStyles(feedbackStyles)(Feedback));
