/*
 NewChat component
 url match: /dashboard
 Base component for the home page, redirects to appropriate
 components depending on the URL/Route
*/
import { withRouter } from 'react-router-dom';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Loader from 'react-loader-spinner'
import { messageStyles } from '../../../Styles/chatStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import clsx from 'clsx';
import MessageList from './MessageList';


let page = 1;
@inject('chatStore', 'userStore', 'commonStore')
@observer
class NewChat extends React.Component {

    componentDidMount() {
        const { version } = this.props.commonStore
        if (!this.props.userStore.customerData.value) {
            this.props.history.push(`/${version}`)
            return
        }
        this.props.commonStore.setActiveHeader('Chat')
        this.props.commonStore.setChatRefresh(true)
        if (this.props.location.appState && this.props.location.appState.from && this.props.location.appState.from === 'ticket') {
            this.props.chatStore.initiateTicketChat()
        }
        else {
            if (window.local_Storage.getItem('sessionId') && window.local_Storage.getItem('sessionId') !== undefined) {
                this.props.chatStore.getAllMessages(window.local_Storage.getItem('sessionId'), 0, 0, 'UP')
            }

            else if (this.props.userStore.authenticated) {
                this.props.chatStore.initiateChat(this.props.location.state && this.props.location.state.intent, this.props.location.state && this.props.location.state.queryText, this.props.userStore.customerData)
            }
            else if (this.props.location.state) {

                this.props.chatStore.initiateChat(
                    this.props.location.state.intent,
                    this.props.location.state.queryText,
                    this.props.location.state.slug,
                    this.props.location.state.category,
                    this.props.userStore.customerData)


            }
            else {
                this.props.chatStore.initiateChat()
            }

        }

    }

    scrollToBottom = () => {
        var elem = document.getElementById('myscrollingChat');
        if (elem != null)
            elem.scrollTop = elem.scrollHeight - elem.offsetHeight;
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={clsx({ ["colFlex"]: true })} style={{ flexGrow: '2', height: '100%' }} >
                <div className={clsx({ [classes.detailsContainer]: true, [classes.fullHeight]: true })}>
                    <Card className={clsx({
                        [classes.ticketDetail]: true,
                        [classes.fullHeight]: true
                    })}>
                        <CardContent
                            className={clsx({
                                [classes.cardContent]: true,
                            })}>
                            <MessageList from="newChat" />
                        </CardContent>
                    </Card>
                </div>



            </div>
        )

    }
}
export default withStyles(messageStyles, { withTheme: true })(withRouter(NewChat));
