import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { ReactSVG } from 'react-svg'
import Images from '../../Theme/Images';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { NavLink } from 'react-router-dom';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { withRouter } from 'react-router-dom';
import { homeStyles } from '../../Styles/homeStyles';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
@inject('queryStore', 'commonStore')
@observer
class PolicySelection extends Component {
    state = {
        selectedPolicy: {}
    }

    componentDidMount() {
        if (this.props.location.state === undefined)
            this.props.history.push('/home')

    }

    proceed = () => {
        const { selectedPolicy } = this.state;
        if (this.props.location.state && this.props.location.state.from === 'update') {
            this.props.queryStore.updateQuery(this.props.location.state && this.props.location.state.input, this.props.location.state && this.props.location.state.text,
                this.props.location.state && this.props.location.state.tempId, selectedPolicy.policyNo)
                .then(() => {
                    if (!this.props.queryStore.inProgress) {
                        this.props.commonStore.setHeaderText('Pending Queries')
                        this.props.commonStore.setGoBackTo('home')
                        this.props.history.push({
                            pathname: '/queries/pending',
                            state: { type: "pending" }
                        })
                    }
                })
        }
        else {
            this.props.queryStore.askQuery(this.props.location.state && this.props.location.state.input, this.props.location.state && this.props.location.state.text, selectedPolicy.policyNo)
                .then(() => {
                    if (!this.props.queryStore.inProgress) {
                        this.props.commonStore.setHeaderText('Pending Queries')
                        this.props.commonStore.setGoBackTo('home')
                        this.props.history.push({
                            pathname: '/queries/pending',
                            state: { type: "pending" }
                        })
                    }
                })
        }
    }

    selectPolicy = (item) => {
        this.setState({
            selectedPolicy: item
        })
    }


    renderPolicyList = () => {
        const { policyList } = this.props.queryStore;
        const { classes, theme } = this.props;
        const { selectedPolicy } = this.state;
        return (
            policyList.map((item, index) => (
                <ListItem  style={{ paddingTop: '1.5vh', paddingBottom: '1.5vh', }} classes={{ divider: index + 1 === policyList.length ? classes.noBorder : 'lineDivider' }} button key={item.text} divider={true} onClick={(e) => this.selectPolicy(item)}>
                    <div className={classes.listItems} onClick={(e) => this.selectPolicy(item)}>
                        <span>Policy number: {item.policyNo} </span>
                        <ListItemSecondaryAction>
                            <CheckIcon key={selectedPolicy} style={{ color: selectedPolicy == item ? theme.palette.orange : theme.palette.silver }} />

                        </ListItemSecondaryAction>
                    </div>
                </ListItem>
            ))
        )

    }

    render() {
        const { classes } = this.props;
        const { inProgress } = this.props.queryStore;
        const { selectedPolicy } = this.state;
        return (
            <div className={classes.policyContainer}>
                <div>
                    <div>We found multiple policies associated with your account, which one are you looking for?</div>
                    <List className={classes.policyListContainer}>
                        {this.renderPolicyList()}
                    </List>
                </div>


                {
                    inProgress ?
                        <Button fullWidth variant="contained" className={classes.button} style={{width: '100%'}} >
                            <CircularProgress size={18} thickness={5} className={classes.loading} />
                        </Button> :
                        <Button fullWidth disabled={!selectedPolicy._id} variant="contained" className={classes.policyButton} type="submit" onClick={this.proceed} style={{ width: '100%' }} >
                            Proceed
                        </Button>
                }

            </div>
        )
    }
}
PolicySelection.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(homeStyles, { withTheme: true })(withRouter(PolicySelection));
