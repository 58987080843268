import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';


export function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function getPageLoadingDom() {
  return (
    <div className="loading-container">
      <CircularProgress size={150} thickness={2} style={{ color: '#0f7fd3' }} />
    </div>
  );
}