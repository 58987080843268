import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import userStore from './mobx-stores/userStore';
import commonStore from './mobx-stores/commonStore';
import constants from './constants';

const superagent = superagentPromise(_superagent, global.Promise);
const emailRegex = /^[A-Za-z0-9](\.?[A-Za-z0-9_-]){0,}@[A-Za-z0-9-]+\.([A-Za-z]{1,6}\.)?[A-Za-z]{2,6}$/;

const API_ROOT = constants.API_ROOT;

const encode = encodeURIComponent;

const handleErrors = (err, res) => {

  if (err && err.response && err.response.status === 401) {
    if (res && res.body && res.body.type && res.body.type === 'INVALID_OTP')
      return
    else
      userStore.goToLogin();
  }
  return err;
};

const handleChatErrors = (err, res) => {

  if (err && err.response && err.response.status === 401) {
    return '401'
  }
  //return err;
};

const errorHandler = (err, res) => {
  if (err && err.response && err.response.status === 401) {
    if (res && res.body && res.body.type && res.body.type === 'INVALID_OTP')
      return
    else
      userStore.goToLogin();
  }
  let error = {
    error: err,
    response: res
  }
  return error;
};

const responseBody = res => res.body;
const responseFull = res => res;

const tokenPlugin = req => {
  if (userStore.token) {
    req.set('Authorization', `Bearer ${userStore.token}`);
  }
};

const requestsReports = {
  get: url =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
};

const requests = {
  del: url =>
    superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  get: url =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  getChat: url =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .end(handleChatErrors)
      .then(responseBody),
  getFull: url =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseFull),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  postDocument: (url, tempId, stepNumber, imageFront) =>
    superagent
      .post(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .field('tempId', JSON.stringify(tempId))
      .field('stepNumber', JSON.stringify(stepNumber))
      .accept('application/json')
      .attach('imageFront', imageFront)
      .end(handleErrors)
      .then(responseBody),
  postDocuments: (url, tempId, stepNumber, imageFront, imageBack) =>
    superagent
      .post(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .field('tempId', JSON.stringify(tempId))
      .field('stepNumber', JSON.stringify(stepNumber))
      .accept('application/json')
      .attach('imageFront', imageFront)
      .attach('imageBack', imageBack)
      .end(handleErrors)
      .then(responseBody),

  postAttachments: (url, files, messageBy, sessionId, messageText) =>
    superagent
      .post(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .accept('application/json')
      .attach('messageAttachment', files[0])
      .attach('messageAttachment', files[1])
      .attach('messageAttachment', files[2])
      .attach('messageAttachment', files[3])
      .attach('messageAttachment', files[4])
      .attach('messageAttachment', files[5])
      .attach('messageAttachment', files[6])
      .attach('messageAttachment', files[7])
      .attach('messageAttachment', files[8])
      .attach('messageAttachment', files[9])
      .attach('messageAttachment', files[10])
      .field('messageText', messageText)
      .field('messageBy', messageBy)
      .field('sessionId', sessionId)
      .end(handleErrors)
      .then(responseBody),
  postAttachmentsWithoutCom: (url, files, messageBy, sessionId) =>
    superagent
      .post(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .accept('application/json')
      .attach('messageAttachment', files[0])
      .attach('messageAttachment', files[1])
      .attach('messageAttachment', files[2])
      .attach('messageAttachment', files[3])
      .attach('messageAttachment', files[4])
      .attach('messageAttachment', files[5])
      .attach('messageAttachment', files[6])
      .attach('messageAttachment', files[7])
      .attach('messageAttachment', files[8])
      .attach('messageAttachment', files[9])
      .attach('messageAttachment', files[10])
      .field('messageBy', messageBy)
      .field('sessionId', sessionId)
      .end(handleErrors)
      .then(responseBody),
  ticketReply: (url, files, message, tempId) =>
    superagent
      .post(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .accept('application/json')
      .attach('files', files[0])
      .attach('files', files[1])
      .attach('files', files[2])
      .attach('files', files[3])
      .attach('files', files[4])
      .attach('files', files[5])
      .attach('files', files[6])
      .attach('files', files[7])
      .attach('files', files[8])
      .attach('files', files[9])
      .attach('files', files[10])
      .field('message', message)
      .field('tempId', tempId)
      .end(handleErrors)
      .then(responseBody),
  ticketReplyWithoutCom: (url, files, tempId) =>
    superagent
      .post(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .accept('application/json')
      .attach('files', files[0])
      .attach('files', files[1])
      .attach('files', files[2])
      .attach('files', files[3])
      .attach('files', files[4])
      .attach('files', files[5])
      .attach('files', files[6])
      .attach('files', files[7])
      .attach('files', files[8])
      .attach('files', files[9])
      .attach('files', files[10])
      .field('tempId', tempId)
      .end(handleErrors)
      .then(responseBody),

};

const Auth = {
  getOtp: (mobileNo) =>
    requests.post('customer/sendOTP', { mobileNo: mobileNo }),
  verifyOTP: (mobileNo, otp) =>
    requests.post('customer/verifyOTP', { mobileNo: mobileNo, OTP: otp }),
  getStatusCount: () =>
    requests.get(`customer/statusCount`),
};

const Home = {
  getFaqs: (tempId) => {
    /*if (userStore.authenticated) {
      return requests.get(`getHomePageData` +
        (tempId !== undefined ? `?tempId=${tempId}` : '')
      )
    }
    else {*/
    return requests.get(`getHomePageDataAnonymous`)
    /* }*/
  }
  ,
  getAutoSuggestText: (text) => {
    /*if (userStore.authenticated)
      return requests.get(`autoSuggest/${encodeURIComponent(text)}`)
    else*/
    return requests.get(`autoSuggestAnonymous/${encodeURIComponent(text)}`)
  }
}

const Query = {
  askQueries: (data, searchText, policyNo, actionType) => {
    /*if (userStore.authenticated)
      return requests.post(`query/search/${searchText}`, { filteredIntentsArray, policyNo })
    else*/
    const { customerData } = userStore
    if (customerData.type === 'customerEmailId')
      data.customerEmailId = customerData.value
    else
      data.customerMobileNo = customerData.value
    if (commonStore.version === 'v4')
      data.domainURL = document.referrer
    else
      data.domainURL = constants.domainArray[`${commonStore.version}`]
    return requests.post(`query/searchAnonymous/${encodeURIComponent(searchText)}`, data)
  }
  ,
  updateQuery: (filteredIntentsArray, searchText, tempId, policyNo) =>
    requests.post(`query/update/${searchText}`, { filteredIntentsArray, tempId, policyNo }),
  getQueries: (type, pageNo) => {
    if (type === 'pending')
      return requests.get(`query/getPendingQuery?pageNo=${pageNo}`)
    else
      return requests.get(`query/getCompletedQuery?pageNo=${pageNo}`)
  },
  downloadPolicy: (fileName) =>
    requests.get(`policy/download/${fileName}`),
  uploadDocuments: (tempId, stepNumber, imageFront, imageBack) => {
    if (imageBack && imageBack.name !== undefined)
      return requests.postDocuments(`uploadDocument`, tempId, stepNumber, imageFront, imageBack)
    else
      return requests.postDocument(`uploadDocument`, tempId, stepNumber, imageFront)
  },
  getQueryLog: (tempId) =>
    requests.get(`query/getQueryLog?tempId=${tempId}`),
  respondToTicket: (files, message, tempId) => {
    if (message !== undefined)
      return requests.ticketReply(`customer/queryResponse`, files, message, tempId)
    else
      return requests.ticketReplyWithoutCom(`customer/queryResponse`, files, tempId)
  },
  checkAckStatus: (ackNo, type, randomNumber, uniqueId, customerData) => {
    let data = { ackNo, type, randomNumber, uniqueId }
    if (commonStore.version === 'v4')
      data.domainURL = document.referrer
    else
      data.domainURL = constants.domainArray[`${commonStore.version}`]
    if (customerData.type === 'customerEmailId')
      data.customerEmailId = customerData.value
    else
      data.customerMobileNo = customerData.value
    return requests.post(`query/checkACKStatus`, data)
  },
  consentForPromotions: (promotionalConsent) => {
    return requests.post(`chat/promotionalConsentQuery`, { promotionalConsent: promotionalConsent })
  },

  getCaptcha: () =>
    requests.post(`generateCaptcha`),

}

const Feedback = {
  sendFeedback: (feedbackType, tempId, feedbackText, stars, customerEmailId) => {
    if (feedbackType === 'dissatisfied')
      return requests.post(`queryFeedback`, { feedbackText, stars, customerEmailId, feedbackStatus: feedbackType.toUpperCase() })
    else {
      if (feedbackText === '')
        return requests.post(`queryFeedback`, { stars, feedbackStatus: feedbackType.toUpperCase() })
      else
        return requests.post(`queryFeedback`, { feedbackText, stars, feedbackStatus: feedbackType.toUpperCase() })
    }
  }
}

const Chat = {
  initiateChat: (intent, queryText, slug, category, customerData) => {
    /* if (userStore.authenticated)
       return requests.post(`chat/initiate`, { initiatedVia: 'WEB', intent, queryText, slug, category })
     else
     */
    let data = {
      initiatedVia: 'WEB',
      intent,
      queryText,
      slug,
      category,
    }
    if (commonStore.version === 'v4')
      data.domainURL = document.referrer
    else
      data.domainURL = constants.domainArray[`${commonStore.version}`]
    if (customerData.type === 'customerEmailId')
      data.customerEmailId = customerData.value
    else
      data.customerMobileNo = customerData.value
    return requests.post(`chat/initiateChatAnonymous`, data)
  },
  initiateTicketChat: () => {
    /* if (userStore.authenticated)
       return requests.post(`chat/initiate`, { initiatedVia: 'WEB', intent, queryText, slug, category })
     else
     */
    return requests.post(`chat/initiateViaTicket`)
  },
  reply: (messageText, files, messageBy, sessionId) => {
    if (messageText == undefined)
      return requests.postAttachmentsWithoutCom('chat/sendMsgAnonymous', files, messageBy, sessionId)
    else
      return requests.postAttachments('chat/sendMsgAnonymous', files, messageBy, sessionId, messageText)
  },
  getAllMessages: (sessionId, pageNo, skip, direction) => {
    /* if (userStore.authenticated)
       return requests.get(`chat/getAllMsgCustomer?sessionId=${sessionId}&limit=20&skip=${skip}&direction=${direction}`)
     else*/
    return requests.get(`chat/getAllMsgAnonymous?sessionId=${sessionId}&limit=20&skip=${skip}&direction=${direction}`)
  },
  getChatList: (pageNo) => {
    return requests.get(`chat/getCustomerChatList?pageNo=${pageNo}&limit=15`)
  },
  indicateTyping: (sessionId) => {
    /* if (userStore.authenticated)
       return requests.post(`chat/indicateTypingCustomer`, { typingBy: 'CUSTOMER', sessionId })
     else*/
    return requests.post(`chat/indicateTypingAnonymous`, { typingBy: 'CUSTOMER', sessionId })
  },
  getAutoSuggestListAnonymous: (keywords) => {
    return requests.get(`autoSuggestListAnonymous/${encodeURIComponent(keywords)}`,)
  },
  endChat: (feedbackType, feedbackText, stars, customerEmailId, customerMobileNo) => {
    let obj = { stars, feedbackStatus: feedbackType }
    if (feedbackText != '')
      obj.feedbackText = feedbackText
    return requests.post(`chat/endChatAnonymous`, obj)
  },
  consentForPromotions: (promotionalConsent) => {
    return requests.post(`chat/promotionalConsentAnonymous`, { promotionalConsent: promotionalConsent })
  },

}

//allStepsData.step_2.apiURL


export default {
  Auth,
  Home,
  Query,
  Feedback,
  Chat
};
