import { observable, action } from 'mobx';
import agent from '../agent';
import commonStore from './commonStore';
import websocketStore from "./websocketStore";
import userStore from './userStore';
import * as ReactDOM from 'react-dom';
import feedbackStore from './feedbackStore';
try {
    if (window.localStorage) {
        window.local_Storage = window.localStorage;
    }
} catch (e) {
    window.local_Storage = {
        _data: {},
        setItem: function (id, val) { return this._data[id] = String(val); },
        getItem: function (id) { return this._data.hasOwnProperty(id) ? this._data[id] : undefined; },
        removeItem: function (id) { return delete this._data[id]; },
        clear: function () { return this._data = {}; }
    };
}
export class ChatStore {

    @observable inProgress = false;
    @observable createProgress = false;
    @observable chatData = { messages: [] };
    @observable sessionId = undefined;
    @observable replyProgress = false;
    @observable chatList = [];
    @observable totalChat = [];
    @observable typing = false;
    @observable scrollAtBottom = true
    @observable topMessage = null
    @observable activeIndex = -1
    @observable openAttachmentPreview = false;
    @observable endProgress = false;
    @observable consentProgress = false;
    @observable cFeedbackDialog = false
    @observable suggestionList = []
    @observable consent = ''
    constructor() {
        let sessionId = window.local_Storage.getItem('sessionId');
        if (sessionId) {
            this.sessionId = sessionId
        }
    }

    @action resetSessionId() {
        this.sessionId = undefined;
    }

    @action setConsent(value) {
        this.consent = value
    }

    @action setFeedbackDialog(value) {
        this.cFeedbackDialog = value
    }

    @action setScroll(value) {
        this.scrollAtBottom = value
    }

    @action setTopMessage(value) {
        this.topMessage = value
    }

    @action resetChat() {
        this.chatData = { messages: [] };
    }

    @action openMessageDrawer(value, index) {
        this.chatData.messages[index].openMessageDrawer = value
    }

    @action setAttachmentPreview(value) {
        this.openAttachmentPreview = value
    }

    @action initiateChat(intent, queryText, slug, category, customerData) {
        this.inProgress = true;
        userStore.resetAnonymousId()
        return agent.Chat.initiateChat(intent, queryText, slug, category, customerData)
            .then(action((response) => {
                if (response.statusCode == '200') {
                    if (response.accessToken) {
                        userStore.setToken(response.accessToken)
                        window.local_Storage.setItem('anonymousToken', response.accessToken);
                    }
                    this.chatData = response.data.chatData;
                    this.sessionId = response.data.chatData.sessionId;
                    window.local_Storage.setItem('anonymousId', response.data.chatData.uniqueClientId);
                    userStore.setAnonymousId(response.data.chatData.uniqueClientId)
                    window.local_Storage.setItem('sessionId', this.sessionId);
                    // if (!userStore.authenticated && websocketStore.socketHandle)
                    websocketStore.registerAnonymous()
                    // else
                    //  websocketStore.connect()*/
                    // websocketStore.registerWithToken()
                }
            }))
            .catch(action((err) => {

            }))
            .finally(action(() => {
                this.inProgress = false;
            }))
    }

    @action initiateTicketChat() {
        this.inProgress = true;
        userStore.resetAnonymousId()
        return agent.Chat.initiateTicketChat()
            .then(action((response) => {
                if (response.statusCode == '200') {
                    if (response.data) {
                        userStore.setToken(response.data.accessToken)
                        window.local_Storage.setItem('anonymousToken', response.data.accessToken);
                    }
                    this.chatData = response.data.chatData;
                    this.sessionId = response.data.chatData.sessionId;
                    window.local_Storage.setItem('anonymousId', response.data.chatData.uniqueClientId);
                    userStore.setAnonymousId(response.data.chatData.uniqueClientId)
                    window.local_Storage.setItem('sessionId', this.sessionId);
                    // if (!userStore.authenticated && websocketStore.socketHandle)
                    websocketStore.registerAnonymous()
                    // else
                    //  websocketStore.connect()*/
                    // websocketStore.registerWithToken()
                }
            }))
            .catch(action((err) => {

            }))
            .finally(action(() => {
                this.inProgress = false;
            }))
    }

    @action setupChat(data) {
        this.chatData = data.chatData;
        this.sessionId = data.chatData.sessionId;
        window.local_Storage.setItem('sessionId', this.sessionId);
        window.local_Storage.setItem('anonymousId', data.chatData.uniqueClientId);
        userStore.setAnonymousId(data.chatData.uniqueClientId)
        if (!userStore.authenticated && websocketStore.socketHandle)
            websocketStore.registerAnonymous()
        else
            websocketStore.connect()
    }

    @action indicateTyping(sessionId) {
        if (this.chatData.isChatTransferred)
            return agent.Chat.indicateTyping(sessionId)
                .then(action(() => {

                }))
                .catch(action((err) => {
                }))
                .finally(action(() => {
                }))
    }

    @action getAutoSuggestList(keyword) {
        //  this.suggestionList = [];
        return agent.Chat.getAutoSuggestListAnonymous(keyword)
            .then(action((response) => {
                if (response.statusCode == '200') {
                    setTimeout(() => {
                        this.setSuggestionList(response.data)
                    }, 500);
                }
            }))
            .catch(action((err) => {
            }))
            .finally(action(() => {
            }))
    }

    @action setSuggestionList(data) {
        this.suggestionList = data
    }

    @action agentTyping(value) {
        this.typing = value;
        setTimeout(() => {
            this.resetTyping()
        }, 4000);

    }
    @action resetTyping() {
        this.typing = false;
    }

    @action sendMessage(messageAttachment, messageText) {
        // this.suggestionList = []
        this.replyProgress = true;
        this.scrollAtBottom = false
        this.topMessage = null
        return agent.Chat.reply(messageText, messageAttachment, 'CUSTOMER', this.chatData.sessionId)
            .then(action((response) => {
                if (response.data && response.data.chatData && response.data.chatData.messages && response.data.chatData.messages.length) {
                    let msgIndex = response.data.chatData.messages.findIndex(x => x.messageBy === 'CUSTOMER')
                    if (msgIndex > -1)
                        this.chatData.messages.push(response.data.chatData.messages[msgIndex])
                }
                this.refreshChat()
                this.scrollAtBottom = false
            }))
            .catch(action((err) => {

            }))
            .finally(action(() => {
                this.replyProgress = false;
            }))
    }

    @action endChat(type, feedbackText, stars, email, mobile) {
        this.endProgress = true;
        return agent.Chat.endChat(type.toUpperCase(), feedbackText, stars, email, mobile)
            .then(action((response) => {
                this.cFeedbackDialog = true
                this.consentForPromotions()
                this.resetSessionId()
                userStore.resetAnonymousId()
                window.local_Storage.removeItem("sessionId");
            }))
            .catch(action((err) => {

            }))
            .finally(action(() => {
                this.endProgress = false;
            }))
    }

    @action consentForPromotions(from) {
        if (from === 'ackStatus') {
            this.consentForPromotionsQuery()
            return
        }
        this.consentProgress = true;
        return agent.Chat.consentForPromotions(this.consent)
            .then(action((response) => {
            }))
            .catch(action((err) => {

            }))
            .finally(action(() => {
                this.consentProgress = false;
            }))
    }

    @action consentForPromotionsQuery() {
        this.consentProgress = true;
        this.cFeedbackDialog = true
        return agent.Query.consentForPromotions(this.consent)
            .then(action((response) => {
            }))
            .catch(action((err) => {

            }))
            .finally(action(() => {
                this.consentProgress = false;
            }))
    }

    @action getAllMessages(sessionId, pageNo, skip, direction, refresh) {
        this.replyProgress = true;
        this.scrollAtBottom = false
        return agent.Chat.getAllMessages(sessionId, pageNo, skip, direction)
            .then(action((response) => {
                if (response.statusCode == '200') {
                    if (refresh && response.data.chatData.messages.length > 0) {
                        this.typing = true;
                        setTimeout(() => {
                            this.updateAfterRefresh(sessionId, pageNo, skip, direction, response)
                        }, 2000);
                    }
                    else {
                        this.updateAfterRefresh(sessionId, pageNo, skip, direction, response)
                    }
                }
            }))
            .catch(action((err) => {
                if (err && err.response && err.response.status)
                    this.initiateChat()
            }))
            .finally(action(() => {

                this.replyProgress = false;
                //  this.agentTyping(false);
                this.scrollAtBottom = false
            }))
    }

    @action updateAfterRefresh(sessionId, pageNo, skip, direction, response) {
        this.typing = false
        this.chatData.totalMessages = response.data.chatData.totalMessages;
        this.chatData.sessionId = response.data.chatData.sessionId;
        this.chatData.uniqueClientId = response.data.chatData.uniqueClientId;
        this.chatData.chatClosedBySystemDueToInactivity = response.data.chatData.chatClosedBySystemDueToInactivity
        window.local_Storage.setItem('anonymousId', this.chatData.uniqueClientId);
        websocketStore.connect()
        if (this.chatData.messages.length === 0)
            this.chatData.messages = direction === 'UP' ? response.data.chatData.messages.reverse() : response.data.chatData.messages
        else {
            response.data.chatData.messages.map((r, i) => {
                if (this.chatData.messages.findIndex(x => x._id === r._id) < 0)
                    this.chatData.messages.push(r)
            })
        }
    }


    @action getAllMessagesTop(sessionId, pageNo, skip, direction) {
        this.replyProgress = true;
        this.chatData.messages && this.chatData.messages.map((row, i) => {
            if (i === 0)
                row.active = true
            else
                row.active = false
        })
        return agent.Chat.getAllMessages(sessionId, pageNo, skip, direction)
            .then(action((response) => {
                if (response.statusCode == '200') {

                    this.chatData.totalMessages = response.data.chatData.totalMessages;
                    this.chatData.sessionId = response.data.chatData.sessionId;
                    this.chatData.uniqueClientId = response.data.chatData.uniqueClientId;
                    window.local_Storage.setItem('anonymousId', this.chatData.uniqueClientId);
                    websocketStore.connect()
                    if (this.chatData.messages.length === 0)
                        this.chatData.messages = response.data.chatData.messages
                    else {
                        response.data.chatData.messages.map((r, i) => {
                            if (this.chatData.messages.findIndex(x => x._id === r._id) < 0)
                                this.chatData.messages.unshift(r)
                        })
                    }
                    this.activeIndex = this.chatData.messages.findIndex(x => x.active === true)


                }
            }))
            .catch(action((err) => {
            }))
            .finally(action(() => {

                this.replyProgress = false;
                this.agentTyping(false);
            }))
    }

    @action getChatList(pageNo) {
        this.inProgress = true;
        return agent.Chat.getChatList(pageNo)
            .then(action((response) => {
                if (response.statusCode == '200') {
                    if (pageNo === 0)
                        this.chatList = response.data.chatList;
                    else
                        this.chatList.push(...response.data.chatList);
                    this.totalChat = response.data.totalChat;
                }
            }))
            .catch(action((err) => {
            }))
            .finally(action(() => {
                this.inProgress = false;
            }))
    }

    @action refreshChatAfterTyping() {
        if (this.chatData.sessionId) {
            this.typing = false
            this.getAllMessages(this.chatData.sessionId, 0, this.chatData.totalMessages, 'DOWN')
        }
    }

    @action refreshChat() {
        this.getAllMessages(this.chatData.sessionId, 0, this.chatData.totalMessages, 'DOWN', true)
    }
}
export default new ChatStore();
