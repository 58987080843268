import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { queryListStyles } from '../../../../Styles/queryStyles';
import { inject, observer } from 'mobx-react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

@inject('queryStore')
@observer
class UploadDocuments extends Component {

    renderForm = (state) => {
        const { classes } = this.props
        var items = Object.keys(state).map(function (key) {
            let labelToShow = key.replace(/^./, key[0].toUpperCase()).replace(/_/g, " ")
            return (
                <div className={classes.formContainer} style={{ marginTop: '1vh' }}>
                    {
                        labelToShow.replace(/ /g, '').length > 1 ? (
                            <div style={{ color: 'gray', marginBottom: '1vh' }}>{labelToShow}</div>
                        ) : null
                    }
                    <div className={classes.processedValue}>{state[key]}</div>
                </div>
            )
        });
        return items
    }

    reupload = () => {
        this.props.queryStore.reupload();
        this.props.clearData()
    }

    renderErrorMessage = (error) => {
        const { classes } = this.props
        return (
            <div className={classes.errorContainer}>
                <Typography className={classes.error} >Error</Typography>
                <Typography className={classes.policyNoText} >{error}</Typography>
                <Button variant="contained" className={classes.reupload} type="submit" onClick={(event) => this.reupload()}>
                    Re-Upload
                </Button>

            </div>
        )
    }

    render() {
        const { classes } = this.props;
        const { processedData } = this.props.queryStore;
        return (
            <div>
                <Fragment>
                    {
                        processedData && processedData.error ? this.renderErrorMessage(processedData.error) :
                            (processedData ? <div><Fragment> {this.renderForm(processedData)} </Fragment></div> : null)
                    }
                </Fragment>
            </div>
        )
    }
}
UploadDocuments.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(queryListStyles, { withTheme: true })(UploadDocuments);

