/*
 ReplyCard component
 url match: /dashboard
 Base component for the home page, redirects to appropriate 
 components depending on the URL/Route
*/
import { withRouter } from 'react-router-dom';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { replyStyles } from '../../../Styles/chatStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { ReactSVG } from 'react-svg';
import Images from '../../../Theme/Images';
import ChatAttachmentPreview from './ChatAttachmentPreview';
import PoweredBy from '../../../Common/PoweredBy';
import ChatSuggestionList from './ChatSuggestionList';
import clsx from 'clsx'
const acceptedFiles = ['image/gif', 'image/jpeg', 'image/png', 'application/pdf', 'application/zip'];

@inject('userStore', 'commonStore', 'chatStore')
@observer
class ReplyCard extends React.Component {
    state = {
        showTextField: true,
        comment: '',
        files: [],
        mail: false,
        whatsapp: false,
        suggestedText: ''
    }

    constructor() {
        super()
        this.inputOpenFileRef = React.createRef()
    }

    openFileModal = () => {
        const { type, showPassword, password } = this.state;

        this.inputOpenFileRef.current.click();
    }

    clearFiles = (index) => {
        let temp = this.state.files.slice();
        temp.splice(index, 1);

        this.setState({ files: temp })
        if (temp.length < 1)
            this.cancelUpload()
    }

    cancelUpload = () => {
        this.props.chatStore.setAttachmentPreview(false)
        this.setState({ files: [] })
    }

    onChangeFile = (event, type) => {
        event.stopPropagation();
        event.preventDefault();
        var files = event.target.files;
        if (!files.length)
            return
        else {
            let temp = []
            Array.from(files).forEach(x => {
                if (!acceptedFiles.includes(x.type.toLowerCase())) {
                    this.props.commonStore.handleOpenSnackBar('Only image and PDF files are allowed!', 'info')
                    return
                }
                x.myUrl = URL.createObjectURL(x)
                temp.push(x)
            })
            let newFilesArray = this.state.files.slice();
            newFilesArray = newFilesArray.concat(temp)
            if (newFilesArray.length > 10)
                this.props.commonStore.handleOpenSnackBar('Only 10 or less attachments are allowed per reply!', 'info')
            else {
                this.setState({ files: newFilesArray })
            }
            this.props.chatStore.setAttachmentPreview(true)
        }
        event.target.value = ''
    }

    scrollToBottom = () => {
        var elem = document.getElementById('myscrollingChat');
        if (elem != null)
            elem.scrollTop = elem.scrollHeight - elem.offsetHeight;
    }

    replyToChat = () => {
        const { comment, files } = this.state;
        const { replyProgress } = this.props.chatStore;
        const { noNet } = this.props.commonStore
        if ((files.length || comment.trim().length) && !replyProgress) {
            if (!noNet) {
                this.props.chatStore.sendMessage(files, comment.length ? comment : undefined)
                    .then(() => {
                        this.props.chatStore.setAttachmentPreview(false)
                        this.props.resetPage()
                        if (!this.props.chatStore.replyProgress) {
                            this.setState({ comment: '', files: [] });
                            this.scrollToBottom();
                        }

                    })
                this.setState({ files: [] })
            }
        }

        else {
           // this.props.commonStore.handleOpenSnackBar("Please add a comment/attach file before proceeding to send", 'info')
        }
    }

    onPressEnter = (event) => {
        const { comment, suggestedText } = this.state;
        if (event.keyCode === 9 && suggestedText.length > 1) {
            event.preventDefault()
            this.setState({
                comment: this.state.comment + suggestedText,
                suggestedText: '',

            })
        }

        //If backspace key is pressed
        else if (event.keyCode === 8) {
            if (comment.length < 4)
                this.setState({
                    suggestedText: '',
                })
        }
        if (event.which === 13) {
            event.preventDefault();
            this.replyToChat()
        }
    }

    actionButtons = () => {
        const { classes } = this.props;
        const { comment } = this.state
        return (
            <div className={classes.actionItems}>
                {
                    //   comment.trim().length ?
                    <ReactSVG className={classes.actionButton} onClick={(e) => this.replyToChat()} src={Images.send} />
                    //:
                    //   <ReactSVG className={classes.actionButton} onClick={(e) => this.openFileModal(e)} src={Images.attachment} />

                }
                <input
                    type="file"
                    multiple
                    style={{ display: "none" }}
                    ref={this.inputOpenFileRef}
                    accept="image/x-png,image/gif,image/jpeg,.pdf,.doc,.zip,.rar,.7zip"
                    onChange={(e) => this.onChangeFile(e, false)}
                />
            </div>
        )
    }

    startTyping = (e) => {
        const { comment } = this.state;
        this.setState({ comment: e.target.value })
        if (e.target.value.trim().length)
            this.props.chatStore.getAutoSuggestList(e.target.value.trim())
        this.props.chatStore.indicateTyping(this.props.chatStore.chatData.sessionId.toString())
        const { chatStore } = this.props;
        this.setState({
            comment: e.target.value,
        })
    }

    sendSuggestion = (value) => {
        this.setState({ comment: '' })
        this.props.chatStore.sendMessage([], value)

    }

    renderInputField = () => {
        const { classes } = this.props;
        const { autoSuggestion, suggestionList } = this.props.chatStore;
        const { comment, files, suggestedText } = this.state;
        return (
            <div style={{ position: 'relative' }}>
                {files.length ? <ChatAttachmentPreview files={files} clearFiles={this.clearFiles} upload={this.replyToChat} cancelUpload={this.cancelUpload} /> : null}
                {comment.trim().length && suggestionList.length ? <ChatSuggestionList open={true} sendSuggestion={this.sendSuggestion} /> : null}
                <div id="drawer-container" style={{ position: 'relative' }} className={clsx({ [classes.replyInput]: true, [classes.sugReplyInput]: comment.trim().length && suggestionList.length })}>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <input id="textInput" name="input-message" autoComplete="off" autoFocus={true} placeholder={'Type a query here'} onChange={this.startTyping} value={comment} onKeyDown={(event) => { this.onPressEnter(event) }}></input>
                    </div>
                    {this.actionButtons()}

                </div>

            </div>
        )
    }

    render() {
        const { classes } = this.props;
        const { showTextField, } = this.state;
        const { inProgress } = this.props.chatStore
        return (
            !showTextField ?
                <Card className={classes.replyCard} >
                    <CardContent classes={{ root: classes.cardContent }}>
                        <div className="rowFlex">
                            <div onClick={(e) => this.setState({ showTextField: true })} className={classes.actionButton} ><ReactSVG src={Images.reply} /><span className={classes.vertCenter}>Reply</span></div>
                            <div onClick={(e) => this.setState({ showTextField: true })} className={classes.actionButton} ><ReactSVG src={Images.note} /><span className={classes.vertCenter}>Add note</span></div>
                        </div>
                    </CardContent>
                </Card>
                :
                <div className={classes.replyCard} onClick={(e) => this.props.commonStore.showOnlyTextBox(false)}>
                    {this.props.chatStore.chatData.chatClosedBySystemDueToInactivity && !inProgress ?
                        <div className={classes.chatClosed}>Chat closed due to inactivity</div> :
                        this.renderInputField()
                    }
                    <PoweredBy />
                </div>
        )
    }
}
export default withStyles(replyStyles, { withTheme: true })(withRouter(ReplyCard));
