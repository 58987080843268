/*
 AttachmentPreview component
 url match: /dashboard
 Base component for the home page, redirects to appropriate 
 components depending on the URL/Route
*/
import { withRouter } from 'react-router-dom';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { Switch, Route } from 'react-router-dom';
import Loader from 'react-loader-spinner'

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { ReactSVG } from 'react-svg';
import Images from '../Theme/Images';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MoreVertIcon from '@material-ui/icons/MoreVert';


import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Moment from 'moment';
import { commonStyles } from '../Styles/commonStyles';
import Tooltip from '@material-ui/core/Tooltip';
const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

@inject('commonStore')
@observer
class AttachmentPreview extends React.Component {
    state = {
        expanded: true,
        showPreview: false,
        currentImage: '',
        fileName: ''
    }




    closeImage = () => {
        this.setState({ showPreview: false })
    }

    modifyText = (text) => {
        return this.props.commonStore.modifyText(text);
    }

    render() {
        const { classes, files } = this.props;
        return (
            <div className={classes.previewContainer}>
                {files.map((row, index) => (
                    row && acceptedImageTypes.includes(row['type']) ?
                        <div className={classes.imgContainer} style={{ backgroundImage: `url(${row.myUrl})` }}>
                            <ReactSVG className={classes.closeIcon} src={Images.close} onClick={(e) => this.props.clearFiles(index)} />
                        </div> :
                        <Tooltip title={row.name}>
                            <div className={classes.fileContainer}>
                                <ReactSVG className={classes.closeIcon} src={Images.close} onClick={(e) => this.props.clearFiles(index)} />
                                <ReactSVG style={{textAlign: 'center'}} src={row['type'] === 'application/zip' ? Images.zip : Images.pdfDoc} />
                            </div>
                        </Tooltip>
                ))
                }
            </div>
        )
    }
}
export default withStyles(commonStyles, { withTheme: true })(withRouter(AttachmentPreview));
