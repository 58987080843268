/*
 MessageList component
 url match: /dashboard
 Base component for the home page, redirects to appropriate 
 components depending on the URL/Route
*/
import { withRouter } from 'react-router-dom';
import React, { useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Loader from 'react-loader-spinner'
import { messageStyles } from '../../../Styles/chatStyles';
import '../../../Styles/react-chat-styles.css';
import ReplyCard from './ReplyCard.js';
import Message from './Message';
import Attachment from './Attachment';
import { ReactSVG } from 'react-svg';
import Images from '../../../Theme/Images';
import clsx from 'clsx';
import * as ReactDOM from 'react-dom';

let page = 1;
const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
};
@inject('chatStore', 'commonStore')
@observer
class MessageList extends React.Component {
    state = {
        showLoader: false,
        fileLength: 0
    }

    componentDidMount() {
        if (this.props.from !== 'newChat')
            this.props.chatStore.getAllMessages(this.props.match.params['id'], 0, 0, 'UP')
                .then(() => {
                    this.scrollToBottom()
                })
        let that = this;
        page = 0;
        // setTimeout(function () { that.setState({ showLoader: false }) }, 3000);
    }


    loadMore() {
        const { chatData } = this.props.chatStore;
        const { from } = this.props;
        var elem = document.getElementById('myscrollingChat');
        let temp = 0;
        if (elem != null) {
            temp = elem.scrollHeight;
        }
        if (chatData.totalMessages > chatData.messages.length) {
            this.props.chatStore.getAllMessagesTop(from === 'newChat' ? chatData.sessionId : this.props.match.params['id'], page++, chatData.messages.length, 'UP')
                .then(() => {
                    if (!this.props.chatStore.replyProgress) {
                        const numMessages = this.messageList.current && this.messageList.current.childNodes.length;
                        let topMessage = numMessages === 0 ? null : this.messageList.current && this.messageList.current.childNodes[this.props.chatStore.activeIndex];
                        this.props.chatStore.setTopMessage(topMessage)
                        ReactDOM.findDOMNode(topMessage) && ReactDOM.findDOMNode(topMessage).scrollIntoView();
                    }
                })
        }
    }

    handleScroll = () => {
        if (this.messageList.current) {
            const scrollTop = this.messageList.current.scrollTop;
            if (scrollTop === 0)
                this.loadMore()
        }
    }

    resetPage = () => {
        page = 0
    }

    setFilesLength = (value) => {
        this.setState({ fileLength: value })
    }

    renderIcon = (from, index) => {
        const { classes } = this.props;
        const { chatData } = this.props.chatStore;
        if (index + 1 < chatData.messages.length) {
            if (chatData.messages[index + 1].messageBy === from)
                // return (<ReactSVG className={classes.hidden} src={Images[`${from.toLowerCase()}`]} />)
                return null
            else
                //return (<ReactSVG src={Images[`${from.toLowerCase()}`]} />)
                return null
        }
        else
            // return (<ReactSVG src={Images[`${from.toLowerCase()}`]} />)
            return null
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    componentWillUpdate(nextProps) {
        if (true) {
            const scrollPos = this.messageList.current && this.messageList.current.scrollTop;
            const scrollBottom = (this.messageList.current && this.messageList.current.scrollHeight - this.messageList.current && this.messageList.current.clientHeight);
            let scrollAtBottom = (scrollBottom <= 0) || (scrollPos === scrollBottom);
            nextProps.chatStore.setScroll(scrollAtBottom)
        }
    }



    renderAttachments = (row, index) => {
        const { classes } = this.props;
        return (
            <div className={row.messageBy === 'CUSTOMER' ? classes.right : classes.left}>
                {/*row.messageBy === 'CUSTOMER' ? this.renderIcon(row.messageBy, index) : null*/}
                <div className="colFlex">
                    {
                        row.messageAttachment.map((item, index) => (
                            <div className={'colFlex'} key={'msgAttachment' + index}>
                                <Attachment item={item} index={index} row={row} />
                                {row.messageText && row.messageText.length ? <Message row={row} /> : null}
                            </div>
                        ))
                    }
                </div>
                {/*row.messageBy !== 'CUSTOMER' ? this.renderIcon(row.messageBy, index) : null*/}
            </div>
        )
    }

    scrollToBottom = () => {
        var elem = document.getElementById('myscrollingChat');
        if (elem != null)
            elem.scrollTop = 0
    }

    scrollToBottom = () => {
        if (this.messageList.current) {
            const scrollHeight = this.messageList.current.scrollHeight;
            const height = this.messageList.current.clientHeight;
            const maxScrollTop = scrollHeight - height;
            ReactDOM.findDOMNode(this.messageList.current).scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
        }

    }

    constructor() {
        super()
        this.messageList = React.createRef()
    }


    render() {
        const { classes, theme } = this.props;
        const { chatData, loadMoreProgress, typing, inProgress } = this.props.chatStore;
        const { showLoader, fileLength } = this.state;
        const { showSearchOnly } = this.props.commonStore;
        return (
            <div className={classes.messages}>
                {!showSearchOnly ? <div id="myscrollingChat" key={chatData && chatData._id} onScroll={(e) => this.handleScroll()} className={clsx({
                    [classes.chatContent]: true, [classes.minimisedDiv]: fileLength > 0,
                    [classes.fullHeight]: true
                })} >
                    {
                        loadMoreProgress ?
                            <div className={classes.loaderDiv}>
                                <Loader height={30} width={30} type={"Oval"} color={theme.palette.red} className={classes.loading} />
                            </div>
                            : null
                    }
                    {inProgress || showLoader ?
                        <div className={classes.loadingContainer}><Loader height={50} width={50} color={this.props.theme.palette.red} type="Oval" className={classes.loading} /></div>
                        :
                        <ul style={{
                             overflow: 'hidden', padding: '0', display: 'flex',
                            flexDirection: 'column', justifyContent: 'space-between', margin: '0vh'
                        }} ref={this.messageList}>
                            <div>
                                {
                                    chatData && chatData.messages && chatData.messages.length ?
                                        chatData.messages.map((row, index) => (
                                            <div key={'chat' + index} >
                                                {
                                                    row.messageAttachment.length ?
                                                        this.renderAttachments(row, index)
                                                        : <Message row={row} index={index} />
                                                }
                                            </div>
                                        )) : null
                                }
                            </div>
                            {
                                typing ?
                                    <div className={classes.left} >
                                        <Loader key={typing} height={30} width={30} type={"ThreeDots"} color={theme.palette.red} className={classes.typing} />
                                    </div>
                                    : null
                            }
                        </ul>
                    }

                    <AlwaysScrollToBottom />

                </div> : null}
                <ReplyCard scrollToBottom={this.scrollToBottom} setFilesLength={this.setFilesLength} resetPage={this.resetPage} />
            </div>

        )

    }
}
export default withStyles(messageStyles, { withTheme: true })(withRouter(MessageList));
