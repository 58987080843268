import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { queryListStyles } from '../../../Styles/queryStyles';
import { inject, observer } from 'mobx-react';
import Home from '../../Home/Home';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import SearchQuery from '../../Home/SearchQuery';

import { ReactSVG } from 'react-svg'
import Images from '../../../Theme/Images';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

@inject('queryStore', 'commonStore', 'chatStore')
@observer
class AlternateQuery extends Component {

    componentDidMount() {
        this.props.queryStore.getFaqs(this.props.match.params['id']);
    }

    updateQuery = (item) => {
        let temp = [{ intent: item.type, filter: "HEALTH_POLICY" }]
        this.props.queryStore.updateQuery(temp, null, this.props.match.params['id'])
            .then(() => {
                if (!this.props.queryStore.inProgress) {
                    if (this.props.queryStore.policyList && this.props.queryStore.policyList.length) {
                        this.props.history.push({
                            pathname: '/policies',
                            state: { input: [{ intent: item.type, filter: "HEALTH_POLICY" }], from: 'update', tempId: this.props.match.params['id'] }
                        })
                    }
                    else {
                        this.props.commonStore.setHeaderText('Pending Queries')
                        this.props.history.push({
                            pathname: '/queries/pending',
                            state: { type: "pending" }
                        })
                    }
                }
            })
    }

    chatWithAgent = () => {
        this.props.chatStore.resetSessionId()
        this.props.history.push({
            pathname: '/chat',
        })
    }

    renderLoader = () => {
        const { classes } = this.props;
        return (
            <div className={classes.outerLoader}>
                <CircularProgress size={80} thickness={2} className={classes.progress} />
            </div>
        )
    }

    render() {
        const { classes } = this.props;
        const { faqs, inProgress } = this.props.queryStore;
        return (
            <div style={{  height: '100%', margin: '1vh 4vw' }}>
                    <div  style={{ height: '100%' }}>
                        <div className={classes.infoText}>Please check if your query is in the following list, or you can write your query again</div>
                        <div className={classes.alternateContainer}>
                            {faqs && !inProgress ? <div className={classes.homeContainer} >
                                <List>
                                    {faqs.map((item, index) => (
                                        <ListItem classes={{gutters: classes.itemGutter}} button key={item.text} divider={true} onClick={(event) => this.updateQuery(item)}>
                                            <ListItemIcon style={{ minWidth: '2vw' }}>
                                                <FiberManualRecordOutlinedIcon className={classes.bulletIcon} />
                                            </ListItemIcon>
                                            <div className={classes.faqItems}>
                                                <span>{item.text}</span>
                                            </div>
                                        </ListItem>
                                    ))}
                                    <ListItem  classes={{gutters: classes.itemGutter}}  button key="agentChat" divider={true} onClick={(event) => this.chatWithAgent()}>
                                        <ListItemIcon style={{ minWidth: '2vw' }}>
                                            <FiberManualRecordOutlinedIcon className={classes.bulletIcon} />
                                        </ListItemIcon>
                                        <div className={classes.faqItems}>
                                            <span>Chat with an Agent</span>
                                        </div>
                                    </ListItem>
                                </List>
                            </div> : this.renderLoader()}
                            <div>
                                <SearchQuery actionType='update' />
                            </div>
                        </div>
                    </div>
            </div>
        )
    }
}
AlternateQuery.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withRouter(withStyles(queryListStyles)(AlternateQuery));
