import React from "react";
import Header from './Common/header';
import NoInternet from "./Components/Home/NoInternet";
import { inject, observer } from 'mobx-react';
import InternetConnectivityDialog from "./Common/InternetConnectivityDialog";
import ConnectivityDialog from "./Common/ConnectivityDialog";
import PoweredBy from "./Common/PoweredBy";

/*
 * Component which serves the purpose of a "root route component".
 */
@inject('commonStore', 'chatStore')
@observer
class Page extends React.Component {
    state = {
        isDisconnected: false,
    }
    /**
     * Here, we define a react lifecycle method that gets executed each time
     * our component is mounted to the DOM, which is exactly what we want in this case
     */

    /**
     * Here, we use a component prop to render
     * a component, as specified in route configuration
     */
    componentWillUnmount() {
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
    }

    componentDidMount() {
        this.handleConnectionChange();
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
    }

    fetchNewData = () => {
        const { headerType } = this.props;
        switch (headerType) {
            case 'chat':
                this.props.chatStore.refreshChat();
                break;
        }
    }

    handleConnectionChange = () => {
        const { noNet } = this.props.commonStore
        const condition = navigator.onLine ? 'online' : 'offline';
        if (condition === 'online') {
            const webPing = setInterval(
                () => {
                    fetch('//assistedclosure.tin-nsdl.com/mascot.png', {
                        mode: 'no-cors',
                    })
                        .then(() => {
                            if (noNet) {
                                this.props.commonStore.setConnectedStatus(true)
                                this.fetchNewData()
                                let that = this;
                                setTimeout(() => {
                                    that.props.commonStore.setConnectedStatus(false)
                                }, 1000);
                            }
                            this.setState({ isDisconnected: false }, () => {
                                this.props.commonStore.resetNoInternet()
                                return clearInterval(webPing)
                            });
                        }).catch(() => {
                            this.props.commonStore.setNoInternet()
                            this.setState({ isDisconnected: true })
                        })
                }, 5000);
            return;
        }
        return this.props.commonStore.setNoInternet()
    }

    render() {
        const PageComponent = this.props.component
        const { headerType, headerText } = this.props;
        const { noNet, showConnectedStatus } = this.props.commonStore
        return (
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Header headerType={headerType} headerText={headerText}></Header>
                <div style={{ width: '100%', height: 'calc(100% - 61px)', position: 'fixed', top: '61px', display: 'flex', flexDirection: 'column' }} className='colFlex'>
                    <PageComponent />
                    {headerType !== 'chat' && headerType !== 'feedback' ? <PoweredBy/> : null}
                </div>
                <InternetConnectivityDialog open={noNet} type='error' message='Please check your internet connection' onClose={this.closeDisconnected} />
                <InternetConnectivityDialog open={showConnectedStatus} type='success' message="You're now online" onClose={this.closeConnected} />
                <ConnectivityDialog />
            </div>
        )
    }
}

export default Page
