import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { loginStyles } from '../../Styles/homeStyles';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import { ReactSVG } from 'react-svg';
import Images from '../../Theme/Images';

@inject('queryStore', 'commonStore', 'userStore')
@observer
class LoginDrawer extends Component {

    navigateTo = (type) => {
        if (this.props.userStore.authenticated) {
            this.props.commonStore.setGoBackTo('home')
            if (type == 'pending')
                this.props.commonStore.setHeaderText('Pending Queries')
            else
                this.props.commonStore.setHeaderText('Completed Queries')

            this.props.history.push({
                pathname: '/queries/' + type,
                state: { type: type }
            })
        }
        else
            this.props.history.push({
                pathname: '/login',
                state: { type: type }
            })

    }

    goToChats = () => {
        if (this.props.userStore.authenticated) {
            this.props.commonStore.setGoBackTo('home')
            this.props.history.push('/chats')
        }
        else
            this.props.history.push({
                pathname: '/login',
                state: { goTo: 'chats' }
            })

    }

    signOut = () => {
        this.props.closeLogin()
        this.props.userStore.logout();
        this.props.history.push({
            pathname: '/home',
        })
    }

    render() {
        const { classes, open } = this.props;
        const { authenticated } = this.props.userStore;
        return (
            <Drawer classes={{
                paper: classes.loginDrawer,
            }} anchor="bottom" open={open} onClose={(e) => this.props.closeLogin()}>
                <div className={classes.container}>
                    <div className={classes.heading}>What Would You Like To Do? </div>
                    <List>
                        <ListItem classes={{root: classes.listItem}}>
                            <ListItemText onClick={(event) => this.navigateTo("pending")} style={{ cursor: 'pointer' }} >
                                <div className="rowFlex">
                                    <ReactSVG src={Images.openTickets} />
                                    <div>
                                        <div className={classes.name}>Open Tickets</div>
                                        <div className={classes.desc}>"View all open tickets"</div>
                                    </div>
                                </div>
                            </ListItemText>
                            <ListItemIcon onClick={(event) => this.navigateTo("pending")}>
                                <React.Fragment>
                                    <ArrowForwardIosIcon style={{ cursor: 'pointer' }} className={classes.forward}></ArrowForwardIosIcon>
                                </React.Fragment>
                            </ListItemIcon>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <ListItemText onClick={(event) => this.navigateTo("completed")} style={{ cursor: 'pointer' }} >
                                <div className="rowFlex">
                                    <ReactSVG src={Images.closedTickets} />
                                    <div>
                                        <div className={classes.name}>Closed Tickets</div>
                                        <div className={classes.desc}>"View all closed tickets"</div>
                                    </div>
                                </div>
                            </ListItemText>
                            <ListItemIcon onClick={(event) => this.navigateTo("completed")}>
                                <React.Fragment>
                                    <ArrowForwardIosIcon style={{ cursor: 'pointer' }} className={classes.forward}></ArrowForwardIosIcon>
                                </React.Fragment>
                            </ListItemIcon>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <ListItemText onClick={(event) => this.goToChats()} style={{ cursor: 'pointer' }} >
                                <div className="rowFlex">
                                    <ReactSVG src={Images.chats} />
                                    <div>
                                        <div className={classes.name}>Chats</div>
                                        <div className={classes.desc}>"View chat history"</div>
                                    </div>
                                </div>
                            </ListItemText>
                            <ListItemIcon onClick={(event) => this.goToChats()}>
                                <React.Fragment>
                                    <ArrowForwardIosIcon style={{ cursor: 'pointer' }} className={classes.forward}></ArrowForwardIosIcon>
                                </React.Fragment>
                            </ListItemIcon>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <ListItemText style={{ cursor: 'pointer' }} >
                                <div className="rowFlex">
                                    <ReactSVG src={Images.newProducts} />
                                    <div>
                                        <div className={classes.name}>Apply for new products</div>
                                        <div className={classes.desc}>"Apply for new products"</div>
                                    </div>
                                </div>
                            </ListItemText>
                            <ListItemIcon onClick={(event) => this.goToChats()}>
                                <React.Fragment>
                                    <ArrowForwardIosIcon style={{ cursor: 'pointer' }} className={classes.forward}></ArrowForwardIosIcon>
                                </React.Fragment>
                            </ListItemIcon>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <ListItemText style={{ cursor: 'pointer' }} >
                                <div className="rowFlex">
                                    <ReactSVG src={Images.notification} />
                                    <div>
                                        <div className={classes.name}>Notifications</div>
                                        <div className={classes.desc}>"Switch notifications on/off"</div>
                                    </div>
                                </div>
                            </ListItemText>
                        </ListItem>
                        {
                            authenticated ?
                                <ListItem style={{ cursor: 'pointer' }} onClick={(event) => this.signOut()}>
                                    <ListItemText style={{ cursor: 'pointer' }} >
                                        <div className="rowFlex">
                                            <ReactSVG src={Images.logout} />
                                            <div>
                                                <div className={classes.name}>Sign Out</div>
                                                <div className={classes.desc}>"Sign out of your account"</div>
                                            </div>
                                        </div>
                                    </ListItemText>
                                </ListItem>
                                : null
                        }
                    </List>
                </div>
            </Drawer>
        )
    }
}

LoginDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(loginStyles)(LoginDrawer));
