import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { commonStyles } from '../Styles/commonStyles';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { ReactSVG } from 'react-svg'
import Images from '../Theme/Images';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

@inject('commonStore', 'userStore')
@observer
class Preferences extends Component {

    componentDidMount() {
        this.props.userStore.getStatusCount()
        this.props.commonStore.setGoBackTo('home')
    }

    navigateTo = (type) => {
        this.props.commonStore.setGoBackTo('preferences')
        if (type == 'pending')
            this.props.commonStore.setHeaderText('Pending Queries')
        else
            this.props.commonStore.setHeaderText('Completed Queries')

        this.props.history.push({
            pathname: '/queries/' + type,
            state: { type: type }
        })
    }

    goToChats = () => {
        this.props.commonStore.setGoBackTo('preferences')
        this.props.history.push('/chats')
    }

    signOut = () => {
        this.props.userStore.logout();
        this.props.history.push({
            pathname: '/home',
        })
    }

    render() {
        const { classes, theme } = this.props;
        const { inProgress } = this.props.userStore;
        const { customerDetails, chatCount, closedTicketCount, openTicketCount } = this.props.userStore.statusCount;
        if (inProgress)
            return (
                <div className={classes.outerLoader}>
                    <CircularProgress size={150} thickness={2} className={classes.progress} />
                </div>
            )
        return (
            <div className={classes.prefContainer}>
                <div style={{ textAlign: 'center' }}>
                    <AccountCircleIcon style={{ fontSize: '20vh', color: theme.palette.warmGray }} />
                    <Typography style={{ cursor: 'pointer' }}  >{customerDetails && customerDetails.userFullName}</Typography>
                    <Typography className={classes.email} variant="subtitle2">{customerDetails && customerDetails.email}</Typography>
                </div>
                <div>
                    <List>
                        <ListItem>
                            <ListItemText onClick={(event) => this.navigateTo("pending")} style={{ cursor: 'pointer' }} ><Typography className={classes.name}>Open Tickets</Typography></ListItemText>
                            <ListItemIcon onClick={(event) => this.navigateTo("pending")}>
                                <React.Fragment>
                                    <Typography style={{ cursor: 'pointer' }} >{openTicketCount}</Typography>
                                    <ArrowForwardIosIcon style={{ cursor: 'pointer' }} className={classes.forward}></ArrowForwardIosIcon>
                                </React.Fragment>
                            </ListItemIcon>
                        </ListItem>
                        <ListItem>
                            <ListItemText onClick={(event) => this.navigateTo("completed")} style={{ cursor: 'pointer' }} ><Typography className={classes.name}>Closed Tickets</Typography></ListItemText>
                            <ListItemIcon onClick={(event) => this.navigateTo("completed")}>
                                <React.Fragment>
                                    <Typography style={{ cursor: 'pointer' }} >{closedTicketCount}</Typography>
                                    <ArrowForwardIosIcon style={{ cursor: 'pointer' }} className={classes.forward}></ArrowForwardIosIcon>
                                </React.Fragment>
                            </ListItemIcon>
                        </ListItem>
                        <ListItem>
                            <ListItemText onClick={(event) => this.goToChats()} style={{ cursor: 'pointer' }} ><Typography className={classes.name}>Chats</Typography></ListItemText>
                            <ListItemIcon onClick={(event) => this.goToChats()}>
                                <React.Fragment>
                                    <Typography style={{ cursor: 'pointer' }} >{chatCount}</Typography>
                                    <ArrowForwardIosIcon style={{ cursor: 'pointer' }} className={classes.forward}></ArrowForwardIosIcon>
                                </React.Fragment>
                            </ListItemIcon>
                        </ListItem>
                        <ListItem>
                            <ListItemText style={{ cursor: 'pointer' }} ><Typography className={classes.name}>Notifications</Typography></ListItemText>
                            <ListItemIcon >
                                <React.Fragment>
                                    <Switch
                                        name="notificationToggle"
                                        color="primary"
                                    />
                                </React.Fragment>
                            </ListItemIcon>
                        </ListItem>
                        <ListItem style={{ cursor: 'pointer' }} onClick={(event) => this.signOut()}>
                            <ListItemText><Typography className={classes.name} >Sign Out</Typography></ListItemText>
                        </ListItem>
                    </List>
                </div>
            </div>
        )
    }
}
Preferences.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withRouter(withStyles(commonStyles, { withTheme: true })(Preferences));
