import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { headerStyles } from '../Styles/commonStyles';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import clsx from 'clsx';
import { ReactSVG } from 'react-svg';
import Images from '../Theme/Images';
import CloseIcon from '@material-ui/icons/Close';
import MinimizeIcon from '@material-ui/icons/Minimize';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CloseChatFeedback from '../Components/Queries/Chat/CloseChatFeedback';

@inject('commonStore', 'userStore', 'chatStore', 'queryStore')
@observer
class Header extends Component {
    state = {
        showChatDialog: false
    }

    openPreferences = () => {
        const {  version } = this.props.commonStore;
        this.props.history.push({
            pathname: `/${version}/preferences`
        })
    }

    goBack = () => {
        this.props.userStore.setProgress(false)
        const { goBackTo, version } = this.props.commonStore;
        if (this.props.location.pathname.toLowerCase().includes('ackstatus')) {
            this.props.queryStore.setShowFeedback(true)
            this.props.history.push({
                pathname: `/${version}/feedback`,
                appState: { from: 'ackStatus' }
            })
            return
        }
        if (this.props.location.pathname.toLowerCase().includes('captcha')) {
            this.props.queryStore.setShowFeedback(false)
            this.props.userStore.setCustomerData({})
            this.props.history.push({
                pathname: `/${version}/`,
            })
        }
        else if (this.props.location.pathname.toLowerCase().includes('/feedback')) {
            this.props.queryStore.setShowFeedback(false)
            this.props.history.push({
                pathname: `/${version}/`,
            })
        }
        else if (this.props.location.pathname.toLowerCase().includes('chatfeedback')) {
            this.props.history.push(`/${version}/`)
            this.props.userStore.setCustomerData({})
        }
        else if (this.props.location.pathname.toLowerCase().includes('chat') && !this.props.location.pathname.toLowerCase().includes('chats')) {
            this.setState({ showChatDialog: true })
        }
        else {
            window.local_Storage.removeItem("sessionId");
            this.props.chatStore.resetSessionId()
            if (goBackTo === 'ackStatus') {
                this.props.history.go(-1)
            }
            else if (goBackTo === 'home')
                this.props.history.push({
                    pathname: `/${version}/home`,
                })
            else if (goBackTo === 'pendingQueries') {
                this.props.history.push({
                    pathname: `/${version}/queries/pending`,
                })
                this.props.commonStore.setGoBackTo('home')
            }

            else if (goBackTo === 'preferences')
                this.props.history.push({
                    pathname: `/${version}/preferences`,
                })
            else if (goBackTo === 'chatList')
                this.props.history.push({
                    pathname: `/${version}/chats`,
                })
            else
                this.props.history.go(-1)
        }
    }

    componentDidMount() {
        if (this.props.match.params['version'] !== undefined)
            this.props.commonStore.setVersion(this.props.match.params['version'])
        else
            this.props.commonStore.setVersion('v4')
        this.props.commonStore.setActiveHeader(this.props.headerText)
    }

    renderMinimiseAndClose = () => {
        const { classes } = this.props;
        const { showSearchOnly } = this.props.commonStore
        return (
            <div className="rowFlex">
                {!showSearchOnly ? <div className={classes.minimize} onClick={(e) => this.showOnlyTextBox()}>&mdash;</div> : null}
            </div>
        )
    }



    showOnlyTextBox = () => {
        const {version} = this.props.commonStore
        if (this.props.location.pathname.includes('home'))
            this.props.commonStore.showOnlyTextBox(true)
        else if (this.props.location.pathname.includes('chat'))
            this.props.commonStore.showOnlyTextBox(true)
        else {
            this.props.history.push(`/${version}/home`)
            this.props.commonStore.showOnlyTextBox(true)
        }
    }

    renderHomeHeader = () => {
        const { classes } = this.props;
        return (
            <div className={'hContainer'}>
                <div className={clsx({ [classes.normalHeader]: true })}  >
                    <div className={clsx({ [classes.headerContainer]: true })} >
                        <div className={classes.logoContainer} style={{ width: '50%' }}>
                            {/* <img className={classes.logo} src={Images.nsdlLogo} style={{ width: '50%' }} />*/}
                            {/*this.renderMinimiseAndClose()*/}
                            {/*<ReactSVG src={Images.me} className={classes.profile} onClick={(event) => this.openPreferences()} />*/}
                        </div>
                        {/*!showSearchOnly ? this.renderMinimiseAndClose() : null*/}
                    </div>
                </div>
            </div>


        )
    }

    closeDialog = () => {
        this.setState({
            showChatDialog: false
        })
    }

    confirmCloseChat = () => {
        const { goBackTo, version } = this.props.commonStore;
        this.setState({
            showChatDialog: false
        })

        ////window.local_Storage.removeItem("sessionId");
        ////this.props.userStore.resetAnonymousId()
        // if (!this.props.userStore.authenticated)
        //   this.props.userStore.setToken(undefined)
        this.props.queryStore.setShowFeedback(true);
        // this.props.commonStore.setGoBackTo('home')
        this.props.history.push({
            pathname: `/${version}/chatFeedback`,
            appState: { item: {}, type: 'SATISFIED', from: 'chat' }
        })
        ////  this.props.chatStore.resetSessionId()

    }

    confirmClearChat = () => {
        this.setState({
            showChatDialog: false
        })
    }

    renderFeedbackHeader = () => {
        const { chatData } = this.props.chatStore
        const { classes, headerText } = this.props
        return (
            <div className={classes.feedbackHeader}>
                <div>{headerText}</div>
            </div>
        )
    }

    renderCommonHeader = () => {
        const { headerText, classes, theme, headerType } = this.props;
        const { customHeaderText, showSearchOnly } = this.props.commonStore
        return (
            <div className={'hContainer'} style={{ backgroundSize: '85%', backgroundRepeat: 'no-repeat' }}>
                <div className={clsx({ [classes.normalHeader]: true, [classes.preferenceHeader]: headerText === 'Preferences' })}  >
                    <div className={clsx({ [classes.headerContainer]: true, [classes.preferenceHeader]: headerText === 'Preferences' })} >
                        <div className={classes.logoContainer} style={{ flex: '1' }}>
                            {/* <img className={classes.logo} src={Images.nsdlLogo} style={{ width: '50%' }} />*/}
                            {/*this.renderMinimiseAndClose()*/}
                            {/*<ReactSVG src={Images.me} className={classes.profile} onClick={(event) => this.openPreferences()} />*/}
                        </div>

                        <div className="rowFlex" style={{ justifyContent: 'end', flex: '1' }}>
                            {this.props.location.pathname.toLowerCase().includes('chat') && !this.props.location.pathname.toLowerCase().includes('chats') && !this.props.location.pathname.toLowerCase().includes('chatfeedback') ? <div className={classes.back}>
                                <ReactSVG id="acBack" onClick={(event) => this.confirmCloseChat()} src={Images.closeHeader} />
                            </div> :
                                <div className={classes.back}><ReactSVG id="acBack" onClick={(event) => this.goBack()} src={Images.closeHeader} /></div>}
                            <div></div>
                        </div>
                        {/*!showSearchOnly ? this.renderMinimiseAndClose() : null*/}
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { headerType } = this.props;
        return (
            headerType == 'home' || headerType == 'nsdlHome' ?
                this.renderHomeHeader()
                :
                this.renderCommonHeader()
        )
    }
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(headerStyles)(Header));
