import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { queryListStyles } from '../../Styles/queryStyles';
import { inject, observer } from 'mobx-react';
import { ReactSVG } from 'react-svg'
import Images from '../../Theme/Images';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { NavLink } from 'react-router-dom';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import QueryCard from './QueryCard';
import { withRouter } from 'react-router-dom';
import StatusCard from './StatusCard';
let page = 1;
@inject('queryStore', 'commonStore')
@observer
class StatusList extends Component {
    componentDidMount(){
        this.props.commonStore.setChatRefresh(false)
    }


    renderQueries = () => {
        const { classes } = this.props;
        const { queryList } = this.props.queryStore;
        return (
            <List className={classes.listPadding}>
                {
                    queryList.map((item, index) => (
                        <div key={index} style={{ paddingTop: '0vh' }}>
                            {/*<div className={classes.tempIdText}>Temp ID: {item.tempId}</div>*/}
                            <QueryCard type={this.props.location.state !== undefined ? this.props.location.state.type : 'pending'}
                                item={item}
                                index={index}>
                            </QueryCard>
                        </div>
                    ))
                }
            </List>
        )
    }

    render() {
        const { classes } = this.props;
        const { inProgress, queryList } = this.props.queryStore;
        if (inProgress && page === 1)
            return (
                <div className={classes.outerLoader}>
                    <CircularProgress size={50} thickness={2} className={classes.progress} />
                </div>
            )
        return (
            <React.Fragment>
                <div className={classes.queryList} id="myScrollingQueryList" onScroll={this.handleScroll}>
                    {
                        <StatusCard />
                    }
                    {
                        inProgress ?
                            <div className={classes.outerLoader}>
                                <CircularProgress size={70} thickness={2} className={classes.progress} />
                            </div>
                            : null
                    }

                </div>

            </React.Fragment>
        )
    }
}
StatusList.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withRouter(withStyles(queryListStyles)(StatusList));
