import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { queryListStyles } from '../../Styles/queryStyles';
import { inject, observer } from 'mobx-react';
import Images from '../../Theme/Images';
import { ReactSVG } from 'react-svg';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import Moment from 'moment';
import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';
import dompurify from 'dompurify'

const bounceInAnim = keyframes`${fadeIn}`;

const SystemMessage = styled.div`
  animation: 1s ${bounceInAnim};
`;

@inject('queryStore', 'commonStore', 'feedbackStore')
@observer
class StatusCard extends Component {
    state = {
        arr: [],
        lastIndex: 0
    };

    componentDidMount() {
        const { version } = this.props.commonStore
        if (!this.props.queryStore.showAckStatus)
            this.props.history.push({
                pathname: `/${version}/home`,
            })
        this.props.queryStore.reupload()
        if (this.props.queryStore.ticketDetails && this.props.queryStore.ticketDetails.ackStatus) {
            this.setState({ arr: this.props.queryStore.ticketDetails.ackStatus })
            this.timer = setInterval(() => {
                if (this.state.lastIndex >= this.state.arr.length) {
                    clearInterval(this.timer);
                    return
                }
                this.setState(prevState => {
                    return {
                        lastIndex: prevState.lastIndex + 1
                    }
                });
            }, 2000);
        }
    }

    getLinkedText = (matches, text) => {
        let temp = text
        matches && matches.length && matches.map((text) => {
            temp = temp.replace(text, `<a className='anchorLink' target='_blank' href=${text}>${text}</a>`)
        })
        return temp
    }

    getMessageText = (text) => {
        const sanitizer = dompurify.sanitize;
        var expression = /(https?:\/\/(?:www1?\.|(?!www1?))[^\s\.]+\.[^\s]{2,}|www1?\.[^\s]+\.[^\s]{2,})/gi;
        var matches = text.match(expression);
        return <span dangerouslySetInnerHTML={{ __html: sanitizer(this.getLinkedText(matches, text), { ADD_ATTR: ['target'] }) }}></span>
    }

    renderQuerySummary = () => {
        const { classes, item, type } = this.props
        const { ticketDetails } = this.props.queryStore
        const { arr, lastIndex } = this.state
        const arrToShow = arr.slice(0, lastIndex + 1);

        return (
            <div className={classes.innerCard}>
                <div className={classes.cardHeading}>
                    <div className={classes.intentHeading}>{`Status of ${ticketDetails.type} Application - ${ticketDetails.ackNumber}`}</div>
                    <span className={classes.time}><ReactSVG className={classes.detailIcon} src={Images.time} />{Moment(ticketDetails.createdAt).format('h:mm a')}</span>
                </div>
                {/* <div className={classes.policyNoText}>Acknowledgement Number: {ticketDetails.ackNumber}</div>*/}
                <div className={classes.detailContainer}>
                    <span className={classes.time}><ReactSVG className={classes.detailIcon} src={Images.ticket} />{ticketDetails.ticketId} </span>
                    <span className={classes.time}><ReactSVG className={classes.detailIcon} src={Images.date} /><div className='colFlex flexCentered'>{Moment(ticketDetails.createdAt).format('DD/MM/YYYY')}</div></span>
                </div>
                <div >
                    {
                        arrToShow.length && arrToShow.map((row, index) => (
                            <SystemMessage className={classes.descText} key={'ackStatus' + index}>{row && this.getMessageText(row)}</SystemMessage>
                        ))
                    }
                </div>
                {/*arrToShow.length === arr.length ? this.renderSatisfactionButtons() : null*/}
                {/*arrToShow.length === arr.length ? <div className='rowFlex flexCentered' ><Button className={classes.skipButton} onClick={(event) => this.onClickSatisfactionButton('skip')}>Exit</Button></div> : null*/}
            </div>
        )
    }

    onClickSatisfactionButton = (type) => {
        const { version } = this.props.commonStore
        if (type === 'skip') {
            this.props.feedbackStore.sendFeedback('skip')
            this.props.history.push(`/${version}/home`)
            return
        }

        this.props.queryStore.setShowFeedback(true);
        this.props.history.push({
            pathname: `/${version}/feedback`,
            appState: { item: this.props.item, type: type, from: 'ackStatus' }
        })
    }
    renderSatisfactionButtons = () => {
        const { classes } = this.props;
        return (
            <div className={classes.footerContainer}>


                <Button className={classes.cFooterButton} onClick={(event) => this.onClickSatisfactionButton('satisfied')}>
                    <div className={classes.cSatisfiedButton}>
                        <div className='rowFlex'>
                            <ReactSVG className={classes.feedbackIcon} src={Images.sat} />
                            <div className={classes.feedbackIcon}>Satisfied</div>
                        </div>
                    </div>
                </Button>
                <Button className={`${classes.cFooterButton} ${classes.cDFooterButton}`} onClick={(event) => this.onClickSatisfactionButton('dissatisfied')}>
                    <div className={`${classes.dissatisfiedButton}`}>
                        <div className='rowFlex'>
                            <ReactSVG className={classes.feedbackIcon} src={Images.dissatOrange} />
                            <div className={classes.feedbackIcon} >Dissatisfied</div>
                        </div>
                    </div>
                </Button>

            </div>
        )
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.cardContainer}>
                {this.renderQuerySummary()}
            </div>
        )
    }
}
StatusCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter((withStyles(queryListStyles)(StatusCard)));
