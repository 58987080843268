import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { homeStyles } from '../../Styles/homeStyles';
import { inject, observer } from 'mobx-react';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Swipe from 'react-easy-swipe';
import Tooltip from '@material-ui/core/Tooltip';
import BackspaceIcon from '@material-ui/icons/Backspace';
import clsx from 'clsx';
const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.orange,
        color: theme.palette.white,
        boxShadow: theme.shadows[1],
        fontSize: '13px',
        fontWeight: 'bold',
    },
}))(Tooltip);
@inject('queryStore', 'commonStore')
@observer
class SearchQuery extends Component {

    state = {
        searchText: '',
        suggestedText: '',
        autoSuggestWidth: '100%',
        suggestionAccepted: false,
        queryList: [],
        showQueryList: false,
        currentQuery: {},
        popQueryList: false,
        doYouMean: '',
        highlight: [],
        open: false,
        initiateChat: false
    }

    constructor(props) {
        super(props)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.queryStore.autoSuggestion && nextProps.queryStore.autoSuggestion.suggestion !== null) {
            this.setState({
                suggestedText: nextProps.queryStore.autoSuggestion.suggestion,
                autoSuggestWidth: ((nextProps.queryStore.autoSuggestion.suggestion.length + this.state.searchText.length + 1) * 12) + 'px',
            })
        }
        else if (nextProps.queryStore.autoSuggestion && nextProps.queryStore.autoSuggestion.dataToSend && nextProps.queryStore.autoSuggestion.dataToSend.doYouMean !== null) {

            this.setState({
                doYouMean: nextProps.queryStore.autoSuggestion.dataToSend.doYouMean,
                suggestedText: '',
                highlight: nextProps.queryStore.autoSuggestion.dataToSend.highlight,
                initiateChat: nextProps.queryStore.autoSuggestion.dataToSend.initiateChat
            })
        }
        else
            this.setState({ doYouMean: "", highlight: [], initiateChat: false })

    }

    handleChange = (e) => {
        const { queryStore } = this.props;

        const { searchText } = this.state;
        if (/\r|\n/.exec(e.target.value)) {
            this.setState({
                highlight: [],
                suggestedText: ''
            })
        }
        if (e.target.value.trim() !== this.state.searchText.trim() && e.target.value.trim().length >= 3 && !(/\r|\n/.exec(e.target.value)))
            queryStore.getAutoSuggestText(e.target.value)
                .then(() => {
                    if (this.props.queryStore.autoSuggestion && this.props.queryStore.autoSuggestion.suggestion !== null) {
                        this.setState({
                            doYouMean: '',
                            highlight: [],
                            suggestedText: this.props.queryStore.autoSuggestion.suggestion,
                            autoSuggestWidth: ((this.props.queryStore.autoSuggestion.suggestion.length + this.state.searchText.length + 1) * 12) + 'px'
                        })
                    }
                    else if (this.props.queryStore.autoSuggestion && this.props.queryStore.autoSuggestion.doYouMean !== null) {
                        this.setState({
                            suggestedText: '',
                            doYouMean: this.props.queryStore.autoSuggestion.doYouMean,
                            highlight: this.props.queryStore.autoSuggestion.highlight
                        })
                    }
                    else
                        this.setState({ doYouMean: "", highlight: [] })


                })
        else {
            this.setState({ suggestedText: '' })
        }
        this.setState({
            searchText: e.target.value,
        })
    }


    handleKeyDown = (event) => {
        const { suggestedText, searchText, suggestionAccepted, queryList, popQueryList, doYouMean } = this.state;

        let temp = suggestedText + searchText;
        let that = this;
        if (event.keyCode === 9 && suggestedText.length > 1) {
            event.preventDefault()
            this.setState({
                searchText: this.state.searchText + suggestedText,
                suggestedText: '',
                suggestionAccepted: true,
                currentQuery: this.props.queryStore.autoSuggestion
            })
        }

        //If backspace key is pressed
        else if (event.keyCode === 8) {
            if (searchText.length === 0 && popQueryList) {
                let temp = queryList.slice();
                temp.pop();
                this.setState({
                    queryList: temp,
                    popQueryList: false,
                })
            }
            else if (searchText.length < 4)
                this.setState({
                    suggestedText: '',
                })
            if (searchText.length === 0) {
                let that = this;
                setTimeout(() => {
                    that.setState({
                        popQueryList: true,
                    })
                }, 100);
            }
        }
        //If Enter key is pressed
        else if (event.keyCode === 13 && searchText.trim().length) {
            if (this.props.queryStore.initiateChat) {
                this.onPressAsk()
                return
            }
            event.preventDefault()
            let temp = queryList.slice();
            let index = temp.findIndex(x => x.intent === this.state.currentQuery.intent)
            if (index < 0)
                temp.push(this.state.currentQuery)
            this.setState({
                showQueryList: true,
                queryList: temp,
                searchText: '',
                autoSuggestWidth: '100%',
                suggestedText: '',
                suggestionAccepted: false
            })
        }
    }

    onPressAsk = () => {
        const { version } = this.props.commonStore
        const { searchText, queryList, suggestionAccepted } = this.state;
        let text = ''
        searchText.trim().length > 0 ? text = searchText : text = null
        let intentList = []
        /*queryList.forEach(item => {
            let temp = {}
           // temp.filter = "HEALTH_POLICY";
            temp.intent = item.intent;
            temp.slug= item.slug
            intentList.push(temp)
        })*/
        let temp = {}
        if (this.props.queryStore.autoSuggestion && this.props.queryStore.autoSuggestion.slug) {
            temp = {
                initiatedVia: 'WEB', slug: suggestionAccepted ? this.props.queryStore.autoSuggestion.slug : ''
            }
        }

        this.props.queryStore.askQuery(temp, text)
            .then(() => {
                if (!this.props.queryStore.inProgress) {
                    if (this.props.queryStore.policyList && this.props.queryStore.policyList.length) {
                        this.props.history.push({
                            pathname: `/${version}/policies`,
                            state: { input: intentList, text: text }
                        })
                    }
                    else if (this.props.queryStore.redirectToChat) {
                        this.props.history.push({
                            pathname: `/${version}/chat`,
                            state: { queryText: searchText }
                        })
                    }
                    else {
                        this.props.commonStore.setHeaderText('Pending Queries')
                        this.props.history.push({
                            pathname: `/${version}/queries/pending`,
                            state: { type: "pending" }
                        })
                    }
                }
            })

    }


    onPressUpdate = () => {
        const { searchText, queryList } = this.state;
        const { version } = this.props.commonStore
        let text = ''
        searchText.trim().length > 0 ? text = searchText : text = null
        let intentList = []
        queryList.forEach(item => {
            let temp = {}
            temp.filter = "HEALTH_POLICY";
            temp.intent = item.intent;
            intentList.push(temp)
        })
        this.props.queryStore.updateQuery(intentList, text, this.props.match.params['id'])
            .then(() => {
                if (!this.props.queryStore.inProgress) {
                    if (this.props.queryStore.policyList && this.props.queryStore.policyList.length) {
                        this.props.history.push({
                            pathname: `/${version}/policies`,
                            state: { input: intentList }
                        })
                    }
                    else if (this.props.queryStore.redirectToChat) {
                        this.props.history.push({
                            pathname: `/${version}/chat`,
                        })
                    }
                    else {
                        this.props.commonStore.setHeaderText('Pending Queries')
                        this.props.history.push({
                            pathname: `/${version}/queries/pending`,
                            state: { type: "pending" }
                        })
                    }
                }
            })

    }

    removeSuggestion = (index) => {
        let temp = this.state.queryList.slice();
        temp.splice(index, 1);
        this.setState({ queryList: temp })
    }

    renderQueryList = () => {
        const { classes } = this.props;
        const { queryList } = this.state;
        return (
            <List>
                {queryList.map((item, index) => (
                    <ListItem key={index} className={classes.selectedListItem}>
                        <ListItemIcon style={{ minWidth: '1vw' }}>
                            <FiberManualRecordIcon className={classes.bulletIcon} />
                        </ListItemIcon>
                        <div className={classes.selectedQuery}>
                            <div className={classes.menuContainer}>{item.textToShow}</div>
                            <div className={classes.queryCancel} onClick={(e) => this.removeSuggestion(index)}>
                                <BackspaceIcon />
                            </div>
                        </div>
                    </ListItem>
                ))}
            </List>
        )
    }

    cancel = (e) => {
        e.target.blur()
        e.stopPropagation()
        this.setState({
            queryList: [],
            suggestedText: '',
            searchText: '',
            doYouMean: '',
            currentQuery: {},
            suggestionAccepted: false,
            open: false,
            highlight: []
        })
    }

    renderButtons = () => {
        const { classes, actionType } = this.props;
        const { inProgress, autoSuggestion } = this.props.queryStore;
        const { searchText, queryList } = this.state;
        return (
            <div className={classes.buttonContainer}>
                <div variant="contained" onClick={(event) => this.cancel(event)} className={classes.cancelButton}  >
                    Cancel
                </div>
                {
                    inProgress ?
                        <Button variant="contained" className={classes.button}  >
                            <CircularProgress size={20} thickness={5} className={classes.loading} />
                        </Button> :
                        (
                            actionType === 'update' ?
                                <Button disabled={!searchText.replace(/\s/g, '').length && queryList.length === 0} variant="contained" className={classes.button} type="submit" onClick={this.onPressUpdate}>
                                    Update
                                </Button>
                                :
                                <Button disabled={!searchText.replace(/\s/g, '').length && queryList.length === 0} variant="contained" className={classes.button} type="submit" onClick={this.onPressAsk}>
                                    Submit
                                </Button>
                        )
                }

            </div>
        )
    }

    onSwipeStart = (event) => {
        //Start swiping...
    }

    onSwipeMove = (position, event) => {
        //Moved ${position.x} pixels horizontally
        //Moved ${position.y} pixels vertically
        return true
    }

    onSwipeEnd = (event) => {
        //End swiping...
    }

    onSwipeRight = (event) => {
        const { suggestedText, searchText, suggestionAccepted, queryList, popQueryList, doYouMean } = this.state;
        if (suggestedText.length > 1) {
            this.setState({
                searchText: this.state.searchText + suggestedText,
                suggestedText: '',
                suggestionAccepted: true,
                currentQuery: this.props.queryStore.autoSuggestion
            })
            let temp = queryList.slice();
            let index = temp.findIndex(x => x.intent === this.props.queryStore.autoSuggestion.intent)
            if (index < 0)
                temp.push(this.props.queryStore.autoSuggestion)
            /* this.setState({
                 showQueryList: true,
                 queryList: temp,
                 searchText: '',
                 autoSuggestWidth: '100%',
                 suggestedText: '',
                 suggestionAccepted: false
             })*/

        }



    }




    doYouMeanIntent = () => {
        const { version } = this.props.commonStore
        if (this.props.queryStore.initiateChat) {
            this.props.history.push({
                pathname: `/${version}/chat`,
                state: {
                    intent: this.props.queryStore.autoSuggestion.intent,
                    queryText: this.state.doYouMean
                }
            })
            return;
        }
        let temp = this.state.queryList.slice();
        let index = temp.findIndex(x => x.intent === this.props.queryStore.autoSuggestion.intent)
        if (index < 0)
            temp.push(this.props.queryStore.autoSuggestion)
        this.setState({
            showQueryList: true,
            queryList: temp,
            searchText: '',
            autoSuggestWidth: '100%',
            suggestedText: '',
            suggestionAccepted: false,
            doYouMean: '',
            highlight: []
        })
    }

    getPlaceholder = () => {
        const { queryList } = this.state;
        if (queryList && queryList.length)
            return `Type your ${this.props.commonStore.stringifyNumber(queryList.length + 1)} query`
        else
            return 'Type a query here...'
    }

    handleFocus = () => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }

    renderHighlightedText = (word) => {
        const { classes } = this.props;
        const { highlight } = this.state;
        if (highlight.includes(word))
            return (<div className={classes.highlighted}>{word}&nbsp;</div>)
        else
            return (<div>{word}&nbsp;</div>)
    }

    open = () => {
        this.setState({ open: true })
    }

    render() {
        const { classes, actionType } = this.props;
        const { autoSuggestion } = this.props.queryStore;
        const { searchText, suggestedText, queryList, showQueryList, doYouMean, open } = this.state;
        return (
            <div style={{ flexGrow: '1', padding: '2vh' }} className={clsx({ [classes.ipContainer]: true, [classes.ipContainer]: actionType === 'update' })} onClick={(e) => this.open()}>
                {/*<LightTooltip
                    title={
                        <div className="speech-bubble">Do you have a query? Raise a ticket? Lodge a complaint?</div>
                    }
                    placement="top">*/}
                <div className={classes.updateInput} onClick={(e) => this.setState({ open: true })}  >Type a query here...</div>
                {/* </LightTooltip>*/}
                <Drawer classes={{
                    paper: classes.drawerPaper,
                }} anchor="bottom" open={open} onClose={(e) => this.setState({ open: false })}>
                    {this.renderButtons()}
                    {
                        showQueryList && queryList.length > 0 ?
                            <div>{this.renderQueryList()}</div> : null
                    }
                    <React.Fragment>
                        <div id="container" style={{ marginTop: '2vh' }}>
                            <textarea mul="true" id="navi" disabled={true} style={{ display: (searchText.length + suggestedText.length >= searchText.length && autoSuggestion && autoSuggestion.suggestion != null) ? 'initial' : 'none' }} placeholder={searchText.length > 0 ? searchText + suggestedText :
                                this.getPlaceholder()} />
                            <Swipe
                                onSwipeStart={this.onSwipeStart}
                                onSwipeMove={this.onSwipeMove}
                                onSwipeEnd={this.onSwipeEnd}
                                onSwipeRight={this.onSwipeRight}>
                                <textarea onFocus={(e) => this.handleFocus()} id="infoi" name="input-message" autoFocus={true} placeholder={this.getPlaceholder()} onChange={this.handleChange} value={searchText} onKeyDown={(event) => { this.handleKeyDown(event) }}></textarea>
                            </Swipe>
                        </div>




                    </React.Fragment>
                    {doYouMean.length > 0 && searchText.length > 0 ?
                        <div className={classes.doYouMeanText}>
                            <span style={{ fontWeight: '500', color: 'black' }}>Do you mean?</span>
                            <div className="rowFlex" style={{ flexWrap: 'wrap' }} onClick={(event) => this.doYouMeanIntent()}>
                                {
                                    doYouMean.split(' ').map((row, index) => (
                                        this.renderHighlightedText(row)
                                    ))
                                }
                            </div>
                        </div> : null
                    }
                </Drawer>
            </div>
        )
    }
}

SearchQuery.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(homeStyles)(SearchQuery));
