import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { listStyles } from '../../../Styles/chatStyles';
import { inject, observer } from 'mobx-react';
import { ReactSVG } from 'react-svg'
import Images from '../../../Theme/Images';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { NavLink } from 'react-router-dom';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { withRouter } from 'react-router-dom';
import ChatCard from './ChatCard';
let page = 1;
@inject('chatStore')
@observer
class ChatList extends Component {

    componentDidMount() {
        this.props.chatStore.getChatList(0)
    }

    loadMore = () => {
        this.props.chatStore.getChatList(page++)
    }

    handleScroll = (event) => {
        const { totalChat, chatList } = this.props.chatStore;
        var obj = document.getElementById('myScrollingChatList');
        if (obj.scrollTop >= (obj.scrollHeight - obj.offsetHeight)) {
            if (totalChat > chatList.length)
                this.loadMore()
        }
    }

    renderQueries = () => {
        const { classes } = this.props;
        const { chatList } = this.props.chatStore;
        return (
            <List classes={{ root: classes.listRoot }}>
                {
                    chatList.map((item, index) => (
                        <div key={index}>
                            <ChatCard item={item} />
                        </div>
                    ))
                }
            </List>
        )
    }

    render() {
        const { classes } = this.props;
        const { inProgress, chatList } = this.props.chatStore;
        if (inProgress && page === 1)
            return (
                <div className={classes.outerLoader}>
                    <CircularProgress size={50} thickness={2} className={classes.progress} />
                </div>
            )
        return (
            <React.Fragment>
                <div className={classes.chatList} id="myScrollingChatList" onScroll={this.handleScroll}>
                    {
                        chatList && chatList.length ? this.renderQueries() : <div className={classes.noEntries}>No entries found !</div>
                    }
                    {
                        inProgress ?
                            <div className={classes.outerLoader}>
                                <CircularProgress size={70} thickness={2} className={classes.progress} />
                            </div>
                            : null
                    }

                </div>

            </React.Fragment>
        )
    }
}
ChatList.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withRouter(withStyles(listStyles)(ChatList));
