import { observable, action } from 'mobx';
import queryStore from './queryStore'
export class CommonStore {

    @observable appLoaded = false;
    @observable snackbarOptions = {
        open: false,
        message: ''
    };
    @observable dialogOptions = {
        dialogOpen: false,
        type: ''
    }
    @observable currentStore = '';  //current store to keep track
    @observable goodToGo = false;
    @observable noNet = false;
    @observable activeHeader = '';
    @observable showSearchOnly = false;
    @observable customHeaderText = "Pending Queries";
    special = ['zeroth', 'first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];
    deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];
    @observable goBackTo = 'home'
    @observable allowChatRefresh = false
    @observable showConnectedStatus = false
    @observable wsFailed = { value: '', type: '' }
    @observable version = 'v4'

    @action setGoBackTo(value) {
        this.goBackTo = value;
    }

    @action setVersion = (value) => {
        this.version = value && value.toLowerCase()
    }

    @action showOverlay(value) {
        this.wsFailed = value
    }

    @action setChatRefresh(value) {
        this.allowChatRefresh = value
    }

    @action setHeaderText(text) {
        this.customHeaderText = text;
    }

    @action setConnectedStatus(value) {
        this.showConnectedStatus = value
        queryStore.getFaqs()
    }

    @action showOnlyTextBox(value) {
        /*  let body = document.getElementById("assistedClosureBody");
          let html = document.getElementById("assistedClosureHtml");
          if (html != null && value)
              html.style.height = 'fit-content'
          else
              html.style.height = '100%'
          if (body != null && value)
              body.style.height = 'fit-content'
          else
              body.style.height = '100%'
          this.showSearchOnly = value*/
    }

    @action setAppLoaded() {
        this.appLoaded = true;
    }

    @action handleOpenSnackBar(message, type) {
        this.snackbarOptions.open = true;
        this.snackbarOptions.type = type;
        this.snackbarOptions.message = message;
    }

    @action handleCloseSnackBar() {
        this.snackbarOptions.open = false;
        this.snackbarOptions.message = '';
    }

    @action setActiveHeader(header) {
        this.activeHeader = header
    }

    @action openDialog(dialogType) {
        this.dialogOptions.dialogOpen = true;
        this.dialogOptions.type = dialogType;
    }

    @action closeDialog() {
        this.dialogOptions.dialogOpen = false;
        this.dialogOptions.type = '';
    }

    @action setCurrentStore(storeValue) {
        this.goodToGo = true;
        this.currentStore = storeValue;
    }
    @action resetFlag() {
        this.goodToGo = false;
    }

    @action setNoInternet() {
        this.noNet = true;
    }
    @action resetNoInternet() {
        this.noNet = false;
    }

    @action stringifyNumber(n) {
        if (n < 20) return this.special[n];
        if (n % 10 === 0) return this.deca[Math.floor(n / 10) - 2] + 'ieth';
        return this.deca[Math.floor(n / 10) - 2] + 'y-' + this.special[n % 10];
    }

    @action modifyText = (text) => {
        let temp = []
        text.split("_").length && text.split("_").forEach(word => {
            word = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            temp.push(word)
        })
        return temp.join(" ")
    }


}
export default new CommonStore();
