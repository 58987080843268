/*
 ChatSuggestionList component
 url match: /dashboard
 Base component for the home page, redirects to appropriate 
 components depending on the URL/Route
*/
import { withRouter } from 'react-router-dom';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { sugStyles } from '../../../Styles/chatStyles';
import clsx from 'clsx';
import dompurify from 'dompurify'
import { ReactSVG } from 'react-svg';
import Images from '../../../Theme/Images';
import { ClickAwayListener } from '@material-ui/core';

@inject('chatStore')
@observer
class ChatSuggestionList extends React.Component {

    componentDidMount() {

    }

    displayText = (item) => {
        let temp = item.question.slice()
        item.question.split(' ').map((row, index) => {
            if (item.highlight.includes(row.toLowerCase())){
                temp = temp.replace(new RegExp('\\b' + row + '\\b', "g"), `<span class='sugHighlight'>${row}</span>`)
            }
               
        })
        return temp
    }

    render() {
        const { classes } = this.props;
        const { suggestionList } = this.props.chatStore
        const sanitizer = dompurify.sanitize;
        return (
                <div className={classes.sugListContainer}>
                    <div className={classes.closeIcon}><ReactSVG src={Images.sugClose} onClick={(e) => this.props.chatStore.setSuggestionList([])} /></div>
                    {
                        suggestionList.map((row, index) => (
                            <div className='chatSuggestion' key={'suggestion' + index} onClick={(e) => this.props.sendSuggestion(row.question)} dangerouslySetInnerHTML={{ __html: sanitizer(this.displayText(row)) }}></div>
                        ))

                    }
                </div>
        )

    }
}
export default withStyles(sugStyles, { withTheme: true })(withRouter(ChatSuggestionList));
