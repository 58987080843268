export const feedbackStyles = theme => ({
    feedbackContainer: {
        height: 'calc(100%)',
        fontSize: '14px',
        backgroundColor: theme.palette.background,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        padding: '0px 0px 0px 0px',
        lineHeight: '2.14',
    },
    consentContainer:{
        fontSize: '11.5px',
        lineHeight: '1.5',
        color: theme.palette.slateGray,
        marginTop: '7vh',
        textAlign: 'justify'
    },
    consentHeading:{
        color: theme.palette.orange,
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: '15.5px'
    },
    dOptionsText:{
        fontSize: '13px',
        margin: '1vh 0vw',
        textAlign: 'left'
    },
    feedbackInnerContainer: {
        padding: '0vh 30px',
        marginBottom: '20px',
        overflowY: 'scroll'
    },
    feedbackSubmitContainer: {
        padding: '0vh 30px'
    },
    starEmpty: {
        color: theme.palette.orange
    },
    starFocus: {
        color: theme.palette.orange
    },
    dialogContent: {
        paddingLeft: '8px !important',
        paddingRight: '8px !important',
    },
    heading: {
        fontSize: '15px',
        fontWeight: '500'
    },
    subHeading: {
        fontSize: '13px',
        padding: '1vh',
        margin: '1vh'
    },
    root: {
        background: theme.palette.paleGray2
    },
    star: {
        color: 'black'
    },
    centerAlign: {
        textAlign: 'center'
    },
    feedbackInfo: {
        textAlign: 'center',
        fontSize: '14px',
        margin: '1vh 0vw',
        color: theme.palette.black,
        fontWeight: '500',
        lineHeight: '1.8'
    },
    feedbackText: {
        fontSize: '13px',
        textAlign: 'center',
        marginTop: '2vh'
    },
    button: {
        marginTop: '1vh',
        marginBottom: '1vh',
        marginRight: '2vw',
        background: theme.palette.red,
        fontSize: '13px',
        width: '100px',
        color: theme.palette.white,
        borderRadius: '10px',
        '&:hover': {
            backgroundColor: theme.palette.orange,
        }
    },
    cancelButton: {
        marginTop: '1vh',
        marginBottom: '1vh',
        background: '#838383',
        fontSize: '14.5px',
        width: '100px',
        borderRadius: '10px',
        color: theme.palette.white,
        marginRight: '1vw',
        '&:hover': {
            backgroundColor: `${theme.palette.coolGray2} !important`,
        }
    },
    error: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        marginTop: '2VH'
    },
    validationError:{
        display: 'flex',
        width: '100%',
        margin:'1vh 0vw'
    },
    errorStyle: {
        color: theme.palette.errorRed,
        fontSize: '11px',
        margin: '0vh 5px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%'
    },
    dialog: {
        textAlign: 'center',
        backgroundColor: theme.palette.white,
        marginBottom: '2VH',
        width: '70%',
        borderRadius: '10px !important',
        boxShadow: '0 0 18px 0 rgba(0, 0, 0, 0.2)'
    },
    radioSelected: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center'
    },
    feedbackOption: {
        display: 'flex',
        flexDirection: 'row',
        margin: '1vh 0vw'
    },
    radioOption: {
        width: '15px',
        height: '15px',
        opacity: '0.3',
        borderRadius: '5px',
        border: 'solid 1px black',
        backgroundColor: theme.palette.white,
        marginRight: '1vw',
        cursor: 'pointer',
        fontWeight: 'normal'
    },
    radioContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop:'2vh'
    },
    selectedRadio: {
        width: '7px',
        height: '7px',
        borderRadius: '2px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: theme.palette.orange,
        opacity: '1'

    },
    selectedRadioOption: {
        opacity: '1',
        borderColor: theme.palette.orange
    },
    radioLabel: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        fontWeight: 'normal',
        cursor: 'pointer',
        textAlign: 'left',
        fontSize: '13px'
    },
    radio: {
        fontSize: '13px',
        margin: '1vh 1vw',
        display: 'flex',
    },
    satisfiedButton: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        color: theme.palette.white,
        fontSize: '13px',
        fontWeight: '500',
        backgroundColor: theme.palette.green
    },
    feedbackIcon: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: 'inherit',
        marginRight: '2.5px'
    },
    footerButton: {
        width: '49%',
        backgroundColor: theme.palette.orange,
        borderRadius: '10px',
        '&:hover': {
            backgroundColor: theme.palette.orange,
        }
    },
    footerContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        margin: '3vh 0vw 3vh 0vw'
    },
    skipButton: {
        color: 'darkGray',
        fontSize: '14px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '2vh',
        color: 'white',
        width: '30%',
        padding: '4px 0vw',
        borderRadius: '19px',
        backgroundColor: theme.palette.red,
        '&:hover': {
            backgroundColor: theme.palette.red,
        }
    },
    cDialog: {
        textAlign: 'center',
        backgroundColor: theme.palette.white,
        marginBottom: '2vh',
        borderRadius: '10px !important',
        boxShadow: '0 0 13px 0 rgba(0, 0, 0, 0.25)',
        fontSize: '13px',
        width: '50%'
    },
    cDialogHeading: {
        fontSize: '13px',
        padding: '1vh 0vw',
        margin: '1vh',
        fontWeight: '500'
    },
    cDialogContent: {
        padding: '20px !important'
    },
    cFooterButton: {
        width: '49%',
        backgroundColor: theme.palette.orange,
        borderRadius: '19px',
        '&:hover': {
            backgroundColor: theme.palette.orange,
        }
    },
    cSatisfiedButton: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        color: theme.palette.white,
        fontSize: '13px',
        fontWeight: '500',
        backgroundColor: theme.palette.orange
    },
    cDFooterButton: {
        backgroundColor: 'transparent',
        border: `solid 1px ${theme.palette.orange}`,
        '&:hover': {
            backgroundColor: 'transparent',
        }

    },
    dissatisfiedButton: {
        color: theme.palette.orange,
        backgroundColor: 'transparent',
    },
    fradioSelected: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center'
      },
      fradioOption: {
        width: '15px',
        height: '15px',
        opacity: '0.3',
        borderRadius: '5px',
        border: 'solid 1px black',
        backgroundColor: theme.palette.white,
        marginRight: '1vw',
        cursor: 'pointer',
        color: theme.palette.slateGray
      },
      fradioContainer: {
        display: 'flex',
        marginRight: '5vw'
      },
      fselectedRadio: {
        width: '7px',
        height: '7px',
        borderRadius: '2px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: theme.palette.orange,
        opacity: '1'
    
      },
      fselectedRadioOption: {
        opacity: '1',
        borderColor: theme.palette.orange
      },
      fradioLabel: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'left'
      },
      fradio: {
        fontSize: '13px',
        margin: '4vh',
        display: 'flex',
        justifyContent: 'center'
      },
      policyButton: {
        backgroundColor: theme.palette.red,
        color: theme.palette.white,
        textTransform: 'unset',
        borderRadius: '8px',
        marginLeft: '1vw',
        marginRight: '1vw',
        fontSize: '13px',
        fontWeight: '500',
        width: '100%',
        '&:hover': {
          backgroundColor: theme.palette.orange,
        }
      },
});