import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { feedbackStyles } from '../../../Styles/feedbackStyles';
import { inject, observer } from 'mobx-react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { ReactSVG } from 'react-svg';
import Images from '../../../Theme/Images';

@inject('feedbackStore', 'chatStore', 'queryStore', 'commonStore')
@observer
class CloseChatFeedback extends Component {

    endChat = (feedback) => {
        const { version } = this.props.commonStore
        if (feedback === 'skip') {
            this.props.chatStore.endChat(feedback)
                .then(() => {
                    if (!this.props.chatStore.endProgress) {
                        this.props.commonStore.setGoBackTo('home')
                        this.props.confirmCloseChat()
                    }
                })
        }
        else {
            this.props.queryStore.setShowFeedback(true);
            // this.props.commonStore.setGoBackTo('home')
            this.props.history.push({
                pathname: `/${version}/chatFeedback`,
                appState: { item: this.props.item, type: feedback, from: 'chat' }
            })
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Dialog classes={{ paper: classes.cDialog }} open={this.props.open} onClose={this.props.closeDialog}>
                <DialogContent classes={{ root: classes.cDialogContent }}>
                    {/*<div className={classes.heading}>Are you sure you want to go back?</div>*/}
                    <div className={classes.cDialogHeading}>Do you want to close ongoing chat?</div>
                    <div className={classes.footerContainer}>
                        <Button className={classes.cFooterButton} onClick={(event) => this.endChat('satisfied')}>
                            <div className={classes.cSatisfiedButton}>
                                <div className='rowFlex'>
                                    <ReactSVG className={classes.feedbackIcon} src={Images.sat} />
                                    <div className={classes.feedbackIcon}>Satisfied</div>
                                </div>
                            </div>
                        </Button>
                        <Button className={`${classes.cFooterButton} ${classes.cDFooterButton}`} onClick={(event) => this.endChat('dissatisfied')}>
                            <div className={`${classes.satisfiedButton} ${classes.dissatisfiedButton}`}>
                                <div className='rowFlex'>
                                    <ReactSVG className={classes.feedbackIcon} src={Images.dissatOrange} />
                                    <div className={classes.feedbackIcon} >Dissatisfied</div>
                                </div>
                            </div>
                        </Button>
                    </div>
                    <div className={'rowFlex flexCentered'}><Button className={classes.skipButton} onClick={(event) => this.endChat('skip')}>Exit</Button></div>
                </DialogContent>
            </Dialog>
        )
    }
}
CloseChatFeedback.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withRouter(withStyles(feedbackStyles)(CloseChatFeedback));
